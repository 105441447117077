import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';
import './RepairsView.scss'
import axios from 'axios';
import Sidebar from '../../components/Sidebar/Sidebar';
import LoadingView from '../LoadingView/LoadingView';
import DatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FormCheck as Checkbox } from 'react-bootstrap';

class RepairsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            loaded: false,
            userBuildings: [],
            buildingLocations: null,
            locationsByID: null,
            buildingRepairs: [],
            allProblemTypes: [],
            allRepairTypes: [],
            buildingOptions: null,
            locationOptions: null,
            problemOptions: null,
            repairOptions: null,
            repairTableRows: null,
            selectedBuilding: null,
            selectedLocation: null,
            selectedProblems: [],
            selectedRepairs: [],
            repairDate: null,
            repairNotes: null,

            problemCheckboxUnknown: false,
            problemCheckboxHandleIssue: false,
            problemCheckboxWaterFeedValveBreak: false,
            problemCheckboxWaterFeedValveSealLeaking: false,
            problemCheckboxOverflowTubePlugged: false,
            problemCheckboxOverflowTubeFailed: false,
            problemCheckboxFlapperOpen: false,
            problemCheckboxFlapperLoose: false,
            problemCheckboxFlapperValveLeaking: false,
            problemCheckboxFlapperValveMechanicalFailure: false,
            problemCheckboxFlapperChainInterference: false,
            problemCheckboxFlapperChainTooShort: false,
            problemCheckboxFlapperChainTooLong: false,
            problemCheckboxTankBreak: false,
            problemCheckboxTankHairlineCrack: false,
            problemCheckboxTanktoBaseSealFailure: false,
            problemCheckboxTankHandleorHandleRodLoose: false,
            problemCheckboxTankHandleorHandleRodFailure: false,
            problemCheckboxTankHandleorHandleRodInterference: false,
            problemCheckboxBaseBreak: false,
            problemCheckboxBaseHairlineCrack: false,
            problemCheckboxBasetoFloorSealFailure: false,

            repairCheckboxNone: false,
            repairCheckboxCleanToilet: false,
            repairCheckboxToiletReplacement: false,
            repairCheckboxHandleReplaced: false,
            repairCheckboxSealReplaced: false,
            repairCheckboxFlapperReplaced: false,
            repairCheckboxFloatReplaced: false,
            repairCheckboxFillValveReplaced: false,
            repairCheckboxFlapperValveReplaced: false,

            formButton: null,
            editingRepairID: null
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#ffffff";
        this.getUserBuildings()
        window.addEventListener('resize', () => this.setState({ windowWidth: window.innerWidth }));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({ windowWidth: window.innerWidth }));
    }

    getUserBuildings() {

        this.setState({
            loaded: false,
            locationOptions: null,
            repairTableRows: null,
            selectedLocation: null,
            formButton: <Button variant="primary" id="addRepairSubmitButton" onClick={this.handleSubmit.bind(this)}>Submit Repair</Button>
        });

        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                let buildings = response.data;
                if (buildings.length != 0) {
                    this.setState({
                        userBuildings: buildings
                    })

                    if (config.selectedBuilding == 0) {
                        Swal.close();
                        Swal.fire({
                            title: "You must have a building selected to view and add repairs.",
                            icon: 'error'
                        })
                            .then(() => {
                                this.setState({
                                    loaded: true
                                });
                            });
                    }
                    else {
                        buildings.forEach((building) => {
                            if (building.id == config.selectedBuilding) {
                                this.getBuildingLocations(building)
                            }
                        });
                    }
                }
                else {
                    Swal.close();
                    Swal.fire({
                        title: "It Looks Like You Aren't Assigned Any Buildings.",
                        icon: 'error'
                    });
                }
            });
    }

    getBuildingLocations(selectedBuilding) {
        //Get all locations in a building
        let buildingID = selectedBuilding["id"];
        let url = `${config.api}buildings/${buildingID}/locations`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                const data = response["data"];
                var locationsByID = [];
                var locations = data.map((item) => {
                    locationsByID[item.locationID] = item;
                    return (item);
                });
                locations.sort((a, b) => a.locationName - b.locationName);
                this.setState({
                    buildingLocations: locations,
                    locationsByID: locationsByID,
                    selectedBuilding: selectedBuilding
                });

                this.getBuildingRepairs(selectedBuilding)
            },
                (error) => {
                    Swal.close();
                    Swal.fire({
                        title: "Error Getting Building Locations",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }

    getBuildingRepairs(selectedBuilding) {
        let buildingID = selectedBuilding["id"];

        let url = `${config.api}repair-logs/building/${buildingID}`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                let data = response.data;
                let records = data["records"];
                let problemList = data["repairProblems"];
                let problems = [];
                data["repairProblems"].forEach((problem) => {
                    problems[problem.id] = problem.label;
                });
                let repairList = data["repairRepairs"];
                let repairs = [];
                data["repairRepairs"].forEach((repair) => {
                    repairs[repair.id] = repair.label;
                });
                let problemRecords = data["problemRecords"];
                let repairRecords = data["repairRecords"];

                this.setState({
                    allProblemTypes: problemList,
                    allRepairTypes: repairList
                });

                this.updateRepairTable(records, problems, repairs, problemRecords, repairRecords);
                this.updateOptions(problemList, repairList);
            });
    }

    updateOptions(problemList, repairList) {
        let buildingOptions = this.state.userBuildings.map((building) => {
            return (
                <option value={building.id}>{building.label}</option>
            )
        })
        let locationOptions = this.state.buildingLocations.map((location) => {
            return (
                <option value={location.locationID}>{location.locationName}</option>
            )
        })
        let problemOptions = problemList.map((problem) => {
            return (
                <option value={problem.id}>{problem.label}</option>
            )
        });
        let repairOptions = repairList.map((repair) => {
            return (
                <option value={repair.id}>{repair.label}</option>
            )
        });
        this.setState({
            buildingOptions: buildingOptions,
            locationOptions: locationOptions,
            problemOptions: problemOptions,
            repairOptions: repairOptions,
        });
    }

    updateRepairTable(records, problems, repairs, problemRecords, repairRecords) {
        records.sort((a, b) => b.repairTime - a.repairTime);
        let tableRows = records.map((record) => {
            let locationName = this.state.locationsByID[record.locationID].locationName;
            let problemArray = problemRecords[record.id];
            var problem = "";
            let repairArray = repairRecords[record.id];
            var repair = "";

            if (problemArray.length == 1) {
                problem = problems[problemRecords[record.id]];
            }
            else {
                for (let i = 0; i < problemArray.length; i++) {
                    if (i == problemArray.length - 1) {
                        problem += problems[problemArray[i]]
                    }
                    else {
                        problem += problems[problemArray[i]] + ', '
                    }
                }
            }

            if (repairArray.length == 1) {
                repair = repairs[repairRecords[record.id]];
            }
            else {
                for (let i = 0; i < repairArray.length; i++) {
                    if (i == repairArray.length - 1) {
                        repair += repairs[repairArray[i]]
                    }
                    else {
                        repair += repairs[repairArray[i]] + ', '
                    }
                }
            }

            var buildingRepairs = this.state.buildingRepairs;
            buildingRepairs[record.id] = {
                'locationName': locationName,
                'repairDate': record.repairDate,
                'repairTime': record.repairTime,
                'problems': problemArray,
                'repairs': repairArray,
                'details': record.repairDescription
            };
            this.setState({
                buildingRepairs: buildingRepairs
            });

            return (
                <tr >
                    <td>{locationName}</td>
                    <td>{record.repairDate}</td>
                    <td>{problem}</td>
                    <td>{repair}</td>
                    <td>{record.repairDescription}</td>
                    <td>
                        <Button variant="link" repair={record.id} onClick={this.editClicked} >Edit</Button>
                        <Button variant="link" repair={record.id} onClick={this.deleteClicked} >Delete</Button>
                    </td>
                </tr>
            )
        });

        this.setState({
            repairTableRows: tableRows,
            loaded: true
        });
    }

    editClicked = (event) => {
        const repairID = event.currentTarget.getAttribute('repair');
        this.editRepair(repairID);

        const repair = this.state.buildingRepairs[repairID];
        var locationID = null;
        this.state.buildingLocations.forEach((location) => {
            if (location.locationName == repair.locationName) {
                document.getElementById('roomSelect').value = location.locationID;
                locationID = location.locationID;
            }
        })

        document.getElementById('notesInput').value = repair.details;

        var selectedProblems = [];
        this.state.allProblemTypes.forEach((type) => {
            var currentCheckbox = 'problemCheckbox-' + type.label;
            if (repair.problems.includes(type.id)) {
                document.getElementById(currentCheckbox).checked = true;
                selectedProblems.push(type.id);
                this.setChecked(currentCheckbox, true);
            }
            else {
                this.setChecked(currentCheckbox, false);
            }
        });

        var selectedRepairs = [];
        this.state.allRepairTypes.forEach((type) => {
            var currentCheckbox = 'repairCheckbox-' + type.label;
            if (repair.repairs.includes(type.id)) {
                document.getElementById(currentCheckbox).checked = true;
                selectedRepairs.push(type.id);
                this.setChecked(currentCheckbox, true);
            }
            else {
                this.setChecked(currentCheckbox, false);
            }
        });

        var oldDate = new Date(repair.repairTime * 1000);

        const editFormButtons = (
            <div>
                <Button variant="primary" id="editRepairSubmitButton" onClick={this.editRepair.bind(this)}>Update Repair</Button>
                {' '}
                <Button variant="danger" id="cancelEditButton" onClick={this.reset.bind(this)}>Cancel</Button>
            </div>
        );

        this.setState({
            editingRepairID: repairID,
            selectedRepairs: selectedRepairs,
            selectedProblems: selectedProblems,
            repairNotes: repair.details,
            selectedLocation: locationID,
            repairDate: oldDate,
            formButton: editFormButtons
        });
    }

    editRepair() {
        const repairID = this.state.editingRepairID;

        let room = this.state.selectedLocation;
        if (room == null) {
            Swal.fire({
                title: 'Please select a Location.',
            });
            return;
        }
        let device = this.state.locationsByID[room].deviceID;
        if (device == null) {
            Swal.fire({
                title: 'This location does not have a device assigned to it.',
            });
            return;
        }
        let problems = this.state.selectedProblems;
        if (problems.length == 0) {
            Swal.fire({
                title: 'Please select at least one problem.',
            });
            return;
        }
        let repairs = this.state.selectedRepairs;
        if (repairs.length == 0) {
            Swal.fire({
                title: 'Please select at least one repair.',
            });
            return;
        }
        let repairDate = this.state.repairDate;
        if (repairDate == null) {
            Swal.fire({
                title: 'Please select a date.',
            });
            return;
        }
        let isoDate = repairDate.toISOString();
        let epochTime = new Date(isoDate).getTime() / 1000;
        repairDate = repairDate.toISOString().split('T')[0];
        let repairNotes = this.state.repairNotes;
        if (repairNotes == null) {
            Swal.fire({
                title: 'Please provide some details about the repair.',
            });
            return;
        }

        Swal.fire({
            'title': 'Updating Repair Log...',
            onBeforeOpen: () => {
                Swal.showLoading()
            }


        });

        var postData = `[{"locationID": ${room},"deviceID": ${device},"repairDate": "${repairDate}","repairDescription": "${repairNotes}","repairTime": ${epochTime},"repairRepair": [${repairs}],"repairProblem": [${problems}]}]`;

        let postConfig = ({
            method: 'post',
            url: `${config.api}repair-log`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: postData
        })

        axios(postConfig)
            .then((response) => {
                let data = response["data"];
                let error = data["errorCode"];
                if (error == null) {
                    var postData2 = JSON.stringify([{
                        "id": repairID
                    }]);

                    let delCconfig = ({
                        method: 'delete',
                        url: `${config.api}repair-log`,
                        headers: {
                            'Authorization': `Bearer ${config.token}`
                        },
                        data: postData2
                    })

                    axios(delCconfig)
                        .then((response) => {
                            Swal.close()
                            let data = response["data"];
                            let body = JSON.parse(data["body"]);
                            let errorMessage = body["errorCode"];
                            if (errorMessage == null) {
                                Swal.fire({
                                    title: 'Repair Successfully Updated',
                                    icon: 'success'
                                })
                                    .then(this.reset.bind(this));
                            }
                            else {
                                Swal.fire({
                                    title: "Error Deleting Repair Log Copy",
                                    text: errorMessage,
                                    icon: 'error'
                                });
                            }
                        },
                            (error) => {
                                Swal.close()
                                Swal.fire({
                                    title: "Error Deleting Repair Log Copy",
                                    text: error,
                                    icon: 'error'
                                });
                            }
                        );
                }
                else {
                    Swal.fire({
                        title: "Error Updating Repair Log",
                        text: error,
                        icon: 'error'
                    });
                }
            },
                (error) => {
                    Swal.close()
                    Swal.fire({
                        title: "Error Updating Repair Log",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }

    deleteClicked = (event) => {
        const repair = event.currentTarget.getAttribute('repair');

        Swal.fire({
            title: 'Are you sure you want to delete this repair?',
            text: "You won't be able to undo this.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                Swal.fire({
                    'title': 'Deleting...',
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    }
                });
                this.deleteRepair(repair);
            }
        })
    }

    deleteRepair(repairID) {

        var postData = JSON.stringify([{
            "id": repairID
        }]);

        let delConfig = ({
            method: 'delete',
            url: `${config.api}repair-log`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            },
            data: postData
        })

        axios(delConfig)
            .then((response) => {
                Swal.close()
                let data = response["data"];
                let body = JSON.parse(data["body"]);
                let errorMessage = body["errorCode"];
                if (errorMessage == null) {
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Your repair has been deleted.',
                        icon: 'success'
                    })
                        .then(this.reset.bind(this));
                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: errorMessage,
                        icon: 'error'
                    });
                }
            },
                (error) => {
                    Swal.close()
                    Swal.fire({
                        title: "Error",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }

    exportData = (api, token, title) => {
        Swal.close();
        Swal.fire({
            title: "Repair Log Exporting Coming Soon.",
            icon: 'error'
        })
            .then(() => {
                this.setState({
                    loaded: true
                });
            });

        return;

        // if (this.state.loaded) {

        //     let totalGallons = 0;
        //     let totalGallonsCost = 0;
        //     let totalFlushes = 0;
        //     let totalGallonsLost = 0;
        //     let totalGallonsLeakedCost = 0;

        //     let data = this.state.data;
        //     let leakData = this.state.leakData;
        //     let gData = null;
        //     let gallonData = [];
        //     let wastedData = [];
        //     let keys = null;

        //     if(data != null){

        //         keys = Object.keys(data).sort();
        //         gallonData = [];
        //         wastedData = [];
        //         for(let i =0 ; i < keys.length ; i++){
        //             let key = keys[i];
        //             let gallons = parseInt(data[key]["gallonsUsed"]);

        //             let flushes = data[key]["flushes"];
        //             let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
        //             let leakCost = Number(parseFloat(data[key]["leakCost"]).toFixed(2));
        //             let gallonsUsedCost = Number(parseFloat(data[key]["cost"]).toFixed(2));
        //             gallonData.push(gallons);
        //             wastedData.push(gallonsWasted);
        //             totalGallons += gallons;
        //             totalGallonsCost += gallonsUsedCost;
        //             totalFlushes += flushes;
        //             totalGallonsLost += gallonsWasted;
        //             totalGallonsLeakedCost += leakCost;
        //         }
        //     }

        //     const chartDates = Object.keys(this.state.data).sort();
        //     const chartGals = [];
        //     const chartLeakedGals = [];
        //     for (let i = 0; i < chartDates.length; i++) {
        //         chartGals.push(this.state.data[chartDates[i]]["gallonsUsed"]);
        //         chartLeakedGals.push(this.state.data[chartDates[i]]["gallonsWasted"]);
        //     }

        //     const exportRoomData = []
        //     const leaks = this.state.leakData;
        //     for (let i = 0; i < Object.keys(this.state.leakData).length; i++) {
        //         let buildingKey = Object.keys(this.state.leakData)[i];
        //         for (let j = 0; j < Object.keys(this.state.leakData[buildingKey]).length; j++) {
        //             let leakKey = Object.keys(this.state.leakData[buildingKey])[j];
        //             exportRoomData.push({
        //                 "building" : this.state.leakData[buildingKey][leakKey]["buildingLabel"],
        //                 "date" : this.state.leakData[buildingKey][leakKey]["date"],
        //                 "name" : this.state.leakData[buildingKey][leakKey]["locationLabel"],
        //                 "num_gallons" : this.state.leakData[buildingKey][leakKey]["gallons"]
        //             });
        //         }
        //     }

        //     exportRoomData.sort((a, b) => (a.date > b.date) ? 1 : (a.date === b.date) ? ((a.num_gallons < b.num_gallons) ? 1 : -1) : -1 );

        //     exportRoomData.forEach(element => element.num_gallons = element.num_gallons.toLocaleString());



        //     const requestBody = {
        //         "reportType" : "water-use",
        //         "data": {
        //             "building_title": title,
        //             "building_subtitle": "",
        //             "start_date": this.state.startDate,
        //             "end_date": this.state.endDate,
        //             "room_name": "",
        //             "num_flushes": totalFlushes.toLocaleString(),
        //             "gallons_used": totalGallons.toLocaleString(),
        //             "gallons_leaked": totalGallonsLost.toLocaleString(),
        //             "gallons_used_cost": totalGallonsCost.toLocaleString(undefined, {maximumFractionDigits:2}),
        //             "gallons_leaked_cost": totalGallonsLeakedCost.toLocaleString(undefined, {maximumFractionDigits:2}),
        //             "gallons_used_array": chartGals,
        //             "gallons_leaked_array": chartLeakedGals,
        //             "chart_dates": chartDates,
        //             "rooms": exportRoomData
        //         }  
        //     };

        //     let url = api+`pdf-page-export`;
        //     let auth = `Bearer `+token;
        //     var config = {
        //         method: 'post',
        //         url: url,
        //         headers: { 
        //           'Authorization': auth, 
        //           'Content-Type': 'application/json', 
        //         },
        //         data : requestBody
        //       };

        //       axios(config)
        //       .then(function (response) {
        //         console.log(JSON.stringify(response.data));
        //       });

        // }
    };

    handleInputChange = (event) => {

        switch (event.target.id) {
            case "buildingSelect":
                this.setState({
                    selectedBuilding: event.target.value
                });
                break;
            case "roomSelect":
                this.setState({
                    selectedLocation: event.target.value
                });
                break;
            case "notesInput":
                this.setState({
                    repairNotes: event.target.value
                });
                break;
            default:
                break;
        }

        const targetID = String(event.target.id);
        if (targetID.includes("problemCheckbox")) {
            var problems = this.state.selectedProblems;
            if (event.target.checked == true) {
                problems.push(parseInt(event.target.value, 10));
            }
            else {
                let index = problems.indexOf(parseInt(event.target.value, 10));
                if (index != -1) {
                    problems.splice(index, 1);
                }
            }
            this.setChecked(targetID, event.target.checked);
            this.setState({
                selectedProblems: problems
            });
        };
        if (targetID.includes("repairCheckbox")) {
            var repairs = this.state.selectedRepairs;
            if (event.target.checked == true) {
                repairs.push(parseInt(event.target.value, 10));
            }
            else {
                let index = repairs.indexOf(parseInt(event.target.value, 10));
                if (index != -1) {
                    repairs.splice(index, 1);
                }
            }
            this.setChecked(targetID, event.target.checked);
            
            this.setState({
                selectedRepairs: repairs
            });
        }
    }

    setChecked = (targetID, value) => {
        switch (targetID) {
            case "problemCheckbox-Unknown":
                this.setState({
                    problemCheckboxUnknown: value
                });
                break;
            case "problemCheckbox-Handle Issue":
                this.setState({
                    problemCheckboxHandleIssue: value
                });
                break;
            case "problemCheckbox-Water Feed Valve Break":
                this.setState({
                    problemCheckboxWaterFeedValveBreak: value
                });
                break;
            case "problemCheckbox-Water Feed Valve Seal Leaking":
                this.setState({
                    problemCheckboxWaterFeedValveSealLeaking: value
                });
                break;
            case "problemCheckbox-Overflow Tube Plugged":
                this.setState({
                    problemCheckboxOverflowTubePlugged: value
                });
                break;
            case "problemCheckbox-Overflow Tube Failed":
                this.setState({
                    problemCheckboxOverflowTubeFailed: value
                });
                break;
            case "problemCheckbox-Flapper Open":
                this.setState({
                    problemCheckboxFlapperOpen: value
                });
                break;
            case "problemCheckbox-Flapper Loose":
                this.setState({
                    problemCheckboxFlapperLoose: value
                });
                break;
            case "problemCheckbox-Flapper Valve Leaking":
                this.setState({
                    problemCheckboxFlapperValveLeaking: value
                });
                break;
            case "problemCheckbox-Flapper Valve Mechanical Failure":
                this.setState({
                    problemCheckboxFlapperValveMechanicalFailure: value
                });
                break;
            case "problemCheckbox-Flapper Chain Interference":
                this.setState({
                    problemCheckboxFlapperChainInterference: value
                });
                break;
            case "problemCheckbox-Flapper Chain Too Short":
                this.setState({
                    problemCheckboxFlapperChainTooShort: value
                });
                break;
            case "problemCheckbox-Flapper Chain Too Long":
                this.setState({
                    problemCheckboxFlapperChainTooLong: value
                });
                break;
            case "problemCheckbox-Tank Break":
                this.setState({
                    problemCheckboxTankBreak: value
                });
                break;
            case "problemCheckbox-Tank Hairline Crack":
                this.setState({
                    problemCheckboxTankHairlineCrack: value
                });
                break;
            case "problemCheckbox-Tank to Base Seal Failure":
                this.setState({
                    problemCheckboxTanktoBaseSealFailure: value
                });
                break;
            case "problemCheckbox-Tank Handle or Handle Rod Loose":
                this.setState({
                    problemCheckboxTankHandleorHandleRodLoose: value
                });
                break;
            case "problemCheckbox-Tank Handle or Handle Rod Failure":
                this.setState({
                    problemCheckboxTankHandleorHandleRodFailure: value
                });
                break;
            case "problemCheckbox-Tank Handle or Handle Rod Interference":
                this.setState({
                    problemCheckboxTankHandleorHandleRodInterference: value
                });
                break;
            case "problemCheckbox-Base Break":
                this.setState({
                    problemCheckboxBaseBreak: value
                });
                break;
            case "problemCheckbox-Base Hairline Crack":
                this.setState({
                    problemCheckboxBaseHairlineCrack: value
                });
                break;
            case "problemCheckbox-Base to Floor Seal Failure":
                this.setState({
                    problemCheckboxBasetoFloorSealFailure: value
                });
                break;


            case "repairCheckbox-None":
                this.setState({
                    repairCheckboxNone: value
                });
                break;
            case "repairCheckbox-Clean Toilet":
                this.setState({
                    repairCheckboxCleanToilet: value
                });
                break;
            case "repairCheckbox-Toilet Replacement":
                this.setState({
                    repairCheckboxToiletReplacement: value
                });
                break;
            case "repairCheckbox-Handle Replaced":
                this.setState({
                    repairCheckboxHandleReplaced: value
                });
                break;
            case "repairCheckbox-Seal Replaced":
                this.setState({
                    repairCheckboxSealReplaced: value
                });
                break;
            case "repairCheckbox-Flapper Replaced":
                this.setState({
                    repairCheckboxFlapperReplaced: value
                });
                break;
            case "repairCheckbox-Float Replaced":
                this.setState({
                    repairCheckboxFloatReplaced: value
                });
                break;
            case "repairCheckbox-Fill Valve Replaced":
                this.setState({
                    repairCheckboxFillValveReplaced: value
                });
                break;
            case "repairCheckbox-Flapper Valve Replaced":
                this.setState({
                    repairCheckboxFlapperValveReplaced: value
                });
                break;
            default:
                break;
        }
    }

    handleDateChange = (date) => {
        this.setState({
            repairDate: date
        });
    };

    // handleBuildingChange = (event) => {
    //     let selectedBuilding = event;

    //     if(selectedBuilding == 0){
    //         Swal.fire({
    //             'title': 'You must have a building selected to view a specific room.'
    //         });
    //     }
    //     else {
    //         localStorage.setItem("mlBuildingID" , selectedBuilding);
    //         this.setState({
    //             loaded: false
    //         });
    //         this.state.userBuildings.forEach((building) => {
    //             if (building.id == selectedBuilding) {
    //                 selectedBuilding = building
    //             }
    //         })
    //         this.getBuildingLocations(selectedBuilding);
    //     }
    // }

    handleSubmit = (event) => {
        let selectedBuildingID = config.selectedBuilding;
        if (selectedBuildingID == 29 || selectedBuildingID == 30 || selectedBuildingID == 31) {
            Swal.fire({
                title: 'Not Currently Available For Demo Purposes.',
            });
            return;
        }

        let room = this.state.selectedLocation;
        if (room == null) {
            Swal.fire({
                title: 'Please select a location.',
            });
            return;
        }
        let device = this.state.locationsByID[room].deviceID;
        if (device == null) {
            Swal.fire({
                title: 'This location does not have a device assigned to it.',
            });
            return;
        }
        let problems = this.state.selectedProblems;
        if (problems.length == 0) {
            Swal.fire({
                title: 'Please select at least one problem.',
            });
            return;
        }
        let repairs = this.state.selectedRepairs;
        if (repairs.length == 0) {
            Swal.fire({
                title: 'Please select at least one repair.',
            });
            return;
        }
        let repairDate = this.state.repairDate;
        if (repairDate == null) {
            Swal.fire({
                title: 'Please select a date.',
            });
            return;
        }
        let isoDate = repairDate.toISOString();
        let epochTime = new Date(isoDate).getTime() / 1000;
        repairDate = repairDate.toISOString().split('T')[0];
        let repairNotes = this.state.repairNotes;
        if (repairNotes == null) {
            Swal.fire({
                title: 'Please provide some details about the repair.',
            });
            return;
        }

        Swal.fire({
            'title': 'Adding Repair Log...',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var postData = `[{"locationID": ${room},"deviceID": ${device},"repairDate": "${repairDate}","repairDescription": "${repairNotes}","repairTime": ${epochTime},"repairRepair": [${repairs}],"repairProblem": [${problems}]}]`;

        let postConfig = ({
            method: 'post',
            url: `${config.api}repair-log`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
                'Content-Type': 'text/plain'
            },
            data: postData
        })

        axios(postConfig)
            .then((response) => {
                Swal.close()
                let data = response["data"];
                let error = data["errorCode"];
                if (error == null) {
                    Swal.fire({
                        title: 'Repair Successfully Logged',
                        icon: 'success'
                    })
                        .then(this.reset.bind(this));
                }
                else {
                    Swal.fire({
                        title: "Error",
                        text: error,
                        icon: 'error'
                    });
                }
            },
                (error) => {
                    Swal.close()
                    Swal.fire({
                        title: "Error",
                        text: error,
                        icon: 'error'
                    });
                }
            );
    }

    reset() {
        this.setState({
            loaded: false,
            selectedLocation: null,
            selectedProblems: [],
            selectedRepairs: [],
            repairDate: null,
            repairNotes: null,

            problemCheckboxUnknown: false,
            problemCheckboxHandleIssue: false,
            problemCheckboxWaterFeedValveBreak: false,
            problemCheckboxWaterFeedValveSealLeaking: false,
            problemCheckboxOverflowTubePlugged: false,
            problemCheckboxOverflowTubeFailed: false,
            problemCheckboxFlapperOpen: false,
            problemCheckboxFlapperLoose: false,
            problemCheckboxFlapperValveLeaking: false,
            problemCheckboxFlapperValveMechanicalFailure: false,
            problemCheckboxFlapperChainInterference: false,
            problemCheckboxFlapperChainTooShort: false,
            problemCheckboxFlapperChainTooLong: false,
            problemCheckboxTankBreak: false,
            problemCheckboxTankHairlineCrack: false,
            problemCheckboxTanktoBaseSealFailure: false,
            problemCheckboxTankHandleorHandleRodLoose: false,
            problemCheckboxTankHandleorHandleRodFailure: false,
            problemCheckboxTankHandleorHandleRodInterference: false,
            problemCheckboxBaseBreak: false,
            problemCheckboxBaseHairlineCrack: false,
            problemCheckboxBasetoFloorSealFailure: false,

            repairCheckboxNone: false,
            repairCheckboxCleanToilet: false,
            repairCheckboxToiletReplacement: false,
            repairCheckboxHandleReplaced: false,
            repairCheckboxSealReplaced: false,
            repairCheckboxFlapperReplaced: false,
            repairCheckboxFloatReplaced: false,
            repairCheckboxFillValveReplaced: false,
            repairCheckboxFlapperValveReplaced: false,

            formButton: <Button variant="primary" id="addRepairSubmitButton" onClick={this.handleSubmit.bind(this)}>Submit Repair</Button>
        });
        if (config.selectedBuilding != 0) {
            this.getBuildingLocations(this.state.selectedBuilding);
        }
    }

    render() {

        if (this.state.loaded) {
            Swal.close()
            return (
                <div id="pageOuterContainer" className="pageOuterContainer" >
                    {this.state.windowWidth > 650 &&
                        <Sidebar
                            pageWrapId={"pageWrap"}
                            outerContainerId={"pageOuterContainer"}
                            generateSummary={this.getUserBuildings.bind(this)}
                            buildings={this.state.userBuildings}
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }

                    <Container id="pageWrap" className="pageWrap" >

                        {this.state.windowWidth <= 650 &&
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                generateSummary={this.getUserBuildings.bind(this)}
                                buildings={this.state.userBuildings}
                                buttonsVisible={true}
                                handleExport={this.exportData}
                            />
                        }

                        <Row className="repairFormColumn">
                            <Col xs={12}>
                                <h2>New Repair</h2>
                                <br />
                                <Form>
                                    {/* User Select */}
                                    {/* <Form.Group>
                                        <Form.Label><b>Building</b></Form.Label>
                                        <Form.Control as="select" id="buildingSelect" onChange={this.handleBuildingChange} defaultValue={this.state.selectedBuilding.id} >
                                            {this.state.buildingOptions}
                                        </Form.Control>
                                    </Form.Group> */}
                                    <Form.Group>
                                        <Form.Label><b>Location</b></Form.Label>
                                        <Form.Control as="select" id="roomSelect" onChange={this.handleInputChange} defaultValue={0} >
                                            <option value="0">Select a Location</option>
                                            {this.state.locationOptions}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {/* Problem Check boxes */}
                                        <Form.Label><b>Problem(s) At The Location?</b></Form.Label>
                                        {/* <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                                    General
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                    <Card.Body> */}
                                        <br />
                                        <Form.Group>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label><i><u>General</u></i></Form.Label>
                                                    <Row>
                                                        <Checkbox label="Unknown" id="problemCheckbox-Unknown" color="primary" checked={this.state.problemCheckboxUnknown} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Handle Issue" id="problemCheckbox-Handle Issue" color="primary" checked={this.state.problemCheckboxHandleIssue} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Water Feed Valve Break" id="problemCheckbox-Water Feed Valve Break" color="primary" checked={this.state.problemCheckboxWaterFeedValveBreak} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Water Feed Valve Seal Leaking" id="problemCheckbox-Water Feed Valve Seal Leaking" color="primary" checked={this.state.problemCheckboxWaterFeedValveSealLeaking} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Overflow Tube Plugged" id="problemCheckbox-Overflow Tube Plugged" color="primary" checked={this.state.problemCheckboxOverflowTubePlugged} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Overflow Tube Failed" id="problemCheckbox-Overflow Tube Failed" color="primary" checked={this.state.problemCheckboxOverflowTubeFailed} onChange={this.handleInputChange} />
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            {/* </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                                    Flapper
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                    <Card.Body> */}
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label><i><u>Flapper</u></i></Form.Label>
                                                    <Row>
                                                        <Checkbox label="Flapper Open" id="problemCheckbox-Flapper Open" color="primary" checked={this.state.problemCheckboxFlapperOpen} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Flapper Loose" id="problemCheckbox-Flapper Loose" color="primary" checked={this.state.problemCheckboxFlapperLoose} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Flapper Valve Leaking" id="problemCheckbox-Flapper Valve Leaking" color="primary" checked={this.state.problemCheckboxFlapperValveLeaking} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Flapper Valve Mechanical Failure" id="problemCheckbox-Flapper Valve Mechanical Failure" color="primary" checked={this.state.problemCheckboxFlapperValveMechanicalFailure} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Flapper Chain Interference" id="problemCheckbox-Flapper Chain Interference" color="primary" checked={this.state.problemCheckboxFlapperChainInterference} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Flapper Chain Too Short" id="problemCheckbox-Flapper Chain Too Short" color="primary" checked={this.state.problemCheckboxFlapperChainTooShort} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Flapper Chain Too Long" id="problemCheckbox-Flapper Chain Too Long" color="primary" checked={this.state.problemCheckboxFlapperChainTooLong} onChange={this.handleInputChange} />
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                            {/* </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card> */}
                                            {/* <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                                    Tank
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="2">
                                                    <Card.Body> */}
                                            <Form.Group>
                                                <Form.Label><i><u>Tank</u></i></Form.Label>
                                                <Row>
                                                    <Checkbox label="Tank Break" id="problemCheckbox-Tank Break" color="primary" checked={this.state.problemCheckboxTankBreak} onChange={this.handleInputChange} />
                                                </Row>
                                                <Row>
                                                    <Checkbox label="Tank Hairline Crack" id="problemCheckbox-Tank Hairline Crack" color="primary" checked={this.state.problemCheckboxTankHairlineCrack} onChange={this.handleInputChange} />
                                                </Row>
                                                <Row>
                                                    <Checkbox label="Tank to Base Seal Failure" id="problemCheckbox-Tank to Base Seal Failure" color="primary" checked={this.state.problemCheckboxTanktoBaseSealFailure} onChange={this.handleInputChange} />
                                                </Row>
                                                <Row>
                                                    <Checkbox label="Tank Handle or Handle Rod Loose" id="problemCheckbox-Tank Handle or Handle Rod Loose" color="primary" checked={this.state.problemCheckboxTankHandleorHandleRodLoose} onChange={this.handleInputChange} />
                                                </Row>
                                                <Row>
                                                    <Checkbox label="Tank Handle or Handle Rod Failure" id="problemCheckbox-Tank Handle or Handle Rod Failure" color="primary" checked={this.state.problemCheckboxTankHandleorHandleRodFailure} onChange={this.handleInputChange} />
                                                </Row>
                                                <Row>
                                                    <Checkbox label="Tank Handle or Handle Rod Interference" id="problemCheckbox-Tank Handle or Handle Rod Interference" color="primary" checked={this.state.problemCheckboxTankHandleorHandleRodInterference} onChange={this.handleInputChange} />
                                                </Row>

                                            </Form.Group>
                                            {/* </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card> */}
                                            {/* <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                                    Base
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="3">
                                                    <Card.Body> */}
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label><i><u>Base</u></i></Form.Label>
                                                    <Row>
                                                        <Checkbox label="Base Break" id="problemCheckbox-Base Break" color="primary" checked={this.state.problemCheckboxBaseBreak} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Base Hairline Crack" id="problemCheckbox-Base Hairline Crack" color="primary" checked={this.state.problemCheckboxBaseHairlineCrack} onChange={this.handleInputChange} />
                                                    </Row>
                                                    <Row>
                                                        <Checkbox label="Base to Floor Seal Failure" id="problemCheckbox-Base to Floor Seal Failure" color="primary" checked={this.state.problemCheckboxBasetoFloorSealFailure} onChange={this.handleInputChange} />
                                                    </Row>
                                                </Form.Group>
                                            </Col>

                                            {/* </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion> */}
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group>
                                        {/* Repair Checkboxes*/}
                                        <Form.Label><b>Repair(s) Made?</b></Form.Label>
                                        <Row>
                                            <Form.Group>
                                                <Checkbox label="None" id="repairCheckbox-None" color="primary" checked={this.state.repairCheckboxNone} onChange={this.handleInputChange} />

                                                <Checkbox label="Clean Toilet" id="repairCheckbox-Clean Toilet" color="primary" checked={this.state.repairCheckboxCleanToilet} onChange={this.handleInputChange} />

                                                <Checkbox label="Toilet Replacement" id="repairCheckbox-Toilet Replacement" color="primary" checked={this.state.repairCheckboxToiletReplacement} onChange={this.handleInputChange} />

                                                <Checkbox label="Handle Replaced" id="repairCheckbox-Handle Replaced" color="primary" checked={this.state.repairCheckboxHandleReplaced} onChange={this.handleInputChange} />

                                                <Checkbox label="Seal Replaced" id="repairCheckbox-Seal Replaced" color="primary" checked={this.state.repairCheckboxSealReplaced} onChange={this.handleInputChange} />

                                                <Checkbox label="Flapper Replaced" id="repairCheckbox-Flapper Replaced" color="primary" checked={this.state.repairCheckboxFlapperReplaced} onChange={this.handleInputChange} />

                                                <Checkbox label="Float Replaced" id="repairCheckbox-Float Replaced" color="primary" checked={this.state.repairCheckboxFloatReplaced} onChange={this.handleInputChange} />

                                                <Checkbox label="Fill Valve Replaced" id="repairCheckbox-Fill Valve Replaced" color="primary" checked={this.state.repairCheckboxFillValveReplaced} onChange={this.handleInputChange} />

                                                <Checkbox label="Flapper Valve Replaced" id="repairCheckbox-Flapper Valve Replaced" color="primary" checked={this.state.repairCheckboxFlapperValveReplaced} onChange={this.handleInputChange} />
                                            </Form.Group>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label><b>Date</b></Form.Label>
                                        <Row>
                                            <DatePicker
                                                id="repairDatePicker"
                                                onChange={this.handleDateChange}
                                                selected={this.state.repairDate}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                            />
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label><b>Details</b></Form.Label>
                                        <Form.Control as="textarea" id="notesInput" rows="3" onChange={this.handleInputChange} placeholder="Repair details..." />
                                    </Form.Group>
                                    {this.state.formButton}
                                </Form>
                            </Col>
                        </Row>
                        <br />
                        <Row className="repairTableRow">
                            <Col xs={12} >
                                <h2>Repair Log</h2>
                                <Table id="repairTable" bordered striped hover>
                                    <thead>
                                        <tr>
                                            <th>Location</th>
                                            <th>Date</th>
                                            <th>Problem</th>
                                            <th>Repair</th>
                                            <th>Details</th>
                                            <th> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.repairTableRows}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
        else {
            return (
                <Container>
                    <LoadingView />
                </Container>
            );
        }
    }
}

export default withRouter(RepairsView);