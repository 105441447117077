import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import './WaterWasteCalculator.scss';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class WaterWasteCalculatorView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            // breakEven: "0 hours",
            breakEven: "",
            maintenanceAge: 0,
            numRooms: 100,
            devicePrice: 74.99,
            waterRate: null,
            waterUnits: null,
            costPerGallon: null
        }

    }

    componentDidMount() {
        document.body.style.backgroundColor = "#EBF2F8";
    }

    handleTyping = (event, field) => {
        console.log(field);
        console.log(event.target.value);
        this.setState({
            [field]: event.target.value
        })
    }

    handleMaintenanceAge = (event) => {
        console.log(this.state.maintenanceAge);
    }

    // Trey's Water Price Calculations
    postWaterRate = () => {
        if (this.state.waterRate == null ||
            this.state.waterUnits == null ||
            this.state.maintenanceAge == null) {
            Swal.fire({
                icon: 'error',
                title: 'Whoops!',
                text: 'Please fill out all fields.'
            })
            return;
        }

        let costPerGallon = 0.00;
        let rate = parseFloat(this.state.waterRate);
        const waterUnits = this.state.waterUnits.split(/(\s+)/).filter(e => e.trim().length > 0);
        const quant = parseInt(waterUnits[0]);
        const unit = waterUnits[1];
        if (unit === "Gallons") {
            costPerGallon = rate / quant;
        } else if (unit === "Cubic") {
            costPerGallon = rate / (quant * 7.481);
        }
        console.log(costPerGallon);

        if (this.state.maintenanceAge == 0) {
            // this.calculateBreakEven(costPerGallon);
            this.calculateAverageBreakEven(costPerGallon);
        }
        else {
            this.calculateBreakEvenWithAge(costPerGallon);
        }
    }

    calculateBreakEven = (costPerGallon) => {
        const breakEvenGallons = this.state.devicePrice / costPerGallon;
        const breakEvenMintues = breakEvenGallons * 0.37;

        if (breakEvenMintues > 59) {
            let breakEven = breakEvenMintues / 60;
            breakEven = (Math.round(breakEven * 4) / 4).toFixed(2) //Round to the nearest quarter
            this.setState({
                breakEven: `${breakEven} hours`
            });
        }
        else {
            let breakEven = Math.ceil(breakEvenMintues);
            this.setState({
                breakEven: `${breakEven} mintues`
            });
        }
    }

    calculateBreakEvenWithAge = (costPerGallon) => {

        console.log(this.state.maintenanceAge);
        let SRD = 1.7192 * this.state.maintenanceAge - 1.7657;
        if (this.state.maintenanceAge == 1) { SRD = 0.6 }
        const AWL = SRD * this.state.numRooms * 365.24;
        const AS = AWL * costPerGallon;
        const IC = this.state.numRooms * this.state.devicePrice;
        const PB = 12 * (IC / AS);
        let breakEven = Math.floor(PB);
        if (breakEven < 1) { breakEven = 1 }

        this.setState({
            breakEven: `${breakEven} months`
        });

        // console.log('SRD ' + SRD);
        // console.log('AWL ' + AWL);
        // console.log('AS ' + AS);
        // console.log('IC ' + IC);
        // console.log('PB ' + PB);
    }

    calculateAverageBreakEven = (costPerGallon) => {
        var breakEvenMonths = [];

        for (let i = 5; i < 11; i++) {
            let SRD = 1.7192 * i - 1.7657;
            const AWL = SRD * this.state.numRooms * 365.24;
            const AS = AWL * costPerGallon;
            const IC = this.state.numRooms * this.state.devicePrice;
            const PB = 12 * (IC / AS);
            let breakEven = Math.floor(PB);
            if (breakEven < 1) { breakEven = 1 }
            breakEvenMonths.push(breakEven);
            console.log(breakEven);
        }

        var averageBreakEven = 0;
        breakEvenMonths.forEach((curBEM) => {
            averageBreakEven += curBEM;
        });
        console.log(averageBreakEven);
        averageBreakEven = Math.floor(averageBreakEven / 6);

        this.setState({
            breakEven: `${averageBreakEven} months`
        });
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container className='pageOuterContainer'>
                    <Row style={{ height: '20%', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="calcLogo">
                            <div className="calcLogoClass" href="/">
                                <span>
                                    <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg" />
                                </span>
                            </div>
                        </div>
                    </Row>
                    <Row style={{ height: '60%' }}>
                        <Col className='boxBackground'>
                            <Row className="calcRow">
                                <Form>
                                    {/* Trey's Water Price Input from SetupView */}
                                    <p style={{ textAlign: 'center', marginTop: 24 }}>Enter Combined Water and Sewer Rate</p>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Dollars</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Water Sewer Rate"
                                                    aria-describedby="DollarRate"
                                                    value={this.state.waterRate}
                                                    required
                                                    fullWidth
                                                    onChange={(event) => {
                                                        this.handleTyping(event, 'waterRate');
                                                    }}
                                                />
                                                <Form.Text id="DollarRate" muted>
                                                    Enter Combined Water and Sewer Rate
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <span style={{ paddingTop: '10px' }}>per</span>
                                        <Col style={{ width: '30%' }}>
                                            <Form.Control
                                                id="unitInput1"
                                                required
                                                onChange={(event) => {
                                                    this.handleTyping(event, 'waterUnits');
                                                }}
                                                as="select"
                                            >
                                                <option>{(this.state.waterUnits == null) ? '-' : this.state.waterUnits}</option>
                                                <option>100 Gallons</option>
                                                <option>100 Cubic Feet</option>
                                                <option>1000 Gallons</option>
                                                <option>1000 Cubic Feet</option>
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>How Many Rooms?</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Room Number"
                                                    aria-describedby="Room"
                                                    value={this.state.numRooms}
                                                    required
                                                    fullWidth
                                                    onChange={(event) => {
                                                        this.handleTyping(event, 'numRooms');
                                                    }}
                                                />
                                                <Form.Text id="Room" muted>
                                                    Enter number of Rooms.
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Price Per Device</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="Device Price"
                                                    value={this.state.devicePrice}
                                                    required
                                                    fullWidth
                                                    onChange={(event) => {
                                                        this.handleTyping(event, 'devicePrice');
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {/* <br />
                                    <p style={{textAlign: 'center', marginTop: 24, paddingBottom: 24}}>How long ago was the last maintenance on your toilet?</p>
                                    <Form.Control as="select" onChange={(event) => {this.handleTyping(event, "maintenanceAge")}}>
                                        <option value={0}>Unknown</option>
                                        <option value={1}>1 Year or Less</option>
                                        <option value={2}>2 Years</option>
                                        <option value={3}>3 Years</option>
                                        <option value={4}>4 Years</option>
                                        <option value={5}>5 Years</option>
                                        <option value={6}>6 Years</option>
                                        <option value={7}>7 Years</option>
                                        <option value={8}>8 Years</option>
                                        <option value={9}>9 Years</option>
                                        <option value={10}>10 Years</option>
                                    </Form.Control> */}
                                </Form>
                                <br />
                                <Button variant="primary" style={{ backgroundColor: '#3578bd', borderWidth: 0, width: '100%' }} onClick={this.postWaterRate}>Calculate</Button>
                            </Row>
                        </Col>
                        {/* Alternate Right Column Based On Calculation Type */}
                        {this.state.maintenanceAge == 0 ?
                            // <Col className='boxBackground'>
                            //     <Row style={{alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            //         <div>
                            //             <h2 className="calcText">Your FlowActive Device will pay for itself after</h2> 
                            //             <h2 className="calcText" style={{color: '#3578bd'}}>{this.state.breakEven}</h2> 
                            //             <h2 className="calcText">of a continuously leaking toilet.*</h2>
                            //         </div>
                            //     </Row>
                            // </Col>
                            <Col className='boxBackground'>
                                <Row style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <h2 className="calcText">Your FlowActive Devices will pay for themselves within:</h2>
                                    <h2 className="calcText" style={{ color: '#3578bd' }}>{this.state.breakEven}</h2>
                                </Row>
                            </Col>
                            :
                            <Col className='boxBackground'>
                                <Row style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <h2 className="calcText">Your FlowActive Device will pay for itself within:</h2>
                                    <h2 className="calcText" style={{ color: '#3578bd' }}>{this.state.breakEven}</h2>
                                </Row>
                            </Col>
                        }
                    </Row>
                    <Row style={{ height: '20%' }}>
                        <Col className='boxBackground' style={{ height: '50%' }}>
                            <Row style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <p style={{ textAlign: 'center' }}>© 2021 FlowActive, Inc. This page is only an estimation of possible costs that could be encountered based on average data collected from FlowActive devices. This estimate is not a guarantee or quotation of any kind.</p>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else {
            return (
                <LoadingView />
            );
        }
    }
}

export default withRouter(WaterWasteCalculatorView);