import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView';
import Container from 'react-bootstrap/Container';
import config from '../../utilities/config.js';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

var autocompletDevices = [];

class ModifyDeviceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allDevices: [],
            loaded: false,
            modifyForm: null
        };
    }

    componentDidMount() {
        this.getAllDevices()
    }

    clearform = () => {
        this.setState({
            modifyForm: null,
        })
    }

    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                console.log(response)
                const data = response["data"];
                const devices = data.map((item) => {
                    autocompletDevices.push({name: item.deviceID,id: item.id});
                    return item;
                });
                this.setState({
                    allDevices: devices,
                    loaded: true
                });
                
            },
                (error) => {
                    console.log(error);
                }
            );
    }

    render() {
        const handleOnSelect = (item) => {
            // the item selected
            console.log(item.name)
        }

        if (this.state.loaded) {
            console.log("LENGTH")
            console.log(autocompletDevices.length)
            Swal.close();
            return (
                <Container>
                    <table>
                        <tbody>
                            <tr style={this.styleRow}>
                                <td style={this.styleCombobox}>
                                    <ReactSearchAutocomplete
                                        items={autocompletDevices} 
                                        onSearch={() => {}}//ignored
                                        onHover={() => {}}//ignored
                                        onSelect={handleOnSelect}
                                        onFocus={() => {}}//ignored
                                        onClear={this.clearform}
                                        autoFocus
                                        showNoResultsText="No devices found"
                                        showItemsOnFocus={true}
                                        formatResult={(item) => {
                                            return (
                                                <span>{Object.values(item.name)}</span>
                                            )
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Container>
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(ModifyDeviceView);