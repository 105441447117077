import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import $ from 'jquery';
import moment from 'moment';
import './WaterUseView.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GraphBlock from '../../components/Block/DashboardBlocks/GraphBlock.js';
import GallonsUsed from '../../components/Block/DashboardBlocks/GallonsUsed.js';
import GallonsCost from '../../components/Block/WaterUseBlocks/GallonsCost.js';
import GallonsLeaked from '../../components/Block/DashboardBlocks/GallonsLeaked.js';
import LeakCost from '../../components/Block/WaterUseBlocks/LeakCost.js';
import TopUsingRooms from '../../components/Block/WaterUseBlocks/TopUsingRooms';
import Flushes from '../../components/Block/WaterUseBlocks/Flushes';
import HeatMapChart from '../../components/Chart/HeatMapChart/HeatMapChart';
  

class WaterUseView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : null,
            selectedBuilding: null,
            buildingTitle: null,
            leakData: null,
            buildings: null,
            startDate: null,
            endDate: null,
            widgetData: null,
            totalRepairs: 0,
            leakTotal: 0,
            dateRange: null,
            loaded: false
        };
    }
    
    componentDidMount() {
        document.body.style.backgroundColor = "#EBF2F8";
        this.generateWaterSummary(config.dateRange);
    }

    exportData = (api, token, title) => {
        if (this.state.loaded) {

            let totalGallons = 0;
            let totalGallonsCost = 0;
            let totalFlushes = 0;
            let totalGallonsLost = 0;
            let totalGallonsLeakedCost = 0;

            let data = this.state.data;
            let leakData = this.state.leakData;
            let gData = null;
            let gallonData = [];
            let wastedData = [];
            let keys = null;

            if(data != null){

                keys = Object.keys(data).sort();
                gallonData = [];
                wastedData = [];
                for(let i =0 ; i < keys.length ; i++){
                    let key = keys[i];
                    let gallons = parseInt(data[key]["gallonsUsed"]);

                    let flushes = data[key]["flushes"];
                    let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
                    let leakCost = Number(parseFloat(data[key]["leakCost"]).toFixed(2));
                    let gallonsUsedCost = Number(parseFloat(data[key]["cost"]).toFixed(2));
                    gallonData.push(gallons);
                    wastedData.push(gallonsWasted);
                    totalGallons += gallons;
                    totalGallonsCost += gallonsUsedCost;
                    totalFlushes += flushes;
                    totalGallonsLost += gallonsWasted;
                    totalGallonsLeakedCost += leakCost;
                }
            }

            const chartDates = Object.keys(this.state.data).sort();
            const chartGals = [];
            const chartLeakedGals = [];
            for (let i = 0; i < chartDates.length; i++) {
                chartGals.push(this.state.data[chartDates[i]]["gallonsUsed"]);
                chartLeakedGals.push(this.state.data[chartDates[i]]["gallonsWasted"]);
            }

            const exportRoomData = []
            const leaks = this.state.leakData;
            for (let i = 0; i < Object.keys(this.state.leakData).length; i++) {
                let buildingKey = Object.keys(this.state.leakData)[i];
                for (let j = 0; j < Object.keys(this.state.leakData[buildingKey]).length; j++) {
                    let leakKey = Object.keys(this.state.leakData[buildingKey])[j];
                    exportRoomData.push({
                        "building" : this.state.leakData[buildingKey][leakKey]["buildingLabel"],
                        "date" : this.state.leakData[buildingKey][leakKey]["date"],
                        "name" : this.state.leakData[buildingKey][leakKey]["locationLabel"],
                        "num_gallons" : this.state.leakData[buildingKey][leakKey]["gallons"]
                    });
                }
            }

            exportRoomData.sort((a, b) => (a.date > b.date) ? 1 : (a.date === b.date) ? ((a.num_gallons < b.num_gallons) ? 1 : -1) : -1 );

            exportRoomData.forEach(element => element.num_gallons = element.num_gallons.toLocaleString());



            const requestBody = {
                "reportType" : "water-use",
                "data": {
                    "building_title": title,
                    "building_subtitle": "",
                    "start_date": this.state.startDate,
                    "end_date": this.state.endDate,
                    "room_name": "",
                    "num_flushes": totalFlushes.toLocaleString(),
                    "gallons_used": totalGallons.toLocaleString(),
                    "gallons_leaked": totalGallonsLost.toLocaleString(),
                    "gallons_used_cost": totalGallonsCost.toLocaleString(undefined, {maximumFractionDigits:2}),
                    "gallons_leaked_cost": totalGallonsLeakedCost.toLocaleString(undefined, {maximumFractionDigits:2}),
                    "gallons_used_array": chartGals,
                    "gallons_leaked_array": chartLeakedGals,
                    "chart_dates": chartDates,
                    "rooms": exportRoomData
                }  
            };

            let url = api+`pdf-page-export`;
            let auth = `Bearer `+token;
            var config = {
                method: 'post',
                url: url,
                headers: { 
                  'Authorization': auth, 
                  'Content-Type': 'application/json', 
                },
                data : requestBody
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
              });

        }
    }; 
     
    generateWaterSummary(numDays) {
        this.setState({
            dateRange: numDays,
            loaded: false
        });
        localStorage.setItem("mlDateRange" , numDays);
       
        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response) => {
            let buildings = response.data;

            if (buildings.length == 1){//User owns one building
                let building = buildings[0];
                let buildingID = building["id"];
                localStorage.setItem("mlBuildingID" , buildingID);
                config.selectedBuilding = buildingID;

                // let startDateString = this.state.startDate;
                // let endDateString = this.state.endDate;
                // let startDate = null;
                // let endDate = null;
                // let nStartDateString = null;
                // let nEndDateString = null;
                // if (startDate != null){
                //     nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                // }
                // else{
                //     nStartDateString = moment().subtract(numDays , 'days').format("YYYY-MM-DD");
                // }

                // if (endDate != null){
                //     nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                // }
                // else{
                //     nEndDateString = moment().format("YYYY-MM-DD");
                // }

                
                this.getBuildingInformation(buildings , config.startDate , config.endDate);
                
                

            }
            else if(buildings.length == 0){
                //this shouldn't be the case but possible and we should handle it
                Swal.fire(
                    {"title" : "You have no buildings setup for this account. Click OK to return to the profile page"}
                ).then((result) => {
                    /* Read more about handling dismissals below */
                    window.location.href ="/profile";
                    
                });



            }
            else{//User owns multiple buildings

                // let startDateString = $("#dp-startDate").val();
                // let endDateString = $("#dp-endDate").val();
                // let startDate = null;
                // let endDate = null;
                // let nStartDateString = null;
                // let nEndDateString = null;
                // if (startDate != null){
                //     nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                // }
                // else{
                //     nStartDateString = moment().subtract(numDays , 'days').format("YYYY-MM-DD");
                // }

                // if (endDate != null){
                //     nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                // }
                // else{
                //     nEndDateString = moment().format("YYYY-MM-DD");
                // }
                
                // this.setState({
                //     startDateString: nStartDateString,
                //     endDateString: nEndDateString
                // });
                if (config.selectedBuilding == 0) {
                    this.getUserBuildingInformation(buildings , config.startDate , config.endDate);
                }
                else {
                    this.getBuildingInformation(buildings , config.startDate , config.endDate);
                }
            }
        });
    }

    getBuildingInformation(buildings , startDate , endDate){

        let url = `${config.api}metrics/portalStats/building/${config.selectedBuilding}?startDate=${startDate}&endDate=${endDate}`;
        console.log(url);

        let sCall = Date.now();
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response2) => {
            let eCall = Date.now();



            let lData = response2["data"];
            let keys = Object.keys(lData);
            let fKey = keys[0];
            let iData = lData[fKey]["statsByDate"];
            let rData = {};

            let iDataKeys = Object.keys(iData);
            for(let i =0 ; i < iDataKeys.length; i++){
                let curKey = iDataKeys[i]
                let curData = iData[curKey];

                let date = curKey;
                let gallons = curData["gallonsUsed"];
                let flushes = curData["flushes"];
                let gallonsWasted = curData["gallonsWasted"];
                let leakCost = curData["leakCost"];
                rData[date] = curData;

            }
            let leakData = {}
            let leakTotal = 0
            leakData[fKey] = lData[fKey]["statsForProblems"];
            leakTotal = Object.keys(leakData[fKey]).length;

            // const cSDate = this.getCalendarDate(startDate);
            // const cEDate = this.getCalendarDate(endDate);
            this.setState({
                data: rData,
                leakData: leakData,
                leakTotal: leakTotal,
                buildings: buildings,
                selectedBuilding: config.selectedBuilding,
                startDate: startDate,
                endDate: endDate,
                loaded: true
            });


            console.log(this.state);
            
            //=============================


            },
            (error) => {
                console.log(error);
            }
          );
    }

    getUserBuildingInformation(buildings , startDate , endDate){

        let selectedBuilding = 0;
        if (this.state.selectedBuilding != null) {
            selectedBuilding = this.state.selectedBuilding;
        }
        var now = new Date();
        var timestamp = now.getTime();
    
        
        let url = `${config.api}metrics/portalStats/user/${config.userID}?&startDate=${startDate}&endDate=${endDate}`;
        console.log(url);

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response) => {


            //TODO AGGREGATE DATA BETWEEN THE DIFFERENT BUILDINGS AND THE DATES
            let lData = response["data"];
            let keys = Object.keys(lData);
            let rData = {};

            for(let j =0 ; j < keys.length ; j++){
                let fKey = keys[j];
                let iData = lData[fKey]["statsByDate"];

                let iDataKeys = Object.keys(iData);
                for(let i =0 ; i < iDataKeys.length; i++){
                    let curKey = iDataKeys[i]
                    let curData = iData[curKey];

                    let date = curKey;
 

                    //there are better ways of merging the objects, but this is the way 
                    if(rData[date] == undefined){
                        rData[date] = curData;
                    }
                    else{
                        rData[date] = this.mergeTwoObjectValues(rData[date] , curData , Object.keys(curData));
                    }


                }
            }

            let leakData = {}
            let leakTotal = 0
            for (let i =0 ; i < keys.length ; i++){
                const curKey = keys[i];
                leakData[curKey] = lData[curKey]["statsForProblems"];
                leakTotal += Object.keys(leakData[curKey]).length
            }
            
            // const cSDate = this.getCalendarDate(startDate);
            // const cEDate = this.getCalendarDate(endDate);

            this.setState({
                data: rData,
                leakData: leakData,
                leakTotal: leakTotal,
                buildings: buildings,
                selectedBuilding: selectedBuilding,
                startDate: startDate,
                endDate: endDate,
                loaded: true
            });

            //=============================
            
            },
            (error) => {
                console.log(error);
            }
          );
    }
    
    //assuming the keys are the same for each , otherwise, you should use the {...obj1 , ...obj2} operator first
    mergeTwoObjectValues(obj1 , obj2 , keys){
        let obj3 = {};
        for(let i = 0 ; i < keys.length ; i++){
            let curKey = keys[i];
            let val = obj1[curKey] + obj2[curKey];
            obj3[curKey] = val;
        }

        return obj3;


    }

    //this doesn't do date conversions properly, but will do the date conversion so that it shows properly
    //on the calendar option for people to choose from
    getCalendarDate(stringDate){

        let year = parseInt(stringDate.substring(0 , 4));
        let month = parseInt(stringDate.substring(5,7));
        let day = parseInt(stringDate.substring(8,10));
        let a = new Date();
        a.setMonth(month - 1);
        a.setDate(day);
        a.setFullYear(year);
        a.setHours(0);
        a.setMinutes(0);
        a.setSeconds(0);
        return a;
    }

    // handleBuildingSelect = (e) => {
    //     const buildings = this.state.buildings;
    //     const buildingID = e;
        
    //     this.setState({
    //         loaded: false
    //     });
        
    //     let startDate = this.state.startDate
    //     let endDate = this.state.endDate;

    //     if(buildingID == 0){
    //         localStorage.setItem("mlBuildingID" , null);
    //         const userID = localStorage.getItem("mlUserID");

    //         this.getUserBuildingInformation(userID , token , buildings , startDate , endDate);
    //     }
    //     else{
    //         localStorage.setItem("mlBuildingID" , buildingID);

    //         this.getBuildingInformation(buildingID , token , buildings , startDate , endDate);
    //     }
    // }  

    // timeChange = (event) => {
    //     console.log(event);
    //     let newRange = event.target.value;
    //     this.setState({
    //         loaded: false,
    //         startDate: null,
    //         endDate: null,
    //         dateRange: newRange
    //     });

    //     let startDate = moment().subtract(newRange , 'days').format("YYYY-MM-DD");
    //     let endDate = moment().format("YYYY-MM-DD");
        
    //     let buildingID = this.state.selectedBuilding;
    //     let buildings = this.state.buildings;
    //     let token = localStorage.getItem("mlToken");

    //     if(buildingID == 0 || buildingID == null){
    //         localStorage.setItem("mlBuildingID" , 0);
    //         localStorage.setItem("mlDateRange" , newRange);
    //         const userID = localStorage.getItem("mlUserID");

    //         this.getUserBuildingInformation(userID , token , buildings , startDate , endDate);
    //     }
    //     else{
    //         localStorage.setItem("mlBuildingID" , buildingID);
    //         localStorage.setItem("mlDateRange" , newRange);

    //         this.getBuildingInformation(buildingID , token , buildings , startDate , endDate);
    //     }
    // }

    render() {
        if (this.state.loaded) {

            let sevenDayGallons = 0;
            let sevenDayGallonsUsedCost = 0;
            let sevenDayFlushes = 0;
            let sevenDayGallonsLost = 0;
            let sevenDayGallonsCost = 0;

            let data = this.state.data;
            let leakData = this.state.leakData;
            let gData = null;
            let gallonData = [];
            let wastedData = [];
            let keys = null;

            if(data != null){

                keys = Object.keys(data).sort();
                gallonData = [];
                wastedData = [];
                for(let i =0 ; i < keys.length ; i++){
                    let key = keys[i];
                    let gallons = parseInt(data[key]["gallonsUsed"]);

                    let flushes = data[key]["flushes"];
                    let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
                    let leakCost = Number(parseFloat(data[key]["leakCost"]).toFixed(2));
                    let gallonsUsedCost = Number(parseFloat(data[key]["cost"]).toFixed(2));
                    gallonData.push(gallons);
                    wastedData.push(gallonsWasted);
                    sevenDayGallons += gallons;
                    sevenDayGallonsUsedCost += gallonsUsedCost;
                    sevenDayFlushes += flushes;
                    sevenDayGallonsLost += gallonsWasted;
                    sevenDayGallonsCost += leakCost;
                }
            }

            Swal.close();
            return (
                <div id="waterUseOuterContainer" className="waterUseOuterContainer" >
        
                    <Sidebar 
                        pageWrapId={"waterUsePageWrap"} 
                        outerContainerId={"waterUseOuterContainer"} 
                        generateSummary={this.generateWaterSummary.bind(this)}
                        buildings={this.state.buildings} 
                        buttonsVisible={true} 
                        handleExport={this.exportData}
                    />
     
                    <Container id="waterUsePageWrap" className="waterUsePageWrap" >
                        <Row className="waterUse-top-row">
                            <Col xs={12} className="waterUseGraphBlock">
                                <GraphBlock keys={keys} gallonData={gallonData} wastedData={wastedData} />
                            </Col>
                        </Row>
                        <Row className="waterUse-bottom-row">
                            <Col md={6} className="dashboardBlock" >
                                <TopUsingRooms buildings={this.state.buildings} leakData={leakData} />
                            </Col>
                            <Col md={6}>
                                <Row className="waterUse-used-top-row">   

                                    <Col md={6} className="dashboardBlock">
                                        <Row>
                                            <Col md={12} className="testBlock">
                                                <GallonsUsed gallonsUsed={sevenDayGallons} />
                                            </Col>
                                            <div className="w-100"></div>
                                            <Col md={12} className="testBlock-Bottom">
                                                <GallonsCost gallonsCost={sevenDayGallonsUsedCost} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6} className="dashboardBlock">
                                        <Row>
                                            <Col md={12} className="testBlock">
                                                <GallonsLeaked gallonsLeaked={sevenDayGallonsLost} />
                                            </Col>
                                            <div className="w-100"></div>
                                            <Col md={12} className="testBlock-Bottom">
                                                <LeakCost leakCost={sevenDayGallonsCost} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                </Row>
                                <Row className="waterUse-used-bottom-row">
                                    <Col className="flushesBlock">
                                        <Flushes flushes={sevenDayFlushes} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="waterUse-bottom-row">
                            <Col md={12} className="" >
                                <HeatMapChart />
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
        else {
            return (
                <LoadingView />
            );
        }
    }
}

export default withRouter(WaterUseView);