import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

import DatePicker from 'react-datepicker';

class InstallCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            allBuildingOptions: [],
            selectedBuilding: 0,
            startDate: null,
            endDate: null,
            tableRows: null
        }

    }

    componentDidMount() {
        this.getAllBuildings();
    }

    getAllBuildings = () => {
        axios({
           method: 'get',
           url: `${config.api}get-all-buildings`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           const data = response['data'];
           const buildingOptions = data.map((building) => {
               return (
                <option value={building.id}>{building.label}</option>
               )
           });
           this.setState({
               allBuildingOptions: buildingOptions,
               loaded: true
           });
        })
        .catch((error) => {
           console.log(error);
        });
    }

    getInstallerVerification = () => {
        console.log('in verify');
        axios({
           method: 'get',
           url: `${config.api}tool/check-install-devices/${this.state.selectedBuilding}?startTime=${this.state.startDate}&endTime=${this.state.endDate}`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           const data = response['data'];
           this.updateRows(data);
        })
        .catch((error) => {
           console.log(error);
        });
    }

    updateRows = (data) => {
        console.log('in update');
        const tableRows = data.map((info) => {
            let tbdStatus = "✅";
            if (info.lastTimeResponded < this.state.startDate) {
                tbdStatus = `❌`
            }
            return( 
                <tr>
                    <td>{info.locationLabel}</td>
                    <td>{tbdStatus}</td>
                    <td>{info.deviceIDString}</td>
                </tr>
            );
        });
        this.setState({
            tableRows: tableRows
        })
    }

    handleBuildingChange = (event) => {
        this.setState({
            selectedBuilding: event.target.value
        })
    }

    handleStartDate = (date) => {
        this.setState({
            startDate: date.getTime()
        })
    }

    handleEndDate = (date) => {
        this.setState({
            endDate: date.getTime()
        })
    }

    handleSubmit = () => {
        console.log('in submit');
        if (this.state.selectedBuilding == 0 ||
                this.state.startDate == null || 
                this.state.endDate == null) {
            Swal.fire({
                title: 'Error',
                text: 'Please fill out all fields',
                icon: 'error'
            });
        }

        this.getInstallerVerification();
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <Row style={{height: '20%', paddingTop: 12}}>
                        <Col>
                            <text>Building Select</text> <br />
                            <Form.Control as="select" onChange={this.handleBuildingChange}>
                                <option value="0">Select a Building</option>
                                {this.state.allBuildingOptions}
                            </Form.Control>
                        </Col>
                        <Col>
                            <text>Install Start</text> <br />
                            <DatePicker 
                                onChange={this.handleStartDate} 
                                selected={this.state.startDate} 
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="Time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                        </Col>
                        <Col>
                            <text>Install Finish</text> <br />
                            <DatePicker 
                                onChange={this.handleEndDate} 
                                selected={this.state.endDate} 
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="Time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                            />
                        </Col>
                        <Col>
                            <Button type="primary" onClick={this.handleSubmit}>Submit</Button>
                        </Col>
                    </Row>
                    <Row style={{height: '80%', paddingTop: 12}}>
                        <Table striped bordered color="#000">
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Status</th>
                                    <th>Device</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tableRows}
                            </tbody>
                        </Table>
                    </Row>
                </Container>
            );
        }
        else {
            return(
                <LoadingView />
            );
        }
    }
}

export default withRouter(InstallCheck);