import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import AllLocationsTable from '../../components/Table/AllLocationsTable/AllLocationsTable';
import config from '../../utilities/config.js';

var allLcoations = {};
var buildingNames = {};

class AllLocationsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingIDs: [],
            loadCount: 0
        };
    }

    componentDidMount() {
        this.getallBuildings();
    }

    getallBuildings() {
        //Get all building IDs
        let url = `${config.api}get-all-buildings`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            const data = response["data"];
            const buildingIDs = data.map((item) => {
                buildingNames[item.id] = item.label;
                return (item.id);
            });
            this.setState({
                buildingIDs: buildingIDs.length
            });
            this.getLocations(buildingIDs);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    getLocations(buildingIDs) {
        buildingIDs.forEach((id) => {
            //Get all locations in a building
            let token = localStorage.getItem("mlToken");
            let url = `${config.api}buildings/${id}/locations`;
            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
            .then((response) => {
                const data = response["data"];
                console.log(data)
                var locations = data.map((item) => {
                    return (item);
                });
                allLcoations[id] = locations;
                var loadCount = this.state.loadCount;
                loadCount += 1;
                this.setState({
                    loadCount: loadCount
                });
            },
            (error) => {
                console.log(error);
            }
            );
        });
    }
    
    render() {
        return (
            <Container>
                <h2>All Locations</h2>
                <br />
                <AllLocationsTable buildingIDs={allLcoations} buildingNames={buildingNames} />
            </Container>
        );
    }
}

export default withRouter(AllLocationsView);