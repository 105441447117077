import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';



class LoadingView extends Component{
    
    constuctor() {
        this.routeChange = this.routeChange.bind(this);

    }

    render(props) {
        return (
          <div>
          </div>
        );
      }

    componentDidMount(){
        Swal.fire({
            'title' : 'Loading',
            'timer' : 30000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        
        
        }).then((result) => {
        /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {

                Swal.fire({
                    'title' : 'Error Loading Page',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    html:
                    '<div style="text-align:left"><b>Select an option below:</b><br><br>1. <a href="" onclick="location.reload()">Reload</a> Page<br><br>2. Return to <a href="/">Home Page</a><br><br>' +
                    '3. If issues persist, <a href="/logout">Relog</a> into Portal</div>' 
                });


                //console.log('I was closed by the timer')
            }
        })
    }  
}


export default withRouter(LoadingView);