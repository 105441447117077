import React from 'react';
import { withRouter } from 'react-router';

import './AlertBlock.scss';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import AlertBlockIcon from '../../../images/alertBlock.js';
import AlertHistoryIcon from '../../../images/alertHistoryIcon.js';
import config from '../../../utilities/config';

const AlertBlock = (props) => {
    const problem = props.problem;

    let endDateParts = problem.date.split("-");
    let startDateParts = config.startDate.split("-");
    for (var i=0; i<3; i++) {
        endDateParts[i] = endDateParts[i].replace(/^0+/, '');
        startDateParts[i] = startDateParts[i].replace(/^0+/, '');
    }
    const endDateString = endDateParts[1] + "/" + endDateParts[2] + "/" + endDateParts[0].substring(2,4);
    const startDateString = startDateParts[1] + "/" + startDateParts[2] + "/" + startDateParts[0].substring(2,4);
    const dateString = startDateString + " - " + endDateString;

    let flushData = props.flushData;
    let flushCount = 0;
    if (flushData != null) {
        let tmpBData = flushData[problem.building];
        if (tmpBData != null){
            let tmpRData = tmpBData[problem.locationLabel];
            if (tmpRData != null){
                flushCount = tmpRData["flushes"];
            }
     
        }
    }
    else {
        flushCount = problem.flushCount;
    }

    const gallons = Number(problem.gallons).toLocaleString();
    const flushes = Number(flushCount).toLocaleString();

    function handleAlertHistory() {
        props.showAlertHistory(problem.locationID);
    }

    return (
        <Container className="alertBlock" >
            <Row onClick={() => { 
            props.history.push(`/room-view?roomName=${problem.locationLabel}&building=${problem.building}`); 
            config.selectedBuilding = problem.building; 
            localStorage.setItem("mlBuildingID" , problem.building);
        }}>
                <Col xs={4} md={2} className="alertBlockIcon">
                    <AlertBlockIcon className="sidebarIcon" fill={props.severityColor}/>
                </Col>
                <Col xs={8} md={10} className="alertTable" >
                    <Table borderless responsive >
                        <thead>
                        </thead>
                        <tbody>
                            <tr className="alertBlockRow1">
                                <td>Date</td>
                                <td>Building</td>
                                <td>Location</td>
                                <td>Total Gallons Wasted</td>
                                <td>Total Flushes</td>
                            </tr>
                            <tr className="alertBlockRow2">
                                <td>{dateString}</td>
                                <td>{problem.buildingLabel}</td>
                                <td>{problem.locationLabel}</td>
                                <td>{gallons}</td>
                                <td>{flushes}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="alertHistoryRow">
                <a className="alertHistoryButton" onClick={handleAlertHistory}>See Alert History</a>
                <AlertHistoryIcon fill="#7A8490" />
            </Row>
        </Container>
    );
};

export default withRouter(AlertBlock);