import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';

var userEmails = [];

class ModifyUserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUserOptions: null,
            selectedUserID: null,
            modifyForm: null,
            email: null,
            password: null,
            fName: null,
            lName: null,
            phoneNumber: null,
            isAdmin: false,
            adminsGetsAlerts: null,
            sendDailyReport: null
        }
    }

    componentDidMount() {
        this.getAllUsers()
    }

    getAllUsers() {
        //Get all Users
        axios.get(
            `${config.api}get-all-users`,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
            )
            .then((response) => {
                const data = response["data"];
                const users = data.map((item) => {
                    return item;
                });
                this.createAllUserOptions(users);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    createAllUserOptions(users) {
        const options = users.map((item) => {
            userEmails[item.id] = item.email;
            return (
               <option value={item.id}>{item.email}</option>
            );
        })
        console.log(userEmails);
        this.setState({
            allUserOptions: options
        });
    }

    handleUserChange = (event) => {
        this.setState({
            selectedUserID: event.target.value
        });
        this.getUserDetails(event.target.value)
    };

    getUserDetails(selected) {
        //Get all Users details
        axios.get(
            `${config.api}users/${selected}/info`,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
        .then((response) => {
            const data = response["data"];
            console.log(data);
            const userInfo = data[0];
            console.log(userInfo);
            this.setState({
                email: userInfo.email,
                fName: userInfo.firstName,
                lName: userInfo.lastName,
                phoneNumber: userInfo.phoneNumber,
                isAdmin: userInfo.admin,
                adminsGetsAlerts: userInfo.adminsGetsAlerts,
                sendDailyReport: userInfo.sendDailyReport
            });
            this.createModifyForm(userInfo)
        },
        (error) => {
            console.log(error);
        }
        );
    }

    createModifyForm(selected) {
        var modifyForm = (
            <Form>
                <Form.Group as={Row} controlId="modifyUserEmail">
                    <Form.Label column sm={2}>Email:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="email" id="email" onChange={this.handleInputChange} placeholder={selected.email} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyUserPassword">
                    <Form.Label column sm={2}>Password:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="password" id="password" onChange={this.handleInputChange} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyUserFirstName">
                    <Form.Label column sm={2}>First Name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="fName" onChange={this.handleInputChange} defaultValue={selected.firstName} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyUserLastName">
                    <Form.Label column sm={2}>Last Name:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="lName" onChange={this.handleInputChange} defaultValue={selected.lastName} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyUserPhone">
                    <Form.Label column sm={2}>Phone Number:</Form.Label>
                    <Col sm={10}>
                        <Form.Control type="text" id="phone" onChange={this.handleInputChange} defaultValue={selected.phoneNumber} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyUserAdmin">
                    <Form.Label column sm={2} >Admin:</Form.Label>
                    <Col sm={10}>
                        <Form.Control as="select" id="isAdmin" onChange={this.handleInputChange} defaultValue={selected.admin} >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                {/* <Form.Group as={Row} controlId="modifyUserDailyReports">
                    <Form.Label column sm={2} >Recieves Daily Reports:</Form.Label>
                    <Col sm={10}>
                        <Form.Control as="select" id="dailyReports" onChange={this.handleInputChange} value={selected.sendDailyReport} >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="modifyUserAdminNotifications">
                    <Form.Label column sm={2} >Admins Get Account Notifications:</Form.Label>
                    <Col sm={10}>
                        <Form.Control as="select" id="adminNotifs" onChange={this.handleInputChange} value={selected.adminsGetAlerts} >
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </Form.Control>
                    </Col>
                </Form.Group> */}
                <Button variant="primary" onClick={this.handleSubmit} >Update User</Button>
            </Form>
        )
        this.setState({
            modifyForm: modifyForm
        })
    }

    handleInputChange = (event) => {
        switch (event.target.id) {
            case "email":
                console.log(event.target.value);
                this.setState({
                    email: event.target.value
                });
                break;
            case "password":
                this.setState({
                    password: event.target.value
                });
                break;
            case "fName":
                this.setState({
                    fName: event.target.value
                });
                break;
            case "lName":
                this.setState({
                    lName: event.target.value
                });
                break;
            case "phone":
                this.setState({
                    phoneNumber: event.target.value
                });
                break;
            case "isAdmin":
                this.setState({
                    isAdmin: event.target.value
                });
                break;
            case "dailyReports":
                this.setState({
                    sendDailyReport: event.target.value
                });
                break;
            case "adminNotifs":
                this.setState({
                    adminsGetsAlerts: event.target.value
                });
                break;
        }
    }
    
    handleSubmit = () => {
        //make sure all the input is correct
        //is formatted correctly

        let data = `[{
            "id": ${this.state.selectedUserID}, 
            "email": "${this.state.email}", 
            "firstName": "${this.state.fName}",
            "lastName": "${this.state.lName}",
            "phoneNumber": ${this.state.phoneNumber},
            "admin": ${this.state.isAdmin}
        }]`
        //Include password if it is being changed
        if (this.state.password.length > 0) {
            data = `[{
                "id": ${this.state.selectedUserID}, 
                "email": "${this.state.email}", 
                "password": "${this.state.password}", 
                "firstName": "${this.state.fName}",
                "lastName": "${this.state.lName}",
                "phoneNumber": ${this.state.phoneNumber},
                "admin": ${this.state.isAdmin}
            }]`
        }

        axios({
           method: 'put',
           url: `${config.api}users`,
           headers: {
              'Authorization': `Bearer ${config.token}`, 
              'Content-Type': 'text/plain'
           },
           data: data
        })
        .then((response) => {
           const data = response['data'];
           Swal.fire({
            icon: 'success',
            title: 'Success 👍🏼'
           });
        })
        .catch((error) => {
           console.log(error);
           Swal.fire({
               icon: 'error',
               title: 'Whoops 😰',
               text: error
           });
        });

    }

    render() {
        if (this.state.allUserOptions == null) {
            return (
                <LoadingView />
            )
        }
        else {
            Swal.close()
            return (
                <Container>
                    <h2>Modify A User</h2>
                    <br />
                    <Form>
                        {/* User Select */}
                        <Row>
                            <Form.Group controlId="userSelect">
                                <Form.Label>Select a User</Form.Label>
                                <Form.Control as="select" onChange={this.handleUserChange} selected="Choose....." >
                                    <option value="0">Select a User</option>
                                    {this.state.allUserOptions}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                    </Form>
                    <br />
                    {this.state.modifyForm}
                </Container>
            );
        }
    }
}

export default withRouter(ModifyUserView);