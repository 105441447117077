import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import './HelpView.scss';
import Sidebar from '../../components/Sidebar/Sidebar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PortalWalkthrough from './VideoWalkthrough';
import InstallVideo from './InstallVideo';
import FAQ from './FAQ';
import Topics from './Topics';

 

class HelpView extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
        };
    }


    componentDidMount(){
        if (this.state.windowWidth <= 767) {
            document.body.style.backgroundColor = "#fefefe";
        }
        else {
            document.body.style.backgroundColor = "#EBF2F8";
        }
        window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    componentDidUpdate() {
        if (this.state.windowWidth <= 767) {
            document.body.style.backgroundColor = "#fefefe";
        }
        else {
            document.body.style.backgroundColor = "#EBF2F8";
        }
    }

    render() {
         return (

            <div id="pageOuterContainer" className="pageOuterContainer">

                {this.state.windowWidth > 650 &&
                    <Sidebar 
                        pageWrapId={"pageWrap"} 
                        outerContainerId={"pageOuterContainer"} 
                        generateSummary={null}
                        buildings={null} 
                        buttonsVisible={false}
                    />
                }

                <div id="pageWrap" className="pageWrap">

                    {this.state.windowWidth <= 650 &&
                        <Sidebar 
                            pageWrapId={"pageWrap"} 
                            outerContainerId={"pageOuterContainer"} 
                            generateSummary={null}
                            buildings={null} 
                            buttonsVisible={false}
                        />
                    }

                    <Container className="helpView" >
                        <Row>
                            <Container>
                                <h2>Video Tutorials:</h2>
                            </Container>
                        </Row>
                        {this.state.windowWidth > 650 ?
                            <Row>
                                <Col className="leftCol" >
                                    <Container>
                                        <PortalWalkthrough />
                                    </Container>
                                </Col>
                                <Col className="rightCol" >
                                    <Container>
                                        <InstallVideo />
                                    </Container>
                                </Col>
                            </Row>
                        :
                            <div>
                                <Row>
                                    <Col className="leftCol" >
                                        <Container>
                                            <PortalWalkthrough />
                                        </Container>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col className="rightCol" >
                                        <Container>
                                            <InstallVideo />
                                        </Container>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <Row className="helpRow">
                            <Col md={6} xs={12} className="leftCol" >
                                <Container>
                                    <h3>Frequently Asked Questions</h3>
                                    <FAQ />
                                </Container>
                            </Col>
                            <Col md={6} xs={12} className="rightCol" >
                                <Row>
                                    <Container>
                                        <h3>Topics</h3>
                                        <Topics />
                                    </Container>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
         )   
    }
}

export default withRouter(HelpView);