import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import config from '../../utilities/config.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import { slide as Menu } from 'react-burger-menu';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import $ from 'jquery';
import './SetupView.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import SetupCard from '../../components/SetupCard/SetupCard';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';

import ReactTooltip from 'react-tooltip';
import WiFiForm from '../../components/WiFiForm.js/WiFiForm.js';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'


class SetupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            maxPage: 4,
            showWaterModal: false,
            loaded: false,
            //Account Details
            fName: null,
            lName: null,
            countryCode: 1,
            phone: null,
            passwordOne: null,
            passwordTwo: null,
            //Water & Sewer Rate
            waterRate: null,
            waterUnits: null,
            //Alert Preferences
            continuousLeakAlerts: true,
            highUsageAlerts: false,
            dailySummary: false,
            weeklySummary: true,
            monthlySummary: false,
            emailAlerts: true,
            textAlerts: false,
            showTextModal: false,
            textUnderstand: false
        };
        this.pages = [];
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#EBF2F8";
        this.getUser();
    }

    getUser() {
        axios({
            method: 'get',
            url: `${config.api}user/${config.userID}`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
            .then((response) => {
                const data = response.data;
                // const ccMod = 10000000000;
                // const phone = data.phoneNumber % ccMod;
                const phone = data.phoneNumber;
                // const cc = Math.floor(data.phoneNumber / ccMod);
                this.setState({
                    fName: data.firstName,
                    lName: data.lastName,
                    phone: phone,
                    countryCode: 1,
                })
                //console.log(this.state);
                this.createFormPages();
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    postPassword = () => {
        const url = `${config.api}generate-hash`;
        const password = this.state.passwordOne;
        axios.post(url, { token: config.token, password: password, userID: config.userID }).then(res => {

            Swal.fire({
                'icon': "success",
                'title': "Account Setup Complete. Please log in again with your new password."
            }).then(function () {
                localStorage.setItem("mlIsActive", 1);
                window.location.replace("https://portal.flowactive.com/logout");
            });


        });
    }


    postPreferences = () => {
        let flowEmails = 0;
        let flowTexts = 0;
        let usageTexts = 0;
        let usageEmails = 0;
        let dailyEmails = this.state.dailySummary ? 1 : 0;
        let weeklyEmails = this.state.weeklySummary ? 1 : 0;
        let monthlyEmails = this.state.monthlySummary ? 1 : 0;
        if (this.state.emailAlerts) {
            usageEmails = this.state.highUsageAlerts ? 1 : 0;
            flowEmails = this.state.continuousLeakAlerts ? 1 : 0;
        }

        if (this.state.textAlerts) {
            usageTexts = this.state.highUsageAlerts ? 1 : 0;
            flowTexts = this.state.continuousLeakAlerts ? 1 : 0;
        }
        //console.log(this.state)
        const requestBody = [{
            "userID": parseInt(config.userID),
            "flowEmails": flowEmails,
            "flowTexts": flowTexts,
            "usageEmails": usageEmails,
            "usageTexts": usageTexts,
            "dailyEmail": dailyEmails,
            "weeklyEmail": weeklyEmails,
            "monthlyEmail": monthlyEmails,
            "customAlerts": {}
        }]
        //console.log(requestBody);
        axios({
            method: 'post',
            url: `${config.api}users/settings/alerts/preferences`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            },
            data: requestBody
        })
            .then((response) => {
                //console.log(response)
                this.postPassword();
            })
            .catch((error) => {
                //console.log(error);
            });
    }

    putUser = () => {

        const requestBody = [{
            "id": parseInt(config.userID),
            "firstName": this.state.fName,
            "phoneNumber": this.state.phone,
            "activeAccount": 1,
            "lastName": this.state.lName
        }]
        //console.log(requestBody);
        axios({
            method: 'put',
            url: `${config.api}users`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            },
            data: requestBody
        })
            .then((response) => {
                //console.log(response);
                this.postPreferences();
            })
            .catch((error) => {
                //console.log(error);
            });

    }

    postWaterRate = () => {
        let costPerGallon = 0.00;
        let rate = parseFloat(this.state.waterRate);
        const waterUnits = this.state.waterUnits.split(/(\s+)/).filter(e => e.trim().length > 0);
        const quant = parseInt(waterUnits[0]);
        const unit = waterUnits[1];
        if (unit === "Gallons") {
            costPerGallon = rate / quant;
        } else if (unit === "Cubic") {
            costPerGallon = rate / (quant * 7.481);
        }
        const requestBody = [{
            "userID": parseInt(config.userID),
            "waterRate": costPerGallon
        }]
        //console.log(requestBody);
        axios({
            method: 'post',
            url: `${config.api}users/waterRate`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            },
            data: requestBody
        })
            .then((response) => {
                //console.log(response);
                this.putUser();
            })
            .catch((error) => {
                //console.log(error);
            });

    }


    handleTyping = (event, field) => {
        console.log(this.state);
        console.log(field, event.target.type, event.target.value);
        switch (event.target.type) {
            case 'text':
                this.setState({
                    [field]: event.target.value
                })
                break;
            case 'password':
                this.setState({
                    [field]: event.target.value
                });
                break;
            case 'select-one':
                this.setState({
                    [field]: event.target.value
                })
                break;
            case 'number':
                this.setState({
                    [field]: event.target.value
                })
                break;
            case 'checkbox':
                this.setState({
                    [field]: event.target.checked
                })
                break;
            default:
                break;
        }
    }

    matchPW = (event) => {
        if (this.state.passwordOne !== event.target.value) {
            event.target.style.color = 'red';
        } else {
            event.target.style.color = 'black';
        }
    }
    createFormPages = () => {
        //Account Details
        this.pages = []
        this.pages.push(
            <Container className='overflowBox'>
                <Row>
                    <h1 style={{ textAlign: 'center' }}>Account Details</h1>
                </Row>
                <p style={{ textAlign: 'center', marginTop: 24 }}>Name</p>
                <Row>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="First Name *"
                            id="First Name"
                            required
                            defaultValue={this.state.fName}
                            onChange={(event) => {
                                this.handleTyping(event, 'fName');
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="text"
                            placeholder="Last Name *"
                            id="Last Name"
                            required
                            defaultValue={this.state.lName}
                            onChange={(event) => {
                                this.handleTyping(event, 'lName');
                            }}
                        />
                    </Col>
                </Row>
                <p style={{ textAlign: 'center', marginTop: 24 }}>Phone</p>
                <Row>
                    <Col xs={8} style={{ margin: 'auto' }}>
                        <Form>
                            <PhoneInput
                                placeholder="Enter phone number"
                                defaultCountry="US"
                                value={this.state.phone}
                                onChange={(event) => {
                                    this.setState({ phone: event })
                                }} />
                        </Form>
                    </Col>
                </Row>
                <p style={{ textAlign: 'center', marginTop: 24 }}>Password Reset</p>
                <Row>
                    <Col>
                        <Form.Control
                            type="password"
                            placeholder="New Password *"
                            id="New Password"
                            required
                            defaultValue={this.state.passwordOne}
                            onChange={(event) => {
                                this.handleTyping(event, 'passwordOne');
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Control
                            type="password"
                            placeholder="Confirm Password *"
                            id="Confirm Password"
                            required
                            defaultValue={this.state.passwordTwo}
                            onChange={(event) => {
                                this.handleTyping(event, 'passwordTwo');
                                this.matchPW(event);
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        );

        //Water & Sewer Info
        this.pages.push(
            <Container className='overflowBox'>
                <Row>
                    <h1 style={{ textAlign: 'center' }}>Water Information</h1>
                </Row>
                <p style={{ textAlign: 'center', marginTop: 24, paddingBottom: 24 }}>Enter Combined Water and Sewer Rate</p>
                <Row>
                    <Col>
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Dollars *"
                                id="Water Sewer Rate"
                                required
                                defaultValue={this.state.waterRate}
                                onChange={(event) => {
                                    this.handleTyping(event, 'waterRate');
                                }}
                            />
                        </Col></Col>
                    <span style={{ paddingTop: '10px' }}>per</span>
                    <Col style={{ width: '30%' }}>
                        <Form.Control
                            id="unitInput1"
                            required
                            onChange={(event) => {
                                this.handleTyping(event, 'waterUnits');
                            }}
                            as="select"
                        >
                            <option>{(this.state.waterUnits == null) ? '-' : this.state.waterUnits}</option>
                            <option>100 Gallons</option>
                            <option>100 Cubic Feet</option>
                            <option>1000 Gallons</option>
                            <option>1000 Cubic Feet</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 24 }}>
                    <Button
                        variant='link'
                        onClick={this.waterPricePopUp.bind(this)}
                    >
                        I dont know my water & sewer rate
                    </Button>
                </Row>
            </Container>
        );

        //Alert Preferences
        this.pages.push(
            <Container className='overflowBox'>
                <Row>
                    <h1 style={{ textAlign: 'center' }}>Alert Preferences</h1>
                </Row>
                <p style={{ textAlign: 'center', paddingTop: '15px' }}>Alert Types</p>
                <i>I would like to receive...</i>
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label={<div><a>Continuous Leak Alerts</a> <a data-tip data-for='contFlowInfo'>&#x24D8;</a></div>}
                            id="Continuous Leak Alerts"
                            defaultChecked={this.state.continuousLeakAlerts}
                            onChange={(event) => {
                                this.handleTyping(event, 'continuousLeakAlerts');
                            }}
                        />
                        <ReactTooltip id='contFlowInfo' effect='solid'>
                            <span>Continuous Leak Alerts are triggered when a device records a constant flow of water for at least 20 minutes.</span>
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label={<div><a>High Usage Alerts</a> <a data-tip data-for='highUsageInfo'>&#x24D8;</a></div>}
                            id="High Usage Alerts"
                            defaultChecked={this.state.highUsageAlerts}
                            onChange={(event) => {
                                this.handleTyping(event, 'highUsageAlerts');
                            }}
                        />
                        <ReactTooltip id='highUsageInfo' effect='solid'>
                            <span>High Usage Alerts are triggered by a device recording a high level of water usage in less than 24 hours.</span>
                        </ReactTooltip>
                    </Col>
                </Row>
                <p style={{ textAlign: 'center', paddingTop: '45px' }}>Alert Summary Emails</p>
                <i>I would like to receive email summaries...</i>
                <Row>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Daily"
                            id="Daily"
                            defaultChecked={this.state.dailySummary}
                            onChange={(event) => {
                                this.handleTyping(event, 'dailySummary');
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Weekly"
                            id="Weekly"
                            defaultChecked={this.state.weeklySummary}
                            onChange={(event) => {
                                this.handleTyping(event, 'weeklySummary');
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            type="checkbox"
                            label="Monthly"
                            id="Monthly"
                            defaultChecked={this.state.monthlySummary}
                            onChange={(event) => {
                                this.handleTyping(event, 'monthlySummary');
                            }}
                        />
                    </Col>
                </Row>
                <p style={{ textAlign: 'center', paddingTop: '45px' }}>Recieve Alerts Via</p>
                <Row>
                    <Col>
                        <Form.Check
                            style={{ textAlign: 'center' }}
                            type="checkbox"
                            label="Email"
                            id="Email"
                            defaultChecked={this.state.emailAlerts}
                            onChange={(event) => {
                                this.handleTyping(event, 'emailAlerts');
                            }}
                        />
                    </Col>
                    <Col>
                        <Form.Check
                            style={{ textAlign: 'center' }}
                            type="checkbox"
                            label="Text Message"
                            id="Text Message"
                            defaultChecked={this.state.textAlerts}
                            onChange={(event) => {
                                if (!this.state.textAlerts) {
                                    this.showTextModal();
                                }
                                this.handleTyping(event, 'textAlerts');

                            }}
                        />
                    </Col>
                </Row>
            </Container>
        );


        //WiFi Credentials
        this.pages.push(
            <Container className='overflowBox'>
                <Row>
                    <h1 style={{ textAlign: 'center' }}>Wi-Fi Information</h1>
                </Row>
                <p style={{ textAlign: 'center', paddingTop: '15px' }}>If you chose to have your devices preconfigured, please enter you network information</p>
                <Row>
                    <WiFiForm />
                </Row>
            </Container>
        )

        this.setState({ loaded: true });
        //console.log(this.pages);
    }

    checkReqs = (pgNum) => {
        let items = []
        switch (pgNum) {
            case 1:
                items = [this.state.fName, this.state.lName, this.state.passwordOne, this.state.passwordTwo];
                if (this.state.passwordOne !== this.state.passwordTwo) {
                    return 'Your passwords do not match';
                }
                break;
            case 2:
                items = [this.state.waterRate, this.state.waterUnits];
                if (this.state.waterRate != null && isNaN(parseFloat(this.state.waterRate))) {
                    return 'Dollars must only be numbers';
                }
                break;
            case 3:
                items = [];
                break;

        }
        for (const item of items) {
            if (item === '' || item == null) {
                return 'Please fill out all fields';
            }
        };
        //console.log('returning true')
        return null;
    }

    waterPricePopUp() {
        this.setState({ showWaterModal: true })
        // const inputValue = 15.0
        // const inputStep = 5

        // Swal.fire({
        //     title: 'Estimate Water Price',
        //     html: `
        //     <input
        //         type="number"
        //         value="${inputValue}"
        //         step="${inputStep}"
        //         class="swal2-input"
        //         id="range-value">`,
        //     input: 'range',
        //     inputValue,
        //     inputAttributes: {
        //     min: 5.0,
        //     max: 30.0,
        //     step: inputStep
        //     },
        //     didOpen: () => {
        //     let inputRange = Swal.getInput()
        //     let inputNumber = Swal.getHtmlContainer().querySelector('#range-value')

        //     // remove default output
        //     inputRange.nextElementSibling.style.display = 'none'
        //     inputRange.style.width = '100%'

        //     // sync input[type=number] with input[type=range]
        //     inputRange.addEventListener('input', () => {
        //         inputNumber.value = inputRange.value
        //     })

        //     // sync input[type=range] with input[type=number]
        //     inputNumber.addEventListener('change', () => {
        //         inputRange.value = inputNumber.value
        //     })
        //     }
        // })

    }

    nextPage() {
        //console.log(this.state)
        const newPage = this.state.page + 1;
        const errorMessage = this.checkReqs(this.state.page)
        if (errorMessage != null) {
            Swal.fire({
                icon: 'error',
                title: `${errorMessage}`,
            })
        } else {
            if (newPage > this.state.maxPage) {
                // this.finish();
                //console.log("make api call");
                this.postWaterRate();
                //console.log(this.state);
            } else {
                this.setState({
                    page: newPage
                });
            }
        }
    }

    prevPage() {
        const newPage = this.state.page - 1;
        this.createFormPages();
        if (newPage >= 1) {
            this.setState({
                page: newPage
            });
        }
    }

    showWaterModal = () => {
        this.setState({ showWaterModal: true })
    }
    hideWaterModal = () => {
        this.setState({ showWaterModal: false })
        //console.log(this.state);
    }

    showTextModal = () => {
        this.setState({ showTextModal: true })
    }
    hideTextModal = () => {
        this.setState({ showTextModal: false })
        //console.log(this.state);
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <div>
                    <Sidebar />
                    <Container id="dpw" className="dpw" >
                        <Row xs={1} sm={1} className="db-top-row">
                            <SetupCard content={this.pages[this.state.page - 1]} page={this.state.page} maxPage={this.state.maxPage} onNext={this.nextPage.bind(this)} onPrev={this.prevPage.bind(this)} />
                        </Row>

                    </Container>

                    {/* Estimate Water Price Modal */}
                    <Modal show={this.state.showWaterModal} onHide={this.hideWaterModal} style={{ opacity: 1 }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Estimate Water & Sewer Rate</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form style={{ padding: 24 }}>
                                <Row inline>
                                    <Col xs={1} style={{ textAlign: 'center', paddingTop: 6 }}>
                                        $
                                    </Col>
                                    <Col xs={3}>
                                        <Form.Control
                                            id="gallonInput"
                                            onChange={(event) => {
                                                this.handleTyping(event, 'waterRate');
                                            }}
                                            as="select"
                                        >
                                            <option>-</option>
                                            <option>7</option>
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </Form.Control>
                                    </Col>
                                    <Col xs={2} style={{ textAlign: 'center', paddingTop: 6 }}>
                                        per
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Control
                                            id="unitInput"
                                            onChange={(event) => {
                                                this.handleTyping(event, 'waterUnits');
                                            }}
                                            as="select"
                                        >
                                            <option>{(this.state.waterUnits == null) ? '-' : this.state.waterUnits}</option>
                                            <option>100 Gallons</option>
                                            <option>100 Cubic Feet</option>
                                            <option>1000 Gallons</option>
                                            <option>1000 Cubic Feet</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.hideWaterModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={this.hideWaterModal}>
                                Save Rate
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* Confirm Text Alerts Modal */}
                    <Modal show={this.state.showTextModal} onHide={this.hideTextModal} style={{ opacity: 1 }} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Text Message Alerts</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form style={{ padding: 24 }}>
                                <Row inline>
                                    <Col xs={8} style={{ textAlign: 'center', paddingTop: 6 }}>
                                        Receive Text Messages at:
                                    </Col>
                                </Row>
                                <Row inline style={{ paddingTop: '20px' }}>
                                    <Col xs={8}>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="US"
                                            value={this.state.phone}
                                            onChange={(event) => {
                                                this.setState({ phone: event })
                                            }} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '10px' }}>
                                    <Col xs={8}>
                                        <span>MSG AND DATA RATES MAY APPLY</span>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Check
                                            style={{ textAlign: 'center' }}
                                            type="checkbox"
                                            label="I Understand"
                                            id="I Understand"
                                            defaultChecked={this.state.textUnderstand}
                                            onChange={(event) => {
                                                this.handleTyping(event, 'textUnderstand');
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.hideTextModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" disabled={!this.state.textUnderstand} onClick={this.hideTextModal}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>);
        } else {
            return (
                <LoadingView />
            );
        }
    }

};

export default withRouter(SetupView);