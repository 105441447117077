import React from 'react';
import { withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';

const AllUsersTable = (props) => {
    const users = props.users;
    console.log(users);
    users.sort((a, b) => a[0].id > b[0].id);
    const tableRows = users.map((userDetails) => {
        const user = userDetails[0];
        return (
            <tr>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.firstName}</td>
                <td>{user.createdAt}</td>
                <td>{user.updatedAt}</td>
            </tr>
        );
    });

    return (
        <Container>
            <h2>All Users</h2>
            <Table bordered hover style={{backgroundColor: '#fefefe'}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <br />
        </Container>
    );
};

export default AllUsersTable;