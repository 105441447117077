import React from 'react';
import './SidebarIcons.scss'

const DeviceIcon = (props) => {
	return (
		<svg className="svg" fill={props.fill} height="30" width="30" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 423.253 423.253"  xml space="preserve">
		<g>
			<g>
				<path d="M314.027,187.739c-15.06,0-27.307,12.25-27.307,27.307s12.247,27.307,27.307,27.307s27.307-12.25,27.307-27.307
					S329.086,187.739,314.027,187.739z M314.027,228.699c-7.526,0-13.653-6.124-13.653-13.653c0-7.53,6.127-13.653,13.653-13.653
					c7.526,0,13.653,6.123,13.653,13.653C327.68,222.575,321.553,228.699,314.027,228.699z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M314.027,153.605c-33.881,0-61.44,27.563-61.44,61.44s27.559,61.44,61.44,61.44c33.881,0,61.44-27.563,61.44-61.44
					S347.907,153.605,314.027,153.605z M314.027,262.832c-26.348,0-47.787-21.436-47.787-47.787s21.439-47.787,47.787-47.787
					s47.787,21.436,47.787,47.787S340.374,262.832,314.027,262.832z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M314.027,105.819c-60.228,0-109.227,48.998-109.227,109.227s48.998,109.227,109.227,109.227
					c60.228,0,109.227-48.998,109.227-109.227S374.255,105.819,314.027,105.819z M314.027,310.619
					c-52.698,0-95.573-42.875-95.573-95.573s42.875-95.573,95.573-95.573c52.698,0,95.573,42.875,95.573,95.573
					S366.725,310.619,314.027,310.619z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M293.547,215.045h-110.95l-32.693-111.152c-0.833-2.836-3.393-4.813-6.345-4.898c-2.877-0.198-5.625,1.734-6.618,4.509
					L80.585,260.03l-26.832-48.302c-1.202-2.164-3.485-3.509-5.967-3.509H6.827c-3.772,0-6.827,3.058-6.827,6.827
					s3.055,6.827,6.827,6.827h36.939l32.188,57.931c1.205,2.174,3.499,3.509,5.966,3.509c0.181,0,0.365-0.007,0.553-0.027
					c2.666-0.212,4.96-1.969,5.867-4.489l54.368-151.009l28.238,96.01c0.853,2.905,3.519,4.902,6.547,4.902h116.053
					c3.772,0,6.827-3.058,6.827-6.827S297.318,215.045,293.547,215.045z"/>
			</g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		</svg>
	);
};

export default DeviceIcon;


