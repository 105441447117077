import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import config from '../../utilities/config';
import './LoginResetView.scss'

class LoginResetView extends Component{
    


    constructor(props) {
        super(props);
        this.state = {
          verified: false
        };
      }
    

    componentDidMount() {
        document.body.style.background = "linear-gradient(60deg, rgba(37, 84, 132, 1) 0%, rgba(0, 172, 193, 1) 100%)"
    }  
    
    //   onVerify = recaptchaResponse => {
    //     this.setState({
    //       verified: true
    //     });
    //   };


    handleSubmit = (event) => {
        event.preventDefault();
        if(!this.state.verified){
            Swal.fire({
                'title' : "Unconfirmed Recapatcha"
            })
            return;
        }

			  
			  


        //get the token information
        const token = this.getParameterByName("token");
        const url = `${config.api}generate-hash`;


       let p0 = $("#password").val();
       let p1 = $("#password1").val();
       console.log("PASSWORDS");
       console.log(p0);
       console.log(p1);
       if (p0 != p1){
           Swal.fire({
               'title' : "Passwords do not match"
           });
           return;
       }

        const password = p0;
        const setPassword = 1;
        Swal.fire({
            'title' : 'Resetting Password',
            'timer' : 60000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading();
            }
        
        
        });
        axios.post(url , {token : token , password: password , setPassword: setPassword}).then(res => {
            
            Swal.fire({
                'title': "Password reset. You will now be redirected to the login page"
            }).then(function(){ 
                window.location.href = "https://portal.flowactive.com/login";
             });


        });
        
    }

     getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    

    render(){
        localStorage.removeItem("mlToken");
        localStorage.removeItem("mlUserID");
        localStorage.removeItem("mlIsAdmin");
        localStorage.removeItem("mlIsMasquerading");
        localStorage.removeItem("mlOriginalUser");





        let curStyle = {width: '130px' , height:'40px'};
        if (!this.state.verified){
            curStyle = {width: '130px' , height:'40px' , "opacity" : ".5"}
        }
        return (


            <div className="outerContainer" >
            <Container className="formContainer">
                <Form className="loginForm" onSubmit={this.handleSubmit} id="loginForm">
                    <Image className="loginImage" src="https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg" fluid />

                    <Form.Group controlId="email">
                        <Form.Label>Password</Form.Label>
                        <Form.Control className="loginInput" type="password" name="password" id="password" placeholder="password123" onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control className="loginInput" type="password" name="password1" id="password1" placeholder="password123" onChange={this.handleChange} />
                    </Form.Group>

                    <Reaptcha className="captcha" sitekey="6LeO5PYUAAAAADW3Wm7lIV02_8UlHEkna_fySJ7P" onVerify={this.onVerify} />

                    <Button className="loginButton" variant="primary" disabled={!this.state.verified} type="submit">Submit</Button>
                    <br />
                </Form>
            </Container>

            <div>
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g class="parallax">
                        <use xlink href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlink href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlink href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlink href="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
            <div className="waveFill"></div>

            </div>



        );

    }

}


export default withRouter(LoginResetView);