import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class AddUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: null,
            password: null,
            fName: null,
            lName: null,
            isAdmin: false
        }
    }

    componentDidMount() {
    }

    handleInputChange = (event) => {
        switch (event.target.id) {
            case "email":
                this.setState({
                    email: event.target.value
                });
                break;
            case "password":
                this.setState({
                    password: event.target.value
                });
                break;
            case "fName":
                this.setState({
                    fName: event.target.value
                });
                break;
            case "lName":
                this.setState({
                    lName: event.target.value
                });
                break;
        }
    }
    
    handleAdminChange = event => {
        console.log(event);
        this.setState({
            isAdmin: event.target.checked
        });
    };

    handleSubmit = (event) => {
        console.log(this.state);
        var email = "";
        var password = "";
        var fName = "";
        var lName = "";
        var isAdmin = 0;

        //Email
        console.warn("***** Need to validate that its an actual email")
        if (this.state.email != null && this.state.email != ""){
            email = String(this.state.email);
        }
        else{
            Swal.fire({  
              title: 'Invalid Email',
            });
            return;
        }
        //Password
        console.warn("***** Need to validate that pasword meets requirements")
        if (this.state.password != null && this.state.password != ""){
            password = String(this.state.password);
        }
        else{
            Swal.fire({  
              title: 'Invalid Pasword',
            });
            return;
        }
        //First Name
        if (this.state.fName != null && this.state.fName != ""){
            fName = String(this.state.fName);
        }
        else{
            Swal.fire({  
              title: 'Invalid Name',
            });
            return;
        }
        //Last Name
        if (this.state.lName != null && this.state.lName != ""){
            lName = String(this.state.lName);
        }
        else{
            Swal.fire({  
              title: 'Invalid Name',
            });
            return;
        }
        //Admin Access
        if (this.state.isAdmin == true) {
            isAdmin = 1;
        }

        this.createUser(email, password, fName, lName, isAdmin);
    }

    createUser(email, password, fName, lName, isAdmin) {

        Swal.fire({
            'title' : 'Creating User...',
            'timer' : 60000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var data = `[{"email": "${email}", "password": "${password}", "firstName": "${fName}", "lastName": "${lName}", "admin": ${isAdmin}}]`;

        var postConfig = {
            method: 'post',
            url: `${config.api}users`,
            headers: { 
                'Authorization': `Bearer ${config.token}`, 
                'Content-Type': 'text/plain'
            },
            data : data
        };
          
        axios(postConfig)
            .then((response) => {
              Swal.close()
              const data = response["data"][0];
              let error = data["errorCode"];
              if (error == null) {
                Swal.fire({  
                    title: 'User Created',
                    icon: 'success'
                    }).then(function(){ 
                        window.location.reload();
                        }
                    );
              }
              else {
                  Swal.fire({
                    title: "Error",
                    text: error,
                    icon: 'error'
                  });
              }
              
            },
              (error) => {
                  console.log(error);
              }
            );
    }

    render() {
        return (
            <Container>
                <h1>Create a New User</h1>
                <br />
                <Form>
                    {/* Email */}
                    <Form.Group controlId="adduserEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" id="email" onChange={this.handleInputChange} placeholder="Email" />
                    </Form.Group>
                    {/* Password */}
                    <Form.Group controlId="addUserPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="text" id="password" onChange={this.handleInputChange} placeholder="Password" />
                    </Form.Group>
                    {/* First Name */}
                    <Form.Group controlId="addUserFname">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" id="fName" onChange={this.handleInputChange} placeholder="John" />
                    </Form.Group>
                    {/* Last Name */}
                    <Form.Group controlId="addUserLname">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" id="lName" onChange={this.handleInputChange} placeholder="Doe" />
                    </Form.Group>
                    {/* Admin Access */}
                    <Form.Group>
                        <Form.Check 
                            id="adminCheck"
                            type="checkbox"
                            label="Administrator Access" 
                            onChange={this.handleAdminChange} 
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={this.handleSubmit}>Create User</Button>
                </Form>
            </Container>
        );
    }

}

export default withRouter(AddUser);