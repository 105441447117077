import config from './config';
import axios from 'axios';

// Helper Funcitons
async function resolve(promise) {
    console.log(promise);
    const resolved = {
      data: null,
      error: null
    };
  
    try {
      resolved.data = await promise;
    } catch(e) {
      resolved.error = e;
    }
  
    return resolved;
}

async function getDeviceSerialNum(deviceID) {
    const deviceInfo = await getDeviceInfo(deviceID);
    if (deviceInfo.error) {console.log(deviceInfo.error);}
    else {console.log('👍🏼'); return deviceInfo.data.deviceID;}
}

// -- API --

// Organization
export async function getOrgProperties(orgID) {
    if (orgID === undefined) {orgID = config.orgID};
    return await resolve(
        axios({
            method: 'get',
            url: `${config.api}organization/${config.orgID}/properties`,
            headers: { 
                'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            return response.data;
        })
    );
}

// Properties
export async function getPropertyBuildings(propertyID) {
    return await resolve(
        axios({
            method: 'get',
            url: `${config.api}property/${propertyID}/buildings`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            return response.data;
        })
    );
}

// Buildings
export async function getBuildingLocations(buildingID) {
    return await resolve(
        axios({
           method: 'get',
           url: `${config.api}buildings/${buildingID}/locations`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           return response.data;
        })
    );
}

// Locations
export async function getlocationMetrics(locationID, startDate, endDate) {
    return await resolve(
        axios({
            method: 'get',
            url: `${config.api}metrics/locations/${locationID}?startDate=${startDate}&endDate=${endDate}`,
            headers: {
               'Authorization': `Bearer ${config.token}`
            }
         })
         .then((response) => {
            return response['data'][0];
         })
    );
}

// Devices
export async function getDeviceInfo(deviceID) {
    let url = `${config.api}devices/${deviceID}`;
    if (deviceID.toString().length < 13) {
        url = `${config.api}devices/${deviceID}?useSQLID=1`;
    }
    return await resolve(
        axios({
            method: 'get',
            url: url,
            headers: {
               'Authorization': `Bearer ${config.token}`
            }
         })
         .then((response) => {
            return response.data[0];
         })
    );
}

export async function getDeviceRawMetrics(deviceID) {
    return await resolve(
        axios({
            method: 'get',
            url: `${config.api}metrics/raw/devices?deviceIDs=${deviceID}`,
            headers: {
               'Authorization': `Bearer ${config.token}`
            }
         })
         .then((response) => {
            return response['data'][0];
         })
    );
}

export async function getDeviceMacAddress(deviceID) {
    console.log('=====================');
    console.log('🙏🏼');
    if (deviceID.toString().length < 13) {
        console.log('💀');
        const deviceSerialNum = await getDeviceSerialNum(deviceID);
        console.log(deviceSerialNum);
        return await resolve(
            axios({
               method: 'post',
               url: `${config.api}devices/macAddress`,
               headers: {
                  'Authorization': `Bearer ${config.api}`, 
                  'Content-Type': 'text/plain'
               },
               data: `[{"deviceID": "${deviceSerialNum}"}]`
            })
            .then((response) => {
                console.log("================================RESPONSE================================")
                console.log(response)
               return response['data'][0];
            })
            .catch((e) => {
                console.log(e);
                console.log(`[{"deviceID": "${deviceSerialNum}"}]`);
            })
        );
    }
    else {
        console.log(deviceID);
        return await resolve(
            axios({
               method: 'post',
               url: `${config.api}devices/macAddress`,
               headers: {
                  'Authorization': `Bearer ${config.token}`
               },
               data: `[{"deviceID": "${deviceID}"}]`
            })
            .then((response) => {
                console.log(`\"deviceID\": \"${deviceSerialNum}\"`);
               return response['data'][0];
            })
        );
    }
}