import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';
import Reaptcha from 'reaptcha';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import config from '../../utilities/config';

class LoginView extends Component{

    constructor(props) {
        super(props);
        this.state = {
          verified: false
        };
    }

    componentDidMount() {
        console.log(window.location.href);
        document.body.style.background = "linear-gradient(60deg, rgba(37, 84, 132, 1) 0%, rgba(0, 172, 193, 1) 100%)"

    }    

    handleSubmit = (event) => {
        event.preventDefault();
        // if(!this.state.verified){
        //     Swal.fire({
        //        'title': 'You must complete reCAPTCHA'
        //     });
        //     return;
        // }

        Swal.fire({
            'title' : 'Logging In',
            'timer' : 30000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        const email = $("#email").val();
        const password = $("#password").val();
        var postConfig = {
            method: 'post',
            url: `${config.api}login`,
            headers: { 
              'Content-Type': 'text/plain',
              'Access-Control-Allow-Origin': "*",
              'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
              'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
            },
            data : `[{"email": "${email}", "password": "${password}"}]`
            // data : {"email": email, "password": password}
            // Uncomment the above line and comment out line 55 if using the old API.
          };
        console.log("POST CONFIG");
        console.log(postConfig);

        axios(postConfig)
        .then((res) => {
            const token = res.data.token;
            if(token == "null" || token == null){
                console.log(token);
                Swal.fire({'title' : 'Email or Password is incorrect'});
            }
            else{
                const orgRoles = res.data.hasOrganizationRoles;
                const orgID = orgRoles[0].organizationID;
                localStorage.setItem("mlToken" , res.data.token);
                localStorage.setItem("mlUserID" , res.data.userID);
                localStorage.setItem("mlOrgID" , orgID);
                localStorage.setItem("mlIsAdmin" , res.data.adminAccess);
                localStorage.setItem("mlFName" , res.data.firstName);
                localStorage.setItem("mlLName" , res.data.lastName);
                localStorage.setItem("mlPhone" , res.data.phone);
                localStorage.setItem("mlIsActive" , res.data.activeAccount);
                localStorage.setItem("mlIsMasquerading" , false);
                let curURL = window.location.href;
                var lastPart = curURL.substr(curURL.lastIndexOf('/') + 1);
                if(lastPart != "login" && lastPart != "logout"){
                   window.location.reload();
                }
                else{
                    this.getUserInfo(res.data.userID);
                }
            }
        })
        .catch((error) => {
            console.log("ERROR");
            console.log(error);
            Swal.fire({"title" : "Email or Password is incorrect"});
        });   
    }

    getUserInfo(userID) {
        axios({
            method: 'get',
            url: `${config.api}user/${userID}`,
            headers: {
                'Authorization': `Bearer ${config.token}`
            }
        })
        .then((response) => {
            const data = response.data;
            if (data.activeAccount == 0) {
                window.location.href ="/setup";
            }
            else {
                window.location.href ="/";
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    resetPasswordLink = (event) =>{
        const url = `${config.api}send-password-reset-email`;
        
        let error = null;

        Swal.fire({
            title: 'Enter your email address',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit',
            showLoaderOnConfirm: true,
            'timer' : 45000,
            'allowOutsideClick': false,
            preConfirm: (email) => {
                let requestContent = {

                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify([{ email: email }])
        
                };

                if(email == "" || !email.includes("@")){
                    error = "Bad Email Address";
                    return;
                }

                return fetch(url , requestContent)
                    .then(response => {
                    if (!response.ok) {
                    throw new Error(response.statusText)
                    }
                    return response.json()
                })
                .catch(error => {
                    Swal.showValidationMessage(
                    `Request failed: ${error}`
                    )
                })
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
              console.log("RESULT");
              console.log(result);
            if (result.dismiss == "cancel") {
                return;
            }
            else if (result && error == null) {

                console.log(result["value"][0]);
                let message = result["value"][0]["developerMessage"];
               // let message = result[0]["developerMessage"];
                Swal.fire({
                    'title': message
                })
            }
            else if(error == "Bad Email Address"){
                Swal.fire({
                    'title' : "Incorrect Email Address"
                })
            }
            else{
                
            }
          })
    }

    // onVerify = recaptchaResponse => {
    //     this.setState({
    //       verified: true
    //     });
    //   };

    render(){
        localStorage.removeItem("mlToken");
        localStorage.removeItem("mlUserID");
        localStorage.removeItem("mlIsAdmin");
        localStorage.removeItem("mlIsMasquerading");
        localStorage.removeItem("mlOriginalUser");
        localStorage.removeItem("mlBuildingID");
        localStorage.removeItem("mlDateRange");


        let curStyle = {width: '130px' , height:'40px'};
        if (!this.state.verified){
            curStyle = {width: '130px' , height:'40px' , "opacity" : ".5"}
        }

        const isIE = /*@cc_on!@*/false || !!document.documentMode;


        if(!isIE){

            return (
                <div className="outerContainer" >
                    <Container className="formContainer">
                        <Form className="loginForm" onSubmit={this.handleSubmit} id="loginForm">
                            <Image className="loginImage" src="https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg" fluid />

                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control className="loginInput" type="email" name="email" placeholder="john@example.com" onChange={this.handleChange} />
                            </Form.Group>
                            < br />
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control className="loginInput" type="password" name="password" placeholder="password123" onChange={this.handleChange} />
                            </Form.Group>

{/*                             <Reaptcha className="captcha" sitekey="6LeO5PYUAAAAADW3Wm7lIV02_8UlHEkna_fySJ7P" onVerify={this.onVerify} />
 */}
                            <br />
                            <br />
                            <Button className="loginButton" variant="primary" disabled={this.state.verified} type="submit">Login</Button>
                            <Button className="forgotPassButton" variant="link" onClick={this.resetPasswordLink}>Forgot Password</Button>
                            <br />
                        </Form>
                    </Container>

                    <div>
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlns xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use xlink href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                                <use xlink href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use xlink href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use xlink href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                    <div className="waveFill"></div>

                </div>
            );

        }
        else{
            return (
                
                    <div style={{"margin-top" : "100px" , "color":"white"}} className="outerContainer" >
                        <center>
                        Warning: This browser does not support the FlowActive Portal. 
                        <br /><br />
                        Please use one of the following browsers:
                        <br /><br />
                            <table>
                            <tr style={{"height" : "100px"}}>
                                <td style={{"width": "150px"}}>
                                    Microsoft Edge 
                                </td>
                                <td>
                                    <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/browser-icons/edge.png" width="70" height="70"/>
                                </td>
                            </tr>
                            <tr  style={{"height" : "100px"}}>
                                <td style={{"width": "150px"}}>
                                    Mozilla Firefox 
                                </td>
                                <td>
                                    <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/browser-icons/firefox.png" width="60" height="60"/>
                                </td>
                             </tr>
                             <tr  style={{"height" : "100px"}}>
                                <td style={{"width": "150px"}}>
                                    Google Chrome  
                                </td>
                                <td>
                                    <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/browser-icons/chrome.jpg" width="65" height="65"/>
                                </td>
                            </tr>
                            <tr  style={{"height" : "100px"}}>
                                <td style={{"width": "150px"}}>
                                    Apple Safari  
                                </td>
                                <td>
                                    <img src="https://mobius-labs-public-imgs.s3.amazonaws.com/browser-icons/safari.png" width="75" height="75"/>
                                </td>
                            </tr>

                                
                            </table>
                        </center>

                    </div>
            
            
            );     
        }

    }

}


export default withRouter(LoginView);









