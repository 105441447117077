

const numberWithCommas =  function(x) {

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    let rVal = parts.join(".");
    let parts2 = x.toString().split(".");
    let extra = "";
    console.log("ADDING TRAILING ZERO");
    console.log(parts2)
  
    if (parts2.length > 1){
        let curLen = parts2[1].length;
        if (curLen < 2){
            extra = "0";
        }
    }
    if (x.toString().indexOf(".") == -1){
        extra = ".00";
    }
    
    rVal += extra;
    return rVal;
    
    //this has lookback support, however only chrome has lookback support as of 03/23/2020
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}


export {numberWithCommas};


