import React from 'react';
import './DataGraph.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Line } from 'react-chartjs-2';

const DataGraph = (props) => {
    // if (props.signalData == undefined && props.batteryData == undefined  && props.sensorData == undefined ) {
    //     return(<></>);
    // }

    let labels = null;
    switch(props.detailedLabels) {
        case true:
            labels = props.keys;
            break;
        default:
            labels = props.keys.map((key) => {
                let parts = key.split(",");
                return parts[0];
            });
            break;
    }

    var datasets = [];

    if (props.signalData != null) {
        datasets.push({
            label: 'WiFi Signal Strength',
            data: props.signalData,
            type: 'line',
            backgroundColor: [
                'rgba(53, 121, 189, 0.5)'
            ],
            borderColor: [
                'rgba(53, 121, 189, 1))'
            ],
            borderWidth: 1,
            pointBackgroundColor: 'rgba(53, 121, 189, 1)',
            tension: 0.1
        });
    }
    if (props.batteryData != null) {
        datasets.push({
            label: 'Battery Voltage',
            data: props.batteryData,
            type: 'line',
            backgroundColor: [
                'rgba(76, 192, 173, 0.5)'
            ],
            borderColor: [
                'rgba(76, 192, 173, 1)'
            ],
            borderWidth: 1,
            pointBackgroundColor: 'rgba(76, 192, 173, 1)',
            tension: 0.1,
        });
    }
    if (props.sensorData != null) {
        datasets.push({
            label: 'Sensor Voltage',
            data: props.sensorData,
            type: 'line',
            backgroundColor: [
                'rgba(255, 243, 0, 0.5)'
            ],
            borderColor: [
                'rgba(255, 243, 0, 1)'
            ],
            borderWidth: 1,
            pointBackgroundColor: 'rgba(255, 243, 0, 1)',
            tension: 0.1
        });
    }

    const data = {
        labels: labels,
        datasets: datasets
    };

    console.log(data);

    const options =  {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: false,
                    userCallback: function(value, index, values) {
                        // value = value.toString();
                        // value = value.split(/(?=(?:...)*$)/);
                        // value = value.join(',');
                        return value;
                    }
                }
            }]
        },
        maintainAspectRatio: false
    };

    return (
        <Container className="graphBackground" >
            <Row>
                <Col xs={12} style={{'height': '10%'}}>
                    <p className="graphLabel">{props.title}</p>
                </Col>
                <Col xs={12} className="dataGraph">
                    <Line data={data} options={options} />
                </Col>
            </Row>
        </Container>
    );
    
};

export default DataGraph;