import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Sidebar from '../../components/Sidebar/Sidebar';
import TabView from '../TabView/TabView';
import Graph from '../Graph/Graph';
import './Summary.scss';
import '../../css/bootstrap/dist/css/bootstrap.min.css';
import '../../css/bootstrap/dist/css/starter-template.css';
import '../../css/scss/all-users.scss';
import '../../css/scss/custom.scss';
import '../../css/scss/device-find.scss';
import '../../css/scss/master-tool.scss';
import '../../css/scss/traffic-light.scss';
import SummaryQS from '../../components/Summary/SummaryQS/SummaryQS';
import SummaryTable from '../../components/Summary/SummaryTable/SummaryTable';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import LeakTable from '../../components/Table/LeakTable/LeakTable';
import $ from 'jquery';
//need this import although not directly used
import moment from 'moment';
import DatePicker from "react-datepicker";
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config.js';


class Summary extends Component{

    daysBack = 7;
    
    
    constructor(props) {
        super(props);
        this.state = {
            data : null,
            selectedBuilding: null,
            widgetData: null
        };

    }

    generateReportAlert(){
        Swal.fire({
            'title' : 'Generating Report',
            'timer' : 60000,
            onBeforeOpen: () => {
                Swal.showLoading();

            },
            
        
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.fire({
                   icon: 'error',
                  'title': 'Request took too long to complete'
              })
            }
          })
    }

    handleStartChange = date => {
        $(".btn-group").show();

        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        const nDate = mm + '/' + dd + '/' + yyyy;
        setTimeout(function(){ 
            $("#dp-startDate").val(nDate)
        }, 250);

    };


    
    handleEndChange = date => {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    const nDate = mm + '/' + dd + '/' + yyyy;
        setTimeout(function(){ 
            $("#dp-endDate").val(nDate)
        }, 250);
    };

    requestReport = e => {
        const token = localStorage.getItem("mlToken");

        const buildings = this.state.buildings;
        const buildingID = parseInt($("#buildingChoice").val());
        //swal loading might be beneficial here
        this.generateReportAlert();
        
        
        let startDateString = $("#dp-startDate").val();
        let endDateString = $("#dp-endDate").val();
        let nStartDateString = null;
        let nEndDateString = null;
        if (startDateString != null){
            nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");
        }
        else{
            Swal.fire({
                'title' : 'Invalid Start Date'
            });
            return;
        }

        if (endDateString != null){
            nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");
        }
        else{
            Swal.fire({
                'title' : 'Invalid End Date'
            });
            return;
        }


        if(buildingID == 0){
            localStorage.setItem("mlBuildingID" , null);
            const userID = localStorage.getItem("mlUserID");

            this.getUserBuildingInformation(userID , token , buildings , nStartDateString , nEndDateString);
        }
        else{
            localStorage.setItem("mlBuildingID" , buildingID);

            this.getBuildingInformation(buildingID , token , buildings , nStartDateString , nEndDateString);
        }
    }

    //this doesn't do date conversions properly, but will do the date conversion so that it shows properly
    //on the calendar option for people to choose from
    getCalendarDate(stringDate){

        let year = parseInt(stringDate.substring(0 , 4));
        let month = parseInt(stringDate.substring(5,7));
        let day = parseInt(stringDate.substring(8,10));
        let a = new Date();
        a.setMonth(month - 1);
        a.setDate(day);
        a.setFullYear(year);
        a.setHours(0);
        a.setMinutes(0);
        a.setSeconds(0);
        return a;
    }



    changeTimezone(date, ianatz) {

        // suppose the date is 12:00 UTC
        var invdate = new Date(date.toLocaleString('en-US', {
          timeZone: ianatz
        }));
      
        // then invdate will be 07:00 in Toronto
        // and the diff is 5 hours
        var diff = date.getTime() - invdate.getTime();
      
        // so 12:00 in Toronto is 17:00 UTC

        return new Date(date.getTime() + diff);
      
      }

    //assuming the keys are the same for each , otherwise, you should use the {...obj1 , ...obj2} operator first
    mergeTwoObjectValues(obj1 , obj2 , keys){
        let obj3 = {};
        for(let i = 0 ; i < keys.length ; i++){
            let curKey = keys[i];
            let val = obj1[curKey] + obj2[curKey];
            obj3[curKey] = val;
        }

        return obj3;


    }


    
    handleBuildingSelect = () => {
        const token = localStorage.getItem("mlToken");
        const buildings = this.state.buildings;
        const buildingID = parseInt($("#buildingChoice").val());
        
        //swal loading might be beneficial here
        this.generateReportAlert();
        
        
        let startDateString = $("#dp-startDate").val();
        let endDateString = $("#dp-endDate").val();
        let nStartDateString = null;
        let nEndDateString = null;
        if (startDateString != null){
            nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");
        }
        else{
            Swal.fire({
                'title' : 'Invalid Start Date'
            });
            return;
        }

        if (endDateString != null){
            nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");
        }
        else{
            Swal.fire({
                'title' : 'Invalid End Date'
            });
            return;
        }


        if(buildingID == 0){
            localStorage.setItem("mlBuildingID" , null);
            const userID = localStorage.getItem("mlUserID");

            this.getUserBuildingInformation(userID , token , buildings , nStartDateString , nEndDateString);
        }
        else{
            localStorage.setItem("mlBuildingID" , buildingID);

            this.getBuildingInformation(buildingID , token , buildings , nStartDateString , nEndDateString);
        }
    }   



    getBuildingInformation(buildingID , token , buildings , startDate , endDate){


        // var now = new Date();
        // var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        // var timestamp = now.getTime();


        // let sevenDaysAgo = timestamp - (86400 * 1000 * this.daysBack);

        // now = this.changeTimezone(now , 'America/New_York');
        // let endDate =now.toISOString().split('T')[0];
        // let tmpDate = now;
        // tmpDate.setDate(now.getDate() - this.daysBack);
        // let startDate = tmpDate.toISOString().split('T')[0];

        let url = `${config.api}metrics/portalStats/building/${buildingID}?startDate=${startDate}&endDate=${endDate}`

        let sCall = Date.now();
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )

          .then((response2) => {
            let eCall = Date.now();



            let lData = response2["data"];
            let keys = Object.keys(lData);
            let fKey = keys[0];
            let iData = lData[fKey]["statsByDate"];
            let rData = {};

            let iDataKeys = Object.keys(iData);
            for(let i =0 ; i < iDataKeys.length; i++){
                let curKey = iDataKeys[i]
                let curData = iData[curKey];

                let date = curKey;
                let gallons = curData["gallonsUsed"];
                let flushes = curData["flushes"];
                let gallonsWasted = curData["gallonsWasted"];
                let leakCost = curData["leakCost"];
                rData[date] = curData;

            }
            let leakData = {}
            leakData[fKey] = lData[fKey]["statsForProblems"];

            const cSDate = this.getCalendarDate(startDate);
            const cEDate = this.getCalendarDate(endDate);
            this.setState({data : rData , leakData: leakData , buildings:buildings , selectedBuilding: buildingID , startDate: cSDate, endDate: cEDate});


            },
            (error) => {
                console.log(error);
            }
          );
    }

    getUserBuildingInformation(userID , token , buildings , startDate , endDate){

        var now = new Date();
        var timestamp = now.getTime();
    
        
        let url = `${config.api}metrics/portalStats/user/${userID}?&startDate=${startDate}&endDate=${endDate}`;


        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )

          .then((response) => {


            //TODO AGGREGATE DATA BETWEEN THE DIFFERENT BUILDINGS AND THE DATES
            let lData = response["data"];
            let keys = Object.keys(lData);
            let rData = {};

            for(let j =0 ; j < keys.length ; j++){
                let fKey = keys[j];
                let iData = lData[fKey]["statsByDate"];

                let iDataKeys = Object.keys(iData);
                for(let i =0 ; i < iDataKeys.length; i++){
                    let curKey = iDataKeys[i]
                    let curData = iData[curKey];

                    let date = curKey;
 

                    //there are better ways of merging the objects, but this is the way 
                    if(rData[date] == undefined){
                        rData[date] = curData;
                    }
                    else{
                        rData[date] = this.mergeTwoObjectValues(rData[date] , curData , Object.keys(curData));
                    }


                }
            }

            let leakData = {}
            for (let i =0 ; i < keys.length ; i++){
                const curKey = keys[i];
                leakData[curKey] = lData[curKey]["statsForProblems"];
            }
            
            const cSDate = this.getCalendarDate(startDate);
            const cEDate = this.getCalendarDate(endDate);

            this.setState({data : rData , leakData: leakData , buildings:buildings , selectedBuilding: null , startDate: cSDate, endDate: cEDate});

            },
            (error) => {
                console.log(error);
            }
          );
    }
    componentDidUpdate(){
        $('table').tablesorter({

            headers: {
                1: { sorter: "digit", empty : "top" }, // sort empty cells to the top
                }


        }); 
        $("table.tablesorter").trigger("update");
        



    }

    componentDidMount(){

        //$('table').tablesorter();
        let token = localStorage.getItem("mlToken");
        let userID = localStorage.getItem("mlUserID");
       
        let url = `${config.api}advanced-building-functions/user/${userID}`;
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )

          .then((response) => {
            let buildings = response.data;
            //console.log("BUILDING");
            //console.log(buildings);
            if (buildings.length == 1){
                let building = buildings[0];
                let buildingID = building["id"];
                localStorage.setItem("mlBuildingID" , buildingID);

                let startDateString = $("#dp-startDate").val();
                let endDateString = $("#dp-endDate").val();
                let startDate = null;
                let endDate = null;
                let nStartDateString = null;
                let nEndDateString = null;
                if (startDate != null){
                    nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nStartDateString = moment().subtract(6 , 'days').format("YYYY-MM-DD");
                }

                if (endDate != null){
                    nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nEndDateString = moment().format("YYYY-MM-DD");
                }

                this.getBuildingInformation(buildingID , token , buildings , nStartDateString , nEndDateString);


               
            }
            else if(buildings.length == 0){
                //this shouldn't be the case but possible and we should handle it
                Swal.fire(
                    {"title" : "You have no buildings setup for this account. Click OK to return to the profile page"}
                ).then((result) => {
                    /* Read more about handling dismissals below */
                    window.location.href ="/profile";
                    
                });



            }
            else{
                //get dates from date select
                let startDateString = $("#dp-startDate").val();
                let endDateString = $("#dp-endDate").val();
                let startDate = null;
                let endDate = null;
                let nStartDateString = null;
                let nEndDateString = null;
                if (startDate != null){
                    nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nStartDateString = moment().subtract(6 , 'days').format("YYYY-MM-DD");
                }

                if (endDate != null){
                    nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nEndDateString = moment().format("YYYY-MM-DD");
                }
                

                this.getUserBuildingInformation(userID ,token , buildings , nStartDateString , nEndDateString);
            }
          });




 
    }


    render(){

        //get information that is approved for the user

        //const data = this.state.data;
        let sevenDayGallons = 0;
        let sevenDayFlushes = 0;
        let sevenDayGallonsLost = 0;
        let sevenDayGallonsCost = 0;

        let data = this.state.data;
        let leakData = this.state.leakData;
        let gData = null;
        let gallonData = [];
        let keys = null;

        if(data != null){

            keys = Object.keys(data).sort();
            gallonData = [];
            for(let i =0 ; i < keys.length ; i++){
                let key = keys[i];
                let gallons = parseInt(data[key]["gallonsUsed"]);

                let flushes = data[key]["flushes"];
                let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
                let leakCost = Number(parseFloat(data[key]["leakCost"]).toFixed(2));
                gallonData.push(gallons);
                sevenDayGallons += gallons;
                sevenDayFlushes += flushes;
                sevenDayGallonsLost += gallonsWasted;
                sevenDayGallonsCost += leakCost;
            }
            let pointBackgroundColors = []

            for(let i = 0 ; i < gallonData.length; i++){
                pointBackgroundColors.push('rgba(53, 120, 189, 1)');
            }
 
            gData = 
            {
                
                labels: keys,
                datasets: [{
                    label: 'Total Number of Gallons Used',
                    data: gallonData,
                    type: 'line',
                    backgroundColor: [
                        'rgba(76, 192, 172, 1)'
                    ],
                    borderColor: [
                        'rgba(76, 192, 172, 1)'
                    ],
                    borderWidth: 1,
                    pointBackgroundColor: pointBackgroundColors
                }]
            };
        }


        const options =  {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        };

        if(data == null){
            return <LoadingView />;
        }
        else{
            Swal.close();
            let buildings = this.state.buildings;
            let selectedBuilding = this.state.selectedBuilding;

            let firstBuildingOption = null;
            let otherBuildingOptions = null;
            if(buildings.length > 1){
                firstBuildingOption = (<option value="0">All Buildings</option>);
                otherBuildingOptions = buildings.map((building,index) => {
                    let buildingLabel = building["label"];
                    let buildingID = building["id"];
                    let selected = false;
                    if(buildingID == selectedBuilding){
                        selected = true;
                    }
                    return (
                        <option value={buildingID} selected={selected}>{buildingLabel}</option>
                    );
                });
            }
            else if(buildings.length == 1){
                otherBuildingOptions = buildings.map((building,index) => {
                    let buildingLabel = building["label"];
                    let buildingID = building["id"];
                    
                    return (
                        <option value={buildingID} selected={true}>{buildingLabel}</option>
                    );
                })
            }

            let tabView = null;
            if(selectedBuilding != null){
               tabView = ( <TabView tab="summary" />);
            }

            console.log("DATA FOR GRAPH");
            console.log(data);
            console.log("BUILDING ID");
            console.log(selectedBuilding);

            let logoURL = "https://mobius-labs-public-imgs.s3.amazonaws.com/icons/flowactive.svg";
            let iconBGC = "white";

            if(selectedBuilding == 5){
                logoURL = "https://mobius-labs-public-imgs.s3.amazonaws.com/icons/hilton-logo.png";
            }

            else if(selectedBuilding == 14){
                logoURL = "https://mobius-labs-public-imgs.s3.amazonaws.com/icons/Marriott_Logo.png";
            }
            else if(selectedBuilding == 15){
                logoURL = "https://mobius-labs-public-imgs.s3.amazonaws.com/icons/doubletree_black_HR.jpg";
            }
            else if(selectedBuilding == 17){
                logoURL = "https://mobius-labs-public-imgs.s3.amazonaws.com/icons/hampton-inn-logo.png";
            }
            else if(selectedBuilding == 19){
                logoURL = "https://mobius-labs-public-imgs.s3.amazonaws.com/icons/courtyard-marriott.png";
                iconBGC = "#53575a";

            }
            


            return (
                <div style={{margin: 24}}>
                {/* <div id="summaryOuterContainer" className="summaryOuterContainer" > */}
                    <Menu isAdmin={false} masquarding={false} iconUrl={"#"} isMasquardeAdmin={false} />
                    {/* <Sidebar pageWrapId={"summaryPageWrap"} outerContainerId={"summaryOuterContainer"} /> */}

                    {/* <div id="summaryPageWrap" className="summaryPageWrap" > */}
                        <select id="buildingChoice" style={{marginBottom: "30px", marginTop: "30px"}} onChange={this.handleBuildingSelect}>
                        {firstBuildingOption}
                        {otherBuildingOptions}
                        
                        </select>
                        <br />
                        <br />
                        <h4>
                            Dates
                        </h4>
                        <div>
                        <DatePicker
                            id="dp-startDate"
                            selected={this.state.startDate}
                            onChange={this.handleStartChange}
                        />
                        &nbsp;to&nbsp; 
                        <DatePicker
                            id="dp-endDate"
                            selected={this.state.endDate}
                            onChange={this.handleEndChange}
                        />
                        &nbsp;&nbsp;
                        <Button style={{"height": "40px"}} variant="contained" color="primary" onClick={this.requestReport}>
                            Retrive Data
                        </Button>
                        <br />
                        <br />
                        </div>
                        {tabView}
                        <center><img className="nav-link" style={{"height" : "80px" , "width": "auto", "backgroundColor": iconBGC}} src={logoURL} /></center>
                        <br />
                        <Graph
                            data={gData} options={options}
                            />
                        <SummaryQS sevenDayGallons={sevenDayGallons} sevenDayFlushes={sevenDayFlushes} sevenDayGallonsCost={sevenDayGallonsCost} sevenDayGallonsLost={sevenDayGallonsLost} />
                        <SummaryTable orderedKeys={keys} data={data} />
                        <LeakTable leakData={leakData} />
                    {/* </div> */}
                {/* </div> */}
                </div>
            );
        }
    }
}

export default withRouter(Summary);