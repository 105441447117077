import React, { Component } from 'react';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';

var fixtureData = {};
var loadCounter = 0;
var buildingInfo = {};
var toiletInfo = {};
var locationSet = {};

class ToiletFixtureView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingIDCount: null,
            loaded: false,
            loadCount: 0
        }
    }

    componentDidMount() {
        this.getallToilets()
    }

    getallToilets() {
        //Get all Toilets
        let url = `${config.api}toilets`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            const data = response["data"];
            data.forEach((item) => {
                toiletInfo[item.id] = item.label;
            });
            console.log('=====Toilet Info=====');
            console.log(toiletInfo);
            this.getAllBuildings();
          },
          (error) => {
            console.log(error);
          }
        );
    }

    getAllBuildings() {
        //Get all building IDs
        let url = `${config.api}get-all-buildings`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            const data = response["data"];
            const buildingIDs = data.map((item) => {
                buildingInfo[item.id] = item.label;
                return (item.id);
            });
            this.setState({
                // buildingIDCount: buildingIDs.length
                buildingIDCount: 1
            });
            console.log('=====Building Info=====');
            console.log(buildingInfo);
            this.getLocations(buildingIDs);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    getLocations(buildingIDs) {
        let token = localStorage.getItem("mlToken");
buildingIDs = [108];
        buildingIDs.forEach((buildingID) => {
            //Get all locations in a building
            let url = `${config.api}buildings/${buildingID}/locations`;
            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
            .then((response) => {
                const data = response["data"];
                var locations = data.map((item) => {
                    return (item);
                });
                this.countFixtures(buildingID, locations);
            },
            (error) => {
                console.log(error);
            }
            );
        });
    }

    countFixtures(buildingID, locations) {
        var fixtureSet = {};
        //Loop through every location in the given building. Get current toilet, 
        //  either increment the value or start it at 1.
        locations.forEach((location) => {
            const curFixture = location["toiletID"];

            // if (locationSet[buildingID] == null) {
            //     locationSet[buildingID] = [{location: location["locationName"], toilet: location["deviceID"]}];
            // }
            // else {
            //     locationSet[buildingID].push({location: location["locationName"], toilet: location["deviceID"]});
            // }

            axios({
               method: 'get',
               url: `${config.api}devices/${location["deviceID"]}?useSQLID=1`,
               headers: {
                  'Authorization': `Bearer ${config.token}`
               }
            })
            .then((response) => {
               const locationData = response['data'][0];
               this.getRssi(locationData, buildingID);
                // if (locationSet[buildingID] == null) {
                //     locationSet[buildingID] = [{location: location["locationName"], toilet: data["deviceID"], timeLast: data["lastCurDeviceStatusUpdate"]}];
                // }
                // else {
                //     locationSet[buildingID].push({location: location["locationName"], toilet: data["deviceID"], timeLast: data["lastCurDeviceStatusUpdate"]});
                // }
                // this.createLocSetRows();
            })
            .catch((error) => {
               console.log(error);
            });

            if (curFixture != null) {
                if (fixtureSet[curFixture] == null) {
                    fixtureSet[curFixture] = 1;
                }
                else {
                    fixtureSet[curFixture] += 1;
                }
            }
        });
        fixtureData[buildingID] = fixtureSet;
        loadCounter += 1;
        this.setState({
            loadCount: loadCounter
        });
        // console.log(`Counter: ${loadCounter}`)
            // console.log('=====Fixture Info=====');
            // console.log(fixtureData);
        // this.createLocSetRows();
    }

    getRssi = (locationData, buildingID) => {
        axios({
           method: 'get',
           url: `${config.api}metrics/raw/devices?deviceIDs=${locationData.id}`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           const data = response['data'][0];
           if (locationSet[buildingID] == null) {
               locationSet[buildingID] = [{location: locationData["locationLabel"], toilet: locationData["deviceID"], timeLast: data.deliveredTime, rssi: data.rssi}];
           }
           else {
               locationSet[buildingID].push({location: locationData["locationLabel"], toilet: locationData["deviceID"], timeLast: data.deliveredTime, rssi: data.rssi});
           }
           this.createLocSetRows();
        })
        .catch((error) => {
           console.log(error);
        });
    }

    createLocSetRows = () => {
        const curLocArray = locationSet[108];
        const locSetRows = curLocArray.map((locSet) => {
            const timeCheck = Date.now();
            const timeStamp = new Date(locSet.timeLast).toLocaleString();
            console.log(timeCheck);
            let timeStatus = '✅';
            if (locSet.timeLast < (timeCheck - 86400000)) {
                timeStatus = '❌';
            }
            return (
                <tr>
                    <td>{locSet.location}</td>
                    {/* <td>{toiletInfo[locSet.toilet]}</td> */}
                    <td>{locSet.toilet}</td>
                    <td>{timeStatus}</td>
                    <td>{timeStamp}</td>
                    <td>{locSet.rssi}</td>
                </tr>
            );
        });
        this.setState({
            locSetRows: locSetRows
        })
    }

    render() {
        console.log(locationSet);
        if (this.state.loadCount == this.state.buildingIDCount) {
            Swal.close();
            return (
                <Container>
                    {/* <h1>Toilet Fixtures in Hotels</h1>
                    <br />
                    <FixtureTables fixtureData={fixtureData} buildingInfo={buildingInfo} toiletInfo={toiletInfo} /> */}
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Room</th>
                                <th>Toilet</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.locSetRows}
                        </tbody>
                    </Table>
                </Container>
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(ToiletFixtureView);
