import React, { Component } from 'react';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2'
import axios from 'axios';
import config from '../../utilities/config';
import DatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withRouter, BrowserRouter as Route, Switch } from 'react-router-dom';
import FlowTestTable from '../../components/Table/FlowTestTable/FlowTestTable';


class FlowTestReport extends Component {
  constructor(props) {
      super(props);
      this.state = {
          info: [],
          showTable:false,
          loaded: true,
          ssid: "",
          tmpSSID: ""
      };
  }
  
  componentDidMount() {
      
  }

   onFormSubmit = (e) => {
    e.preventDefault();
    this.getDeviceInfo();
    // send state to server with e.g. `window.fetch`
  }

  handleInputChange = (event) => {
    switch (event.target.id) {
        case 'ssid':
            this.setState({
              tmpSSID: event.target.value
            })
            break;
        default:
            break;
    }
}
  
  getDeviceInfo() {
      Swal.showLoading()

      //Get all Users
      console.log(this.state.tmpSSID);
      let url = `${config.api}get-test-file?searchField=deviceID&isRangeSearch=0&value=${this.state.tmpSSID}`;
      let token = localStorage.getItem("mlToken");

      axios.get(
          url,
          {
              headers: { Authorization: `Bearer ${token}` , "Access-Control-Allow-Origin" : "*"}
          }
        )
        .then((response) => {
          console.log(response);
          const data = response["data"]["results"];
          const info = data.map((item) => {
              return item;
          });
          Swal.close();
          this.setState({
            info: info,
            showTable: true,
            ssid: this.state.tmpSSID
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }


  render() {
      if (this.state.loaded) {
          Swal.close();

        let theTable = null;
        if (this.state.showTable == true) {
            theTable = (<FlowTestTable info={this.state.info} deviceID={this.state.ssid}/>);
        }

          return (
                  <Container>
                     <br /> <br /> <br />

                    <Form onSubmit={this.onFormSubmit.bind(this)}>
                        <Form.Group>
                            <Form.Label>SSID</Form.Label>
                            <Form.Control type="text" id="ssid" onChange={this.handleInputChange} />
                        </Form.Group>
                        <br></br>
                    </Form>

                    <Button variant="primary" id="provisionSubmitButton" onClick={this.getDeviceInfo.bind(this)} >Submit</Button>
                    <br /><br /><br />
                      <h2>Device Flow Test {this.state.ssid}</h2>
                      <br />
                      {theTable}
                  </Container>
              
          );
      }
      else {
          return (
              <div>
                  <LoadingView />
              </div>
          );
      }
  }
}


export default withRouter(FlowTestReport);