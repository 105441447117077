import React from 'react';
import './WaterUseBlocks.scss';
import '../DashboardBlocks/GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Flushes = (props) => {

    let flushes = Number(props.flushes).toLocaleString();

    return (
        <Container className="waterUseBackground">
            <Row style={{"height": "25%"}} >
                <p className="blocksLabel">Flushes</p>
            </Row>
            <Row style={{"height": "75%"}} >
                <p className="totalWaterUse">{flushes}</p>
            </Row>
        </Container>
    );
};

export default Flushes;