import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';
// import { BrowserRouter as Router, Route, } from "react-router-dom";
import DeviceBlocks from '../../components/Block/DeviceBlocks/DeviceBlocks';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import $ from 'jquery';
import moment from 'moment';
import './DeviceView.scss';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class DeviceView extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            data : null,
            selectedBuilding: null,
            buildingTitle: null,
            leakData: null,
            buildings: null,
            startDate: null,
            endDate: null,
            widgetData: null,
            totalRepairs: 0,
            leakTotal: 0,
            dateRange: null,
            loaded: false,
        };

    }
 
    componentDidMount(){
        document.body.style.backgroundColor = "#EBF2F8";
        this.generateDeviceSummary(config.dateRange);
        window.addEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({windowWidth: window.innerWidth}));
    }

    generateDeviceSummary(numDays) {
        this.setState({
            dateRange: numDays,
            loaded: false
        });
        localStorage.setItem("mlDateRange" , numDays);
       
        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response) => {
            let buildings = response.data;

            if (buildings.length == 1){//User owns one building
                let building = buildings[0];
                let buildingID = building["id"];
                localStorage.setItem("mlBuildingID" , buildingID);
                config.selectedBuilding = buildingID;

                let startDateString = this.state.startDate;
                let endDateString = this.state.endDate;
                let startDate = null;
                let endDate = null;
                let nStartDateString = null;
                let nEndDateString = null;
                if (startDate != null){
                    nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nStartDateString = moment().subtract(numDays , 'days').format("YYYY-MM-DD");
                }

                if (endDate != null){
                    nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nEndDateString = moment().format("YYYY-MM-DD");
                }

                
                this.getBuildingInformation(buildings , nStartDateString , nEndDateString);
                
                

            }
            else if(buildings.length == 0){
                //this shouldn't be the case but possible and we should handle it
                Swal.fire(
                    {"title" : "You have no buildings setup for this account. Click OK to return to the profile page"}
                ).then((result) => {
                    /* Read more about handling dismissals below */
                    window.location.href ="/profile";
                    
                });



            }
            else{//User owns mulitple buildings

                let startDateString = $("#dp-startDate").val();
                let endDateString = $("#dp-endDate").val();
                let startDate = null;
                let endDate = null;
                let nStartDateString = null;
                let nEndDateString = null;
                if (startDate != null){
                    nStartDateString = moment(startDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nStartDateString = moment().subtract(numDays , 'days').format("YYYY-MM-DD");
                }

                if (endDate != null){
                    nEndDateString = moment(endDateString, "MM/DD/YYYY").format("YYYY-MM-DD");

                }
                else{
                    nEndDateString = moment().format("YYYY-MM-DD");
                }
                
                this.setState({
                    startDateString: nStartDateString,
                    endDateString: nEndDateString
                });
                if (config.selectedBuilding == 0) {
                    this.getUserBuildingInformation(buildings , nStartDateString , nEndDateString);
                }
                else {
                    this.getBuildingInformation(buildings , nStartDateString , nEndDateString);
                }
            }
        });
    }

    getBuildingInformation(buildings , startDate , endDate){

        let url = `${config.api}metrics/portalStats/building/${config.selectedBuilding}?startDate=${startDate}&endDate=${endDate}`;

        let sCall = Date.now();
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response2) => {
            let eCall = Date.now();



            let lData = response2["data"];
            let keys = Object.keys(lData);
            let fKey = keys[0];
            let iData = lData[fKey]["statsByDate"];
            let rData = {};

            let iDataKeys = Object.keys(iData);
            for(let i =0 ; i < iDataKeys.length; i++){
                let curKey = iDataKeys[i]
                let curData = iData[curKey];

                let date = curKey;
                let gallons = curData["gallonsUsed"];
                let flushes = curData["flushes"];
                let gallonsWasted = curData["gallonsWasted"];
                let leakCost = curData["leakCost"];
                rData[date] = curData;

            }
            let leakData = {}
            let leakTotal = 0
            leakData[fKey] = lData[fKey]["statsForProblems"];
            leakTotal = Object.keys(leakData[fKey]).length;

            this.setState({
                leakData: leakData,
                leakTotal: leakTotal,
                buildings: buildings,
                selectedBuilding: config.selectedBuilding,
                startDate: startDate,
                endDate: endDate
            });
            this.getDeviceStatus(buildings, startDate, endDate)


            },
            (error) => {
                console.log(error);
            }
          );
    }

    getUserBuildingInformation(buildings , startDate , endDate){

        var now = new Date();
        var timestamp = now.getTime();
    
        
        let url = `${config.api}metrics/portalStats/user/${config.userID}?&startDate=${startDate}&endDate=${endDate}`;


        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )

          .then((response) => {


            //TODO AGGREGATE DATA BETWEEN THE DIFFERENT BUILDINGS AND THE DATES
            let lData = response["data"];
            let keys = Object.keys(lData);
            let rData = {};

            for(let j =0 ; j < keys.length ; j++){
                let fKey = keys[j];
                let iData = lData[fKey]["statsByDate"];

                let iDataKeys = Object.keys(iData);
                for(let i =0 ; i < iDataKeys.length; i++){
                    let curKey = iDataKeys[i]
                    let curData = iData[curKey];

                    let date = curKey;
 

                    //there are better ways of merging the objects, but this is the way 
                    if(rData[date] == undefined){
                        rData[date] = curData;
                    }
                    else{
                        rData[date] = this.mergeTwoObjectValues(rData[date] , curData , Object.keys(curData));
                    }


                }
            }

            let leakData = {}
            let leakTotal = 0
            for (let i =0 ; i < keys.length ; i++){
                const curKey = keys[i];
                leakData[curKey] = lData[curKey]["statsForProblems"];
                leakTotal += Object.keys(leakData[curKey]).length
            }
            
            // const cSDate = this.getCalendarDate(startDate);
            // const cEDate = this.getCalendarDate(endDate);

            this.setState({
                leakData: leakData,
                leakTotal: leakTotal,
                buildings: buildings,
                selectedBuilding: config.selectedBuilding,
                startDate: startDate,
                endDate: endDate
            });
            this.getDeviceStatus(buildings, startDate, endDate)
            
            },
            (error) => {
                console.log(error);
            }
          );
    }


    getDeviceStatus(buildings, startDateString, endDateString) {
        let startDate = Date.parse(startDateString);
        let endDate = Date.parse(endDateString);
        let totalRoomData = [];

        if (config.selectedBuilding == 0) {
            let buildingCount = 0;
            let deviceData = {};
            buildings.forEach((building) => {
                let buildingID = building.id;
                let url = `${config.api}portalStats/devices/building/${buildingID}?startDate=${config.startDate}&endDate=${config.endDate}`;
                axios.get(
                    url,
                    {
                        headers: { Authorization: `Bearer ${config.token}` }
                    }
                )
                .then((response) => {
                        buildingCount++;
                        let lData = response["data"];
                        let keys = Object.keys(lData);
                        let fKey = keys[0];
                        let rData = lData[fKey];
                        const deviceKeys = Object.keys(rData);
                        deviceKeys.forEach((dKey) => {
                            const newDevice = {
                                batteryV: rData[dKey].batteryV,
                                deliveredTime: rData[dKey].deliveredTime,
                                deviceID: rData[dKey].deviceID,
                                locationLabel: rData[dKey].locationLabel,
                                rssi: rData[dKey].rssi,
                                buildingID: buildingID
                            }
                            deviceData[dKey] = newDevice
                        });
                        if (buildingCount === buildings.length) {
                            this.setState({
                                data : deviceData,
                                deviceView: "all",
                                loaded: true
                            }); 
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            });
        }
        // else if (config.selectedBuilding == 29) {
        //     const deviceData = {
        //         "10191010-0012": {
        //             "batteryV": 3200,
        //             "deliveredTime": Date.now(),
        //             "deviceID": "10191010-0012",
        //             "locationLabel": "257",
        //             "rssi": -56
        //         },
        //         "10191010-0013": {
        //             "batteryV": 3200,
        //             "deliveredTime": Date.now(),
        //             "deviceID": "10191010-0013",
        //             "locationLabel": "321",
        //             "rssi": -68
        //         },
        //         "10191010-0015": {
        //             "batteryV": null,
        //             "deliveredTime": null,
        //             "deviceID": "10191010-0015",
        //             "locationLabel": "325",
        //             "rssi": null
        //         },
        //         "10191010-0016": {
        //             "batteryV": 4017,
        //             "deliveredTime": Date.now(),
        //             "deviceID": "10191010-0016",
        //             "locationLabel": "316",
        //             "rssi": -95
        //         },
        //         "10191010-0017": {
        //             "batteryV": 4048,
        //             "deliveredTime": Date.now(),
        //             "deviceID": "10191010-0017",
        //             "locationLabel": "322",
        //             "rssi": -95
        //         }
        //     }
        //     this.setState({
        //         data : deviceData,
        //         deviceView: "all",
        //         loaded: true
        //     }); 
        // }
        else {
            let buildingID = config.selectedBuilding;
                let url = `${config.api}portalStats/devices/building/${buildingID}?startDate=${config.startDate}&endDate=${config.endDate}`;
                axios.get(
                    url,
                    {
                        headers: { Authorization: `Bearer ${config.token}` }
                    }
                )
    
                .then((response) => {
                        let lData = response["data"];
                        let keys = Object.keys(lData);
                        let fKey = keys[0];
                        let rData = lData[fKey];
                        console.log(rData);
                        this.setState({
                            data : rData,
                            deviceView: "all",
                            loaded: true
                        }); 
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
        
    }


    exportData = (api, token, title) => {
        console.log(this.state);

        if (this.state.loaded) {

            Swal.fire({
                'title' : 'Exporting File ...',
                'timer' : 60000,
                'allowOutsideClick': false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            const buildings = this.state.buildings;
            let oDevices = this.state.data;

            let workingDevices = 0;

            let lowBatteryDevices = [];
            let weakSignalDevices = [];
            let noResponseDevices = [];

            const exportDeviceData = [];


            let keys = Object.keys(oDevices);
            console.log(oDevices);
            console.log(keys)
            console.log(buildings)

            for(let i =0 ; i < keys.length ; i++){
                let tmp = oDevices[keys[i]];
                let deviceID = tmp["deviceID"];
                let batteryV = tmp["batteryV"];
                let locationLabel = tmp["locationLabel"];
                let rssi = tmp["rssi"];
                let deliveredTime = tmp["deliveredTime"];
                let issueO = {};

                let signalP = 0;

                if(rssi != null){
                    batteryP = signalP = 2 * (rssi + 100);
                }

                if(signalP < 5){
                    issueO["signal"] = 2;
                }
                else if(signalP < 30){
                    issueO["signal"] = 1;
                }
                else{
                    issueO["signal"] = 0;
                }
                signalP = Math.min(signalP , 100) + "%";

                let batteryP = 0;
                if(batteryV != null){
                    batteryP = parseFloat((batteryV- 3200) / 900.0).toFixed(2) * 100;
                }


                if(batteryP < 10){
                    issueO["battery"] = 2;
                }
                else if (batteryP < 30){
                    issueO["battery"] = 1 
                }
                else{
                    issueO["battery"] = 0;
                }



                if(locationLabel == null){
                    locationLabel = "Unassigned";
                }
                else{
                    locationLabel = "Room " + locationLabel;
                }

                let fDate = "No Status";
                if (deliveredTime > 0){
                    let diff = Date.now() - deliveredTime;

                    if(diff > 172800000){
                        issueO["time"] = 1;
                    }
                    else{
                        issueO["time"] = 0;
                    }

                    let d = new Date(deliveredTime);
                    fDate = d.toLocaleString();
                }
                else{
                    issueO["time"] = 2;
                }

                //var fDate = new Date(deliveredTime);




                if(batteryP < 0){
                    batteryP = 0;
                }
                else if(batteryP > 100){
                    batteryP = 100;
                }

                batteryP = Math.trunc(batteryP) + "%";


                let buildingLabel = "";
                if (title === "All Buildings") {
                    buildings.forEach((building) => {
                        if (building.id == tmp["buildingID"]) {
                            buildingLabel = building.label;
                        }
                    })
                }
                else {
                    buildings.forEach((building) => {
                        if (building.label == title) {
                            buildingLabel = building.label;
                        }
                    })
                }

                let finalO = {
                    "deviceID" : deviceID,
                    "lastResponse" : fDate,
                    "battery": batteryP,
                    "signalStrength": signalP,
                    "locationLabel" : locationLabel,
                    "building": buildingLabel,
                    "issueO" : issueO
                }
                let problemFound = false;

                if (issueO["battery"] > 0) {
                    problemFound = true;
                    lowBatteryDevices.push(finalO);
                    exportDeviceData.push({
                        "building": buildingLabel,
                        "room": locationLabel,
                        "name":deviceID,
                        "problem":"Low Battery"
                    });
                }
                if (issueO["signal"] > 0 && issueO["battery"] == 0) {
                    problemFound = true;
                    weakSignalDevices.push(finalO);
                    exportDeviceData.push({
                        "building": buildingLabel,
                        "room": locationLabel,
                        "name":deviceID,
                        "problem":"Weak Signal"
                    });
                }
                if (fDate == "No Status" && issueO["battery"] == 0) {
                    problemFound = true;
                    noResponseDevices.push(finalO);
                    exportDeviceData.push({
                        "building": buildingLabel,
                        "room": locationLabel,
                        "name":deviceID,
                        "problem":"No Response"
                    });
                }
                else if (issueO["time"] > 0 && issueO["battery"] == 0) {
                    problemFound = true;
                    noResponseDevices.push(finalO);
                    exportDeviceData.push({
                        "building": buildingLabel,
                        "room": locationLabel,
                        "name":deviceID,
                        "problem":"No Response"
                    });
                }

                if (!problemFound) {
                    workingDevices += 1;
                }
            }



            const requestBody = {
                "reportType" : "devices",
                "data": {
                    'building_title': title,
                    'building_subtitle': "",
                    'start_date': this.state.startDate,
                    'end_date': this.state.endDate,
                    'num_total_devices': Object.keys(oDevices).length,
                    'num_working_devices': workingDevices,
                    'num_low_battery': lowBatteryDevices.length,
                    'num_weak_wifi': weakSignalDevices.length,
                    'num_no_response': noResponseDevices.length,
                    'devices': exportDeviceData  
                }
            }

            let url = api+`pdf-page-export`;
            let auth = `Bearer `+token;
            var config = {
                method: 'post',
                url: url,
                headers: { 
                  'Authorization': auth, 
                  'Content-Type': 'application/json', 
                },
                data : requestBody
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                let data = response["data"];
                let uploadFile = data["uploadFile"];
                let url = uploadFile["uploadFile"];
                Swal.close();
                Swal.fire({
                    title: 'Report Created',
                    text: 'Clicking the download button will open a new tab or window to download your file.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3578bd',
                    confirmButtonText: 'Download'
                  }).then(() => {
                    window.open(url);
                  })
              });

        }
    };

    //assuming the keys are the same for each , otherwise, you should use the {...obj1 , ...obj2} operator first
    mergeTwoObjectValues(obj1 , obj2 , keys) {
        let obj3 = {};
        for(let i = 0 ; i < keys.length ; i++){
            let curKey = keys[i];
            let val = obj1[curKey] + obj2[curKey];
            obj3[curKey] = val;
        }

        return obj3;


    }

    //this doesn't do date conversions properly, but will do the date conversion so that it shows properly
    //on the calendar option for people to choose from
    getCalendarDate(stringDate){

        let year = parseInt(stringDate.substring(0 , 4));
        let month = parseInt(stringDate.substring(5,7));
        let day = parseInt(stringDate.substring(8,10));
        let a = new Date();
        a.setMonth(month - 1);
        a.setDate(day);
        a.setFullYear(year);
        a.setHours(0);
        a.setMinutes(0);
        a.setSeconds(0);
        return a;
    }


    render(){
        if (this.state.loaded) {
            Swal.close();
            let data = this.state.data;
            let deviceView = this.state.deviceView;
            console.log(data);
            return (

                <div id="pageOuterContainer" className="pageOuterContainer" >
    
                    {this.state.windowWidth > 650 &&
                        <Sidebar 
                            pageWrapId={"pageWrap"} 
                            outerContainerId={"pageOuterContainer"} 
                            generateSummary={this.generateDeviceSummary.bind(this)}
                            buildings={this.state.buildings} 
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }

                    <div id="pageWrap" className="pageWrap" >

                        {this.state.windowWidth <= 650 &&
                            <Sidebar 
                                pageWrapId={"pageWrap"} 
                                outerContainerId={"pageOuterContainer"} 
                                generateSummary={this.generateDeviceSummary.bind(this)}
                                buildings={this.state.buildings} 
                                buttonsVisible={true}
                                handleExport={this.exportData}
                            />
                        }
                        {/* Low Battery */}
                        <Row className="deviceView-top-row">
                            <DeviceBlocks tableKey="Battery Life" buildings={this.state.buildings} devices={data} deviceView={deviceView}/>
                        </Row>
                        {/* Weak Signal */}
                        <Row className="deviceView-middle-row">
                            <DeviceBlocks tableKey="Wi-Fi Connection" buildings={this.state.buildings} devices={data} deviceView={deviceView}/>
                        </Row>
                        {/* No Response */}
                        <Row className="deviceView-bottom-row">
                            <DeviceBlocks tableKey="Late Response" buildings={this.state.buildings} devices={data} deviceView={deviceView}/>
                        </Row>
                    </div>
                    
                </div>
            );
        }
        else{
            return <LoadingView />;
        }


    }

}


export default withRouter(DeviceView);





