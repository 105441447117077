import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

function Device(id, result, value, time) {
    this.id = id;
    this.result = result;
    this.value = value;
    this.time = time
}

var deviceID = "";
var scannedDevices = [];

class HallSensorTesting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceRows: null,
            resetOnSuccess: false,
            passingValue: 180,
            tolerance: 10,
            loaded: false
        }
    }

    getHallVal = (scannedDevice) => {
        var getConfig = {
        method: 'get',
        url: `${config.api}devices/${scannedDevice.id}`,
        headers: { 
            'Authorization': `Bearer ${config.token}`
        }
        };

        axios(getConfig)
        .then((response) => {
            if (response.data.length == 0){
                Swal.fire({
                    icon: "error",
                    title: "Can't find that device"
                })
                return;
            }

            const sqlID = response.data[0].id;
            var rawGetConfig = {
            method: 'get',
            url: `${config.api}metrics/raw/devices?deviceIDs=${sqlID}&limit=2`,
            headers: { 
                'Authorization': `Bearer ${config.token}`, 
            }
            };

            axios(rawGetConfig)
            .then((response) => {
                const data = response.data;
                let resObj = {};
                
                let olderTime = null;
                data.forEach((dataObj) => {
                    console.log(dataObj);
                    let curTime = dataObj.deliveredTime;
                    if (curTime < olderTime || olderTime == null) {
                        olderTime = curTime;
                        resObj = dataObj;
                    }
                });

                let hallValue = resObj.hallVal;
                scannedDevice.value = hallValue;
                if (hallValue != null && 
                    hallValue < (this.state.passingValue + this.state.tolerance) &&
                    hallValue > (this.state.passingValue - this.state.tolerance) ) {
                        scannedDevice.result = true;
                }
                let deliveredTime = resObj.deliveredTime;
                if (deliveredTime != null) {
                    scannedDevice.time = new Date(deliveredTime).toLocaleString()
                }

                scannedDevices.push(scannedDevice)
                this.updateRows();
            })
            .catch((error) => {
                console.log(error);
            });

        })
        .catch((error) => {
            console.log(error);
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.passingValue == null || this.state.passingValue == "" || 
            this.state.tolerance == null || this.state.tolerance == "") {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'You have to enter a passing value and tolerance.',
            });
            document.getElementById('hallTestInput').value = "";
            return; 
        }

        document.getElementById('hallTestInput').value = "";
        let scannedDevice = new Device(deviceID, false, 0, '');
        this.getHallVal(scannedDevice);
    }

    updateRows = () => {
        const rows = scannedDevices.map((curDevice) => {
            let tbdResult = "❌";
            if (curDevice.result) { tbdResult = "✅"}
            return (
                <tr>
                    <td>{curDevice.id}</td>
                    <td>{tbdResult}</td>
                    <td>{curDevice.value}</td>
                    <td>{curDevice.time}</td>
                </tr>
            )
        })
        this.setState({
            deviceRows: rows
        })
    }

    resetTable() {
        console.log("reset");
        scannedDevices = [];
        this.updateRows();
    }
    
    handleInputChange = (event) => {
        switch (event.target.id) {
            case 'hallTestInput':
                event.preventDefault();
                deviceID = event.target.value;
                break;
            case 'tolerance':
                this.setState({
                    tolerance: event.target.value
                })
                break;
            case 'passingValue':
                this.setState({
                    passingValue: event.target.value
                })
                break;
            default:
                break;
        }
    }

    render() {
        Swal.close();
        return (
            <Container>
                <Row>
                    <Col xs={6} sm={4}>
                        <h2>Hall Sensor Testing</h2>
                        <br />
                        <br />
                        <Form>
                            <Form.Group>
                                <Form.Label>Passing Value</Form.Label>
                                <Form.Control type="text" id="passingValue" defaultValue={this.state.passingValue} onChange={this.handleInputChange} />
                                <br />
                                <Form.Label>Tolerance +/-</Form.Label>
                                <Form.Control type="text" id="tolerance" defaultValue={this.state.tolerance} onChange={this.handleInputChange} />
                            </Form.Group>
                        </Form>
                        <br />
                        <Form onSubmit={this.handleSubmit} >
                            <Form.Group>
                                <Form.Label>Device ID</Form.Label>
                                <Form.Control type="text" id="hallTestInput" placeholder="123456-123456" onChange={this.handleInputChange} />
                            </Form.Group>
                            <Button variant="primary" id="hallTestSubmitButton" type='submit'>Submit</Button>
                        </Form>
                    </Col>
                    <Col xs={6} sm={8}>
                        <Row>
                            <Col sm={10}>
                                <h3>{scannedDevices.length} devices scanned</h3>
                            </Col>
                            <Col sm={2}>
                                <Button variant="link" onClick={this.resetTable.bind(this)} >Clear</Button>
                            </Col>
                        </Row>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Device ID</th>
                                    <th>Result</th>
                                    <th>Value</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.deviceRows}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(HallSensorTesting);