import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import LoadingView from '../LoadingView/LoadingView';
import Table from 'react-bootstrap/Table';
import AllUsersTable from '../../components/Table/AllUsersTable/AllUsersTable';


class AllUsersView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allUsers: [],
            userCount: null,
            loadCount: 0
        }
    }

    componentDidMount() {
        this.getAllUsers()
    }

    getAllUsers() {
        //Get all Users
        // let url = `${config.api}get-all-users`;
        // let token = localStorage.getItem("mlToken");

        // axios.get(
        //     `${config.api}get-all-users`,
        //     {
        //         headers: { Authorization: `Bearer ${token}` }
        //     }
        //   )
        //   .then((response) => {
        //     const data = response["data"];
        //     const users = data.map((item) => {
        //         return item;
        //     });
        //     this.setState({
        //         userCount: users.length
        //     });
        //     this.getUserInfo(users);
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );

        axios({
           method: 'get',
           url: `${config.api}get-all-users`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           const data = response['data'];
           const users = data.map((item) => {
               return item;
           });
           this.setState({
               userCount: users.length
           });
           this.getUserInfo(users);
        })
        .catch((error) => {
           console.log(error);
        });
    }

    getUserInfo(users) {
        console.log("USERS" + users)
        users.forEach((user) => {
            console.log(user);
            //Get all Users details
            let url = `${config.api}users/${user.id}/info`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
            .then((response) => {
                const data = response["data"];
                let userInfo = [];
                if(Array.isArray(data) == true){
                     userInfo = data.map((item) => {
                        return item;
                    });
                }
                
                var userList = this.state.allUsers;
                if(userInfo.length > 0){
                    userList.push(userInfo);
                }
                var loadCounter = this.state.loadCount;
                loadCounter += 1;
                this.setState({
                    allUsers: userList,
                    loadCount: loadCounter
                });
            },
            (error) => {
                console.log(error);
            }
            );
        });
    }

    render() {
        if (this.state.loadCount == this.state.userCount) {
            Swal.close()
            return (
                <Container>
                    <AllUsersTable users={this.state.allUsers} />
                </Container>
            );
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }
}

export default withRouter(AllUsersView);