import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import gradImg from '../../../images/spin.gif';




const AllMapTable = (props) => {


    //const users = props.users;
    //console.log(users);
    //users.sort((a, b) => a[0].id > b[0].id);
    const rows = props.rows;
    console.log("ROWS")
    console.log(rows)
     

    const tableRows = rows.map((row) => {
        let sDate = new Date(row.sTime).toString();
        let eDate = new Date(row.eTime).toString();
        
        let linkText = <a href={row.link}>download</a>
        if (row.link == null){
            linkText = "Not Available"
        }

        let statusCheck = row.reportGenProgress;
        let statusText = "Creating, this may take several minutes"
        
        if (statusCheck == 1){
            statusText = "Complete"
        }
        else{
            statusText = (<img src={gradImg} style={{"maxHeight": "120px" , "maxWidth": "120px"}}/>)
        }
        
        let reportTypeLabel = "N/A"
        if(row.mapType == 1){
            reportTypeLabel = "Heat Map"
        }


            

        return (
            <tr>
                <td>{row.id}</td>
                <td>{statusText}</td>
                <td>{linkText}</td>
                <td>{reportTypeLabel}</td>
                <td>{row.label}</td>
                <td>{sDate}</td>
                <td>{eDate}</td>
                <td>{row.createdAt}</td>            
            </tr>
        );
    });

    return (
        <Container>
            <h2>Past Reports Generated</h2>
            <Table bordered hover style={{backgroundColor: '#fefefe'}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Link</th>
                        <th>Map Type</th>
                        <th>Building</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Date Generated</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <br />
        </Container>
    );



};

export default AllMapTable;
