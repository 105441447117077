import React, { Component } from 'react';
import axios from 'axios';
import config from '../../utilities/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import DashboardView from '../DashboardView/DashboardView';

class OrganizationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            email: null,
            password: null,
            fName: null,
            lname: null,
            orgRole: null,

            orgUsers: []
        };
    }

    componentDidMount() {
        this.getOrgUsers();
    }

    getOrgUsers() {
        var getConfig = {
            method: 'get',
            url: `${config.api}organization/${config.orgID}/users`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            console.log(data);
            const dataKeys = Object.keys(data);
            this.createTableRows(data, dataKeys);
        },
        (error) => {
            console.log(error);
        });
        // .catch((error) => {
        //     console.log(error);
        // });
    }
 
    createTableRows = (userData, userDataKeys) => {
        const tableRows = userDataKeys.map((userKey) => {
            const user = userData[userKey];
            const orgRole = user["organizationRoleID"];
            var userOrgRole = ""
            switch(orgRole) {
                case 4:
                    userOrgRole = "Owner"
                    break;
                case 3:
                    userOrgRole = (
                        <Form.Control defaultValue={3} id="userRoleInput" onChange={this.handleChange} size='sm' as="select">
                            <option value={3} user_id={user.id}>Admin</option>
                            <option value={2} user_id={user.id}>User</option>
                        </Form.Control>
                    );
                    break;
                case 2:
                    userOrgRole = (
                        <Form.Control defaultValue={2} id="userRoleInput" onChange={this.handleChange} size='sm' as="select">
                            <option value={3} user_id={user.id}>Admin</option>
                            <option value={2} user_id={user.id}>User</option>
                        </Form.Control>
                    );
                    break;
                case 1:
                    userOrgRole = (
                        <Form.Control defaultValue={2} id="userRoleInput" onChange={this.handleChange} size='sm' as="select">
                            <option value={3} user_id={user.id}>Admin</option>
                            <option value={2} user_id={user.id}>User</option>
                        </Form.Control>
                    );
                    break;
                default:
                    break;
            }
            return (
                <tr key={userKey}>
                    <td>{user.firstName} {user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{userOrgRole}</td>
                </tr>
            );
        });
        this.setState({
            alerts: tableRows
        });
    }

    commitNewUser = () => {
        Swal.showLoading();
        var data = `[{"organizationRoleID" : \"${this.state.orgRole}\" , "email": \"${this.state.email}\", "password": \"${this.state.password}\", "firstName": \"${this.state.fName}\", "lastName": \"${this.state.lName}\"}]`;
        console.log(data);
        
        var postConfig = {
        method: 'post',
        url: `${config.api}organization/${config.orgID}/users`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };

        axios(postConfig)
        .then((response) => {
            Swal.close();
            Swal.fire({
                'title': "User created",
                'icon': 'success'
            });
            this.getOrgUsers();
        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            console.log(error);
        });

    }

    setOrgRole = (user_id) => {
        var data = `[{"organizationID": ${config.orgID}, "user_id": ${user_id}, "organizationRoleID": ${this.state.orgRole}}]`;
        console.log(data);
        
        var postConfig = {
        method: 'post',
        url: `${config.api}user-organization-roles`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };

        axios(postConfig)
        .then((response) => {
            Swal.close();
            Swal.fire({
                'title': "User created",
                'icon': 'success'
            });
            this.getOrgUsers();
        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            console.log(error);
        });
    }

    handleChange = (event) => {
        switch (event.target.id) {
            case "newUserEmailInput":
                console.log("email");
                this.setState({
                    email: event.target.value
                });
                break;
            case "newUserPasswordInput":
                console.log("password");
                this.setState({
                    password: event.target.value
                });
                break;
            case "newUserFNameInput":
                console.log("f name");
                this.setState({
                    fName: event.target.value
                });
                break;
            case "newUserLNameInput":
                console.log("l name");
                this.setState({
                    lName: event.target.value
                });
                break;
            case "newOrgRoleInput":
                console.log("org role");
                console.log(event.target.value)
                this.setState({
                    orgRole: event.target.value
                });
                break;
            default:
                console.log("default");
                break;
        }
        console.log(event.target.value);
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleClose = () => {
        this.setState({
            showModal: false,
            email: null,
            password: null,
            fName: null,
            lname: null,
            orgRole: null,
        });
    }
    saveUser = () => {
        this.setState({
            showModal: false
        }, () => this.commitNewUser());
    }
    
    render() {
        return (
            <Container style={{'height': '100%'}}>
                <Row style={{'paddingTop': '50px'}}>
                    <Col xs={12} md={6}>
                        <h2 style={{'marginLeft': '0px'}}>Organization Roles</h2>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button onClick={this.handleShow} style={{'backgroundColor': '#3578bd', 'borderColor': '#3578bd', 'position': 'absolute', 'right': '0'}} >&#65291; New User </Button>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={12} style={{"height": "60vh", "overflow": "auto"}}>
                        <Table striped responsive bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.alerts}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Modal 
                    show={this.state.showModal} 
                    onHide={this.handleClose} 
                    style={{opacity:1}} 
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            {/* Email */}
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control id="newUserEmailInput" type="email" onChange={this.handleChange} placeholder="Enter email" />
                                <br />
                            {/* Password */}
                                <Form.Label>Password</Form.Label>
                                <Form.Control id="newUserPasswordInput" type="password" onChange={this.handleChange} placeholder="Password" />
                                <br />
                            {/* First Name */}
                                <Form.Label>First Name</Form.Label>
                                <Form.Control id="newUserFNameInput" type="text" onChange={this.handleChange} placeholder="John" />
                                <br />
                            {/* Last Name */}
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control id="newUserLNameInput" type="text" onChange={this.handleChange} placeholder="Smith" />
                                <br />
                            {/* Org Role */}
                                <Form.Label>Organization Role</Form.Label>
                                <Form.Control id="newOrgRoleInput" onChange={this.handleChange} size='sm' as="select">
                                    <option>-</option>
                                    <option value={3}>Admin</option>
                                    <option value={2}>User</option>
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.saveUser}>
                            Add User
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default OrganizationSettings;