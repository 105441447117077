import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class AddProperty extends Component {

    constructor(props) {
        super(props)
        this.state = {
            label: null,
            OrgId: null,
            isAdmin: false
        }
    }

    componentDidMount() {
        this.getOrgs();
    }


    handleInputChange = (event) => {
        console.log(event.target.value)
        console.log(event.target.id)
        switch (event.target.id) {
            case "addlabel":
                this.setState({
                    label: event.target.value
                });
                break;
            case "addOrgID":
                this.setState({
                    OrgId: event.target.value
                });
                break;
        }
    }
    
    handleAdminChange = event => {
        console.log(event);
        this.setState({
            isAdmin: event.target.checked
        });
    };

    getOrgs = (event) => {

        const url = `${config.api}get-all-organizations`;
            //get all the buildings for user. If user has only one building, get that building (building), 
            //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        ) .then((response) => {
            console.log(response.data);

            const orgs = response.data

            const options = orgs.map((item) => {
                return (
                   <option value={item.id}>{item.label}</option>
                );
            })

            console.log("OPTIONS")
            console.log(options)


            this.setState({
                loaded:true,
                allOrgs: options
            }) 
            
            Swal.close()
        });



    }

    handleSubmit = (event) => {
        console.log(this.state);
        var label = "";
        var OrgId = "";

        //Property Name
        console.log(this.state.label)
        console.warn("***** Need to validate that its an actual email")
        if (this.state.label != null && this.state.label != ""){
            label = String(this.state.label);
        }
        else{
            Swal.fire({  
              title: 'Invalid Property Name',
            });
            return;
        }
        //Org ID
        if (this.state.OrgId != null && this.state.OrgId != ""){
            OrgId = String(this.state.OrgId);
        }
        else{
            Swal.fire({  
              title: 'Invalid Organization ID',
            });
            return;
        }

        this.createProperty(label,OrgId);
    }

    createProperty(label,OrgId) {

        Swal.fire({
            'title' : 'Creating Property...',
            'timer' : 60000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var data = `[{"label": "${label}", "organizationID": ${OrgId}}]`;

        var postConfig = {
            method: 'post',
            url: `${config.api}property`,
            headers: { 
                'Authorization': `Bearer ${config.token}`, 
                'Content-Type': 'text/plain'
            },
            data : data
        };
          
        axios(postConfig)
            .then((response) => {
              Swal.close()
              const data = response["data"][0];
              let error = data["errorCode"];
              if (error == null) {
                Swal.fire({  
                    title: 'Property Added',
                    icon: 'success'
                    }).then(function(){ 
                        window.location.reload();
                        }
                    );
              }
              else {
                  Swal.fire({
                    title: "Error",
                    text: error,
                    icon: 'error'
                  });
              }
              
            },
              (error) => {
                  console.log(error);
              }
            );
    }

    render() {
        if(this.state.loaded){
            return (
                <Container>
                    <h1>Add new Property</h1>
                    <br />
                    <Form>
                        {/* Property Name */}
                        <Form.Group controlId="addlabel">
                            <Form.Label>Property Name</Form.Label>
                            <Form.Control type="text" onChange={this.handleInputChange} placeholder="Property Name" />
                        </Form.Group>
                        <Form.Control.Feedback />
                        {/* Org ID */}

                        <Form.Group>
                                <Form.Label>Select a Organization</Form.Label>
                                <Form.Control as="select" id="addOrgID" onChange={this.handleInputChange} defaultValue={this.state.selectedUser} >
                                    <option value="0">Select a Organization</option>
                                    {this.state.allOrgs}
                                </Form.Control>
                        </Form.Group>
                        <Button variant="primary" onClick={this.handleSubmit}>Add Property</Button>
                    </Form>
                </Container>
            );
        }
        else{
            return(
                <LoadingView />
            );
            
        }
        
    }

}

export default withRouter(AddProperty);