import React, { useState } from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'


import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../../utilities/config';

export default function WiFiForm() {
    const [ssid, setSSID] = useState();
    const [wifiPass, setWifiPass] = useState();
    const [show, setShow] = useState(false)
    const [ssidConfirmed, setSSIDConfirmed] = useState(false);
    const [passConfirmed, setPassConfirmed] = useState(false);

    const submitCredentials = () => {
        Swal.showLoading()
        const data = `[{"userID": ${config.userID}, "ssid": "${ssid}", "password": "${wifiPass}"}]`;
        console.log(data);
        axios({
           method: 'post',
           url: `${config.api}users/${config.userID}/wifiCredentials`,
           headers: {
              'Authorization': `Bearer ${config.token}`, 
              'Content-Type': 'application/json'
           },
           data: data
        })
        .then((response) => {
           const data = response['data'];
           console.log(data);
           Swal.close();
           Swal.fire({
            icon: 'success',
            title: 'WiFi info saved'
           });
        })
        .catch((error) => {
           console.log(error);
           Swal.close();
           Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error
           });
        });        
    }
    
    const handleChange = (event) => {
        switch(event.target.id) {
            case 'ssid-input':
                setSSID(event.target.value);
                break;
            case 'wifi-password-input':
                setWifiPass(event.target.value);
                break;
        }
    }

    const handleSubmit = () => {
        handleClose();
        submitCredentials();
    }

    const handleClose = () => {
        setShow(false);
        setSSIDConfirmed(false);
        setPassConfirmed(false);
    }

  return (
    <>
    <Container className="formContainer">
        <Form className="wifiForm" >
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label><h4>Network Name</h4></Form.Label>
                        <Form.Text>Please enter the name of the Wi-Fi network you would like your devices to use.</Form.Text>
                        <Form.Control className="ssidInput" type="text" name="ssid-input" id="ssid-input" placeholder="" onChange={handleChange} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label><h4>Password</h4></Form.Label>
                        <Form.Text>Please enter the password for the Wi-Fi network you would like your devices to use.</Form.Text>
                        <Form.Control className="wifiPasswordInput" type="text" name="wifi-password-input" id="wifi-password-input" placeholder="" onChange={handleChange} />
                    </Form.Group>
                </Col>
            </Row>
            <Button onClick={() => setShow(true)} >Submit</Button>
        </Form>
    </Container>

    <Modal 
        show={show} 
        onHide={handleClose} 
        style={{opacity:1}} 
        animation={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>Confirm Network Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <p>Please confirm your credentials before continuing. Your devices will not work properly if these are incorrect</p>
                </Row>
                <Row>
                    <p>Network: &ensp;</p>
                    <p><strong>{ssid}</strong></p>
                </Row>
                <Row>
                    <Form.Check 
                        type={'checkbox'}
                        id={`ssid-confirm`}
                        label={`This network is correct`}
                        onClick={() => setSSIDConfirmed(!ssidConfirmed)}
                    />
                </Row>
                <Row style={{paddingTop: '24px'}}>
                    <p>Password: &ensp;</p>
                    <p><strong>{wifiPass}</strong></p>
                </Row>
                <Row>
                    <Form.Check 
                        type={'checkbox'}
                        id={`pass-confirm`}
                        label={`This password is correct`}
                        onClick={() => setPassConfirmed(!passConfirmed)}
                    />
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Change
            </Button>
            <Button variant="primary" disabled={!ssidConfirmed || !passConfirmed} onClick={handleSubmit}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}
