import React, { Component } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import config from '../../utilities/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import LoadingView from '../LoadingView/LoadingView';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Table } from 'react-bootstrap';
import { each } from 'jquery';

class RawDataViewTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceSerialNum: config.DeviceID,
            viewtype: false,
            deviceData: [],
        };
    }

    getRawMetrics(sqlID) {
        //Call to /metrics/raw/devices?deviceIDs=<sqlID>
        //where <sqlID> is the ID of the device in the database
        //to get the latest metrics sent by the seletced device
        axios({
            method: 'get',
            url: `${config.api}metrics/raw/devices?deviceIDs=${config.DeviceID.id}&limit=50`,
            headers: {
                'Authorization': `Bearer ${config.token}`,
            }
        })
            .then((response) => {
                if (response.data.length == 0) {
                    Swal.fire({
                        icon: "error",
                        title: "Can't find that device",
                        text: 'Double check your Device ID'
                    })
                    return;
                }
                const resObj = response.data;

                console.log(resObj);

                this.state.deviceData = resObj.map((obj) => {
                    let batteryVoltage = obj.batteryV;
                    if (batteryVoltage != null) {
                        batteryVoltage = String(batteryVoltage);
                        batteryVoltage = batteryVoltage.substring(0, 1) + '.' + batteryVoltage.substring(1);
                    }
                    let deliveredTime = obj.deliveredTime;
                    if (deliveredTime != null) {
                        deliveredTime = new Date(deliveredTime).toLocaleString()
                    }
                    let createdTime = obj.createdAt;
                    if (createdTime != null) {
                        createdTime = new Date(createdTime).toLocaleString()
                    }

                    return (
                        <tr>
                            <td>{obj.theStatus}</td>
                            <td>{batteryVoltage}</td>
                            <td>{deliveredTime}</td>
                            <td>{createdTime}</td>
                            <td>{obj.rssi}</td>
                            <td>{obj.ticks}</td>
                        </tr>

                    )

                })

                this.setState({
                    loaded: true
                });
            })
            .catch((error) => {
                console.log(error);
                if (String(sqlID).includes('-')) {
                    Swal.fire({
                        icon: "error",
                        title: "Can't find that device",
                        text: 'Double check your Device ID'
                    })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Error Getting Raw Data",
                        text: error
                    })
                }
            });
    }

    render() {
        Swal.close();
        return (
            <Container>
                <Button onClick={() => { this.getRawMetrics(config.DeviceID) }}>Submit</Button>
                <Table striped hover>
                <thead>
                <tr>
                    <th>Packet type:</th>
                    <th>Battery Voltage:</th>
                    <th>Delivered Time:</th>
                    <th>Created Time:</th>
                    <th>RSSI:</th>
                    <th>Ticks:</th>
                </tr>
                </thead>
                <tbody>
                {this.state.deviceData}
                </tbody>
                </Table>
            </Container>)


    }
}
export default withRouter(RawDataViewTable);