import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import './MasqueradeView.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../../components/Sidebar/Sidebar.js';
import Button from 'react-bootstrap/Button';
import LoadingView from '../LoadingView/LoadingView';
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Container from 'react-bootstrap/Container';
import config from '../../utilities/config.js';

 

class MasquaradeView extends Component {
    
    constructor(props){
        super(props);
    }
    
    state = {
        users: null
    };


    componentDidMount(){
        //do this call
        let url = `${config.api}get-all-users`;

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
          )
    
          .then((response) => {
            this.setState({users: response["data"]})
          },
          (error) => {
            console.log(error);
          }
        );


    }

    setMasquerade = ele => {
        const userID = $("#userSelect").val();
        console.log(userID);
        if (config.isMasquerading == false) {
            localStorage.setItem("mlOriginalUser" , config.userID);
            localStorage.setItem("mlUserID" , userID);
            config.userID = userID;
            localStorage.setItem("mlBuildingID" , 0);
            config.selectedBuilding = 0;
            localStorage.setItem("mlIsMasquerading" , true);
            config.isMasquerading = true;
            console.log(config);
            window.location.href = "/";
        }
        else {
            localStorage.setItem("mlUserID" , userID);
            config.userID = userID;
            console.log(config);
            window.location.href = "/";
        }

    };


    render() {
        let users = this.state.users;

        if(users != null){

            Swal.close();
            return (
                <div id="masqueradeOuterContainer" className="masqueradeContainer" >
                    <Sidebar 
                        pageWrapId={"masqueradePageWrap"} 
                        outerContainerId={"masqueradeOuterContainer"} 
                        buttonsVisible={false} 
                    />
    
                    <Container id="masqueradePageWrap" className="masqueradePageWrap" >

                        <div className="masqueradeFormContainer">
                            <div className="masqueradeForm">
                                <h2 className="select-title">
                                    Select a User to Masquerade
                                </h2>
                                <select id="userSelect">
                                <option value="0">Select a User</option>
                                {
                                    users.map((ele,index) => {

                                        return (
                                        <option value={ele.id}>{ele.email}</option>
                                        );
                                    })
                                }
                                </select>
                                <br />
                                <br />
                                <Button variant="primary" onClick={this.setMasquerade}>
                                    Masquerade
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            )
        }

        else{
            return <LoadingView />;
        }

    }
}

export default withRouter(MasquaradeView);