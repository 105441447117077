import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { Button, Form } from 'react-bootstrap';
import AdvSch from './AdvancedSeachVars';
import OrgResultsView from './OrgResultsView.js';
import PropResultsView from './PropResultsView.js';
import UserResultsView from './UserResultsView.js';

var autocompUsers = [];
var autocompOrgs = [];
var autocompProps = [];
var autocompBuildings = [];
var autocompLocs = [];
var autocompDevices = [];


class AdvancedSearchView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedUsers: false,
            loadedOrgs: false,
            loadedProps: false,
            loadedBuildings: false,
            loadedLocs: false,
            loadedDevices: false,
            focus: "",
            resultpage: false
        };
    }

    getOrgs() {
        //Get all Orgs
        if (this.state.loadedOrgs) {
            return autocompOrgs;
        }
        else if (this.state.focus == "o") {
            let url = `${config.api}get-all-organizations`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    console.log(response)
                    const data = response["data"];
                    autocompOrgs = []
                    data.map((item) => {
                        autocompOrgs.push({ name: item.label, id: item.id });
                        return item;
                    });
                    this.setState({
                        loadedOrgs: true,
                    });
                    return autocompOrgs;
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getUsers() {
        //Get all Users
        if (this.state.loadedUsers) {
            return autocompUsers;
        }
        else if (this.state.focus == "u") {
            let url;
            if (AdvSch.Org != null) {
                url = `${config.api}users/organization/${AdvSch.Org.id}`;
            }
            else {
                url = `${config.api}get-all-users`;
            }

            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    console.log(response)
                    const data = response["data"];
                    autocompUsers = []
                    data.map((item) => {
                        autocompUsers.push({ name: item.email, id: item.id });
                        return item;
                    });
                    this.setState({
                        loadedUsers: true,
                    });
                    return autocompUsers;
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }



    getProps() {
        //Get all Orgs
        if (this.state.loadedProps) {
            return autocompProps;
        }
        else if (this.state.focus == "p") {
            let url;
            if (AdvSch.User != null) {
                url = `${config.api}user/${AdvSch.User.id}/properties`;
            }
            else if (AdvSch.Org != null) {
                url = `${config.api}organization/${AdvSch.Org.id}/properties`;
            }
            else {
                url = `${config.api}get-all-properties`;
            }
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    console.log(response)
                    const data = response["data"];
                    autocompProps = []
                    data.map((item) => {
                        autocompProps.push({ name: item.label, id: item.id });
                        return item;
                    });
                    this.setState({
                        loadedProps: true,
                    });
                    return autocompProps;
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getBuildings() {
        //Get all Orgs
        if (this.state.loadedBuildings) {
            return autocompBuildings;
        }
        else if (this.state.focus == "b") {
            let url;
            if (AdvSch.Prop != null) {
                url = `${config.api}property/${AdvSch.Prop.id}/buildings`;
            }
            else if (AdvSch.User != null) {
                url = `${config.api}user/${AdvSch.User.id}/buildings`;
            }
            else {
                url = `${config.api}get-all-buildings`;
            }



            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    console.log(response)
                    const data = response["data"];
                    autocompBuildings = []
                    data.map((item) => {
                        autocompBuildings.push({ name: item.label, address: item.address, id: item.id });
                        return item;
                    });
                    this.setState({
                        loadedBuildings: true,
                    });
                    return autocompBuildings;
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getLocs() {
        //Get all Locations
        if (this.state.loadedLocs) {
            return autocompLocs;
        }
        else if (this.state.focus == "l" && AdvSch.Building != null) {
            let url = `${config.api}buildings/${AdvSch.Building.id}/locations`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    console.log(response)
                    const data = response["data"];
                    autocompLocs = []
                    data.map((item) => {
                        autocompLocs.push({ name: item.locationName, id: item.locationID });
                        return item;
                    });
                    this.setState({
                        loadedLocs: true,
                    });
                    return autocompLocs;
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    getDevices() {
        //Get all Users
        if (this.state.loadedDevices) {
            return autocompDevices;
        }
        else if (this.state.focus == "d") {
            let url;
            if (AdvSch.Building != null) {
                url = `${config.api}buildings/${AdvSch.Building.id}/devices`;
            }
            else {
                url = `${config.api}get-all-devices`;
            }

            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    console.log(response)
                    const data = response["data"];
                    autocompDevices = []
                    data.map((item) => {
                        autocompDevices.push({ name: item.deviceID, id: item.id });
                    });
                    this.setState({
                        loadedDevices: true
                    });
                    return autocompDevices;
                },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }

    handleSubmit() {
        this.setState({
            resultpage: true
        });
    }


    render() {
        Swal.close();
        if (this.state.resultpage) {
            if(AdvSch.Device!=null){
                
            }
            else if(AdvSch.Locs!=null){
                
            }
            else if(AdvSch.Building!=null){
                
            }
            else if(AdvSch.Prop!=null){
                return (<PropResultsView />)
            }
            else if(AdvSch.User!=null){
                return (<UserResultsView />)
            }
            else if(AdvSch.Org!=null){
                return (<OrgResultsView />)
            }
            else{
                return null;
            }
        }
        else {
            return (
                <Container>
                    <Form.Group>
                        <Form.Label>Organization:</Form.Label>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                zIndex: '1000',
                            }}
                            items={this.getOrgs()}
                            onSearch={(item) => { if (item == "") AdvSch.Org = null; }}
                            onHover={() => { }}//ignored
                            onSelect={(item) => {
                                // the item selected
                                console.log(item.name)
                                AdvSch.Org = item;
                                this.setState({
                                    loadedUsers: false,
                                    loadedProps: false,
                                    loadedBuildings: false,
                                    loadedLocs: false,
                                    loadedDevices: false
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    focus: "o",
                                });
                            }}
                            showNoResultsText="No Organizations found"
                            formatResult={(item) => {
                                return (
                                    <span>{Object.values(item.name)}</span>
                                )
                            }}
                        />

                        <Form.Label>User:</Form.Label>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                zIndex: '999',
                            }}
                            items={this.getUsers()}
                            onSearch={(item) => { if (item == "") AdvSch.User = null; }}
                            onHover={() => { }}//ignored
                            onSelect={(item) => {
                                // the item selected
                                console.log(item.name)
                                AdvSch.User = item;
                                this.setState({
                                    loadedProps: false,
                                    loadedBuildings: false,
                                    loadedLocs: false,
                                    loadedDevices: false
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    focus: "u",
                                });
                            }}
                            showNoResultsText="No Users found"
                            formatResult={(item) => {
                                return (
                                    <span>{Object.values(item.name)}</span>
                                )
                            }}
                        />



                        <Form.Label>Property:</Form.Label>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                zIndex: '998',
                            }}
                            items={this.getProps()}
                            onSearch={(item) => { if (item == "") AdvSch.Prop = null; }}
                            onHover={() => { }}//ignored
                            onSelect={(item) => {
                                // the item selected
                                console.log(item.name)
                                AdvSch.Prop = item;
                                this.setState({
                                    loadedBuildings: false,
                                    loadedLocs: false,
                                    loadedDevices: false
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    focus: "p",
                                });
                            }}
                            showNoResultsText="No Properties found"
                            formatResult={(item) => {
                                return (
                                    <span>{Object.values(item.name)}</span>
                                )
                            }}
                        />

                        <Form.Label>Building:</Form.Label>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                zIndex: '997',
                            }}
                            items={this.getBuildings()}
                            onSearch={(item) => { if (item == "") AdvSch.Building = null; }}
                            onHover={() => { }}//ignored
                            onSelect={(item) => {
                                // the item selected
                                console.log(item)
                                AdvSch.Building = item;
                                this.setState({
                                    loadedLocs: false,
                                    loadedDevices: false
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    focus: "b",
                                });
                            }}
                            showNoResultsText="No Buildings found"
                            formatResult={(item) => {
                                return (
                                    <Container>
                                        <span>{Object.values(item.name)}</span>
                                    </Container>
                                )
                            }}
                        />

                        <Form.Label>Location:</Form.Label>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                zIndex: '996',
                            }}
                            items={this.getLocs()}
                            onSearch={(item) => { if (item == "") AdvSch.Locs = null; }}
                            onHover={() => { }}//ignored
                            onSelect={(item) => {
                                // the item selected
                                AdvSch.Locs = item;
                                console.log(item.name)
                                this.setState({
                                    loadedDevices: false
                                });
                            }}
                            onFocus={() => {
                                this.setState({
                                    focus: "l",
                                });
                            }}
                            showNoResultsText="No Locations found(Building Required)"
                            formatResult={(item) => {
                                return (
                                    <span>{Object.values(item.name)}</span>
                                )
                            }}
                        />

                        <Form.Label>Device:</Form.Label>
                        <ReactSearchAutocomplete
                            styling={{
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                                zIndex: '995',
                            }}
                            items={this.getDevices()}
                            onSearch={(item) => { if (item == "") AdvSch.Device = null; }}
                            onHover={() => { }}//ignored
                            onSelect={(item) => {
                                // the item selected
                                AdvSch.Device = item;
                                console.log(item.name)
                            }}
                            onFocus={() => {
                                this.setState({
                                    focus: "d",
                                });
                            }}
                            showNoResultsText="No devices found"
                            formatResult={(item) => {
                                return (
                                    <span>{Object.values(item.name)}</span>
                                )
                            }}
                        />
                        <Button onClick={()=>{this.handleSubmit()}}>Submit</Button>
                    </Form.Group>
                </Container>
            );
        }
    }
}

export default withRouter(AdvancedSearchView);