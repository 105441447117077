import React, { Component } from 'react';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2'
import axios from 'axios';
import config from '../../utilities/config';
import DatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { withRouter, BrowserRouter as Route, Switch } from 'react-router-dom';

var weeklyReportButtons = [];

class DeviceMacAddress extends Component {

    constructor(props) {
        super(props)
        var valueMin = new Date();
        var valueMax = new Date();
        valueMin.setHours(0)
        valueMin.setMinutes(0)
        valueMin.setSeconds(0)
        valueMin.setMilliseconds(0)
        valueMax.setHours(23)
        valueMax.setMinutes(59)
        valueMax.setSeconds(59)
        valueMax.setMilliseconds(999)
        var stringMin = valueMin.toDateString();
        var stringMax = valueMax.toDateString();

        this.state = {
            file: null,
            fUpload: false,
            valueMin: valueMin,
            valueMax: valueMax,
            stringMin: stringMin,
            stringMax: stringMax
        }
    }

    componentDidMount() {
        //Get all building IDs
        let url = `${config.api}get-all-buildings`;
        let token = localStorage.getItem("mlToken");

    }

    handleFile = (event)  =>  {
        console.log(event.target.files[0])
        this.setState({
            file: event.target.files[0],
            fUpload: true
          })

    }

    handleFileTimeMin = (value, fm)  =>  {
        console.log(value)
        this.setState({
            valueMin: value,
            stringMin: value.toDateString(),
            fUpload: false
          })

    }

    handleFileTimeMax = (value, fm)  =>  {
        console.log(value)
        this.setState({
            valueMax: value,
            stringMax: value.toDateString(),
            fUpload:false
          })

    }

    handleSubmit = (event) => {

        Swal.fire({
            'title' : 'Generating File',
            'timer' : 30000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });
        if(this.state.fUpload){
            var data = new FormData();
            data.append('file', this.state.file);
            
            var sConfig = {
            method: 'post',
            url: `${config.api}devices/macAddressReport/deviceID`,
            headers: { 
                'Authorization': `Bearer ${config.token}`, 
            },
            data : data
            };
            
            axios(sConfig)
            .then(function (response) {

                if(response.data.error == null){
                    if(response.data.results != null){
                        const fileName = response.data.results.uploadFile;
                        Swal.fire({
                            'title' : `File`,
                            'html': `<a href="${fileName}">Download</a>`,
                            'allowOutsideClick': false
                        });
                    }
                }
                else{
                    Swal.fire({
                        'title' : `Error`,
                        'html': `Something went wrong with the request.`,
                        'allowOutsideClick': false
                    });
                }           
            })
            .catch(function (error) {
            console.log(error);
            });
        }
        else{
            let vMin = this.state.valueMin;
            let vMax = this.state.valueMax;


            vMin.setHours(0)
            vMin.setMinutes(0)
            vMin.setSeconds(0)
            vMin.setMilliseconds(0)
            vMax.setHours(23)
            vMax.setMinutes(59)
            vMax.setSeconds(59)
            vMax.setMilliseconds(999)

            const vMinF = parseInt(vMin.getTime()/1000);
            const vMaxF = parseInt(vMax.getTime()/1000);

            const url =  `${config.api}devices/macAddressReport/time?minValue=${vMinF}&maxValue=${vMaxF}&timezone=America/New_York`;

            var sConfig = {
                method: 'get',
                url: url,
                headers: { 
                  'Authorization': `Bearer ${config.token}`
                }
              };

              axios(sConfig)
              .then(function (response) {
                if(response.data.error == null){
                    if(response.data.results != null){
                        const fileName = response.data.results.uploadFile;
                        Swal.fire({
                            'title' : `File`,
                            'html': `<a href="${fileName}">Download</a>`,
                            'allowOutsideClick': false
                        });
                    }
                }
                else{
                    Swal.fire({
                        'title' : `Error`,
                        'html': `Something went wrong with the request.`,
                        'allowOutsideClick': false
                    });
                }
                console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
                 //swal alert error
                 Swal.fire({
                    'title' : `Error`,
                    'html': `Something went wrong with the request.`,
                    'allowOutsideClick': false
                });
              });
              

        }
    }


    render() {

            Swal.close();
            return (
                <Container>
                    <h1>Generate MAC Address Report</h1>
                    <br />
                    <Form>
    
                        {/* Building */}


                        <Row>
                            <Col >
                                <a href = "https://flowactive-public-documents.s3.amazonaws.com/Examples/example-mac-addresses.csv">Example File</a>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Device File</Form.Label>
                                    <br></br>
                                    <Form.Control type="file" onChange={this.handleFile}/>
                                </Form.Group>
                                <Button variant="primary" value="submit" onClick={this.handleSubmit}>Get Mac Address Report (By Device)</Button>
                            </Col>
                            <Col >
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Upload File with Devices (Devices)</Form.Label>
                                    <br></br>
                                    <br></br>
                                    Start Date:
                                    <br />
                                    <DatePicker id="dp-min"  value={this.state.stringMin} onChange={this.handleFileTimeMin}/>
                                    <br />
                                    <br />
                                    End Date:
                                    <br />
                                    <DatePicker id="dp-max"   value={this.state.stringMax} onChange={this.handleFileTimeMax}/>
                                </Form.Group>
                                <br />

                                <Button variant="primary" value="submit" onClick={this.handleSubmit}>Get Mac Address Report (By Time)</Button>

                            </Col>
                        </Row>

                        <br></br>
    
                        {/* Start Date */}
                        <Row>
                        </Row>
    
                        {/* End Date */}
                        

                    </Form>
                    {weeklyReportButtons}
                </Container>
            );
        
    }


 
}

export default withRouter(DeviceMacAddress);