import React from 'react';
import './GallonsLeaked.scss';
import './GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';
import config from '../../../utilities/config';

const GallonsLeaked = (props) => {

    let gallonsLeaked = Number(props.gallonsLeaked).toLocaleString();
    console.log(gallonsLeaked);
    console.log(gallonsLeaked == '0');

    let leakedColor = '#f04451'
    if (gallonsLeaked == '0') {
        leakedColor = '#4cc0ac'
    }

    let cost = null;

    if (props.hasOwnProperty('cost')) {
        if (config.userID == 89) {
            const demoMultiplier = localStorage.getItem('mlDemoMultiplier');
            if (demoMultiplier != null) {
                cost = Number(props.cost * demoMultiplier).toFixed(2).toLocaleString();
            }
            else {
                cost = Number(props.cost).toLocaleString();
            }
        }
        else {
            cost = Number(props.cost).toLocaleString();
        }
    }

    const openWaterUse = () => {
        const history = props.history;
        history.push("/water-use");
    }

    if (props.history != null) {
        let fCost = numberWithCommas(cost);
        return (
            // <Container className="gallonsLeakedBackground" onClick={openWaterUse}>
            <Container className="gallonsLeakedBackground">
                <Row style={{"height": "25%"}} >
                    <p className="blocksLabel">Gallons Leaked</p>
                </Row>
                <Row style={{"height": "75%"}} >
                    <p className="totalGallonsLeaked" style={{color: `${leakedColor}`}}>
                        <div>{gallonsLeaked}</div>
                        {cost !== null && (<div className="totalGallonsLeakedCost">${fCost}</div>)}
                    </p>
                </Row>
            </Container>
        );
    }
    else {
        return (
            <Container className="gallonsLeakedBackground">
                <Row style={{"height": "25%"}} >
                    <p className="blocksLabel">Gallons Leaked</p>
                </Row>
                <Row style={{"height": "75%"}} >
                    <p className="totalGallonsLeaked" style={{color: `${leakedColor}`}}>{gallonsLeaked}</p>
                </Row>
            </Container>
        );
    }
    
};

export default GallonsLeaked;