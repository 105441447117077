import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingView from '../LoadingView/LoadingView.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import config from '../../utilities/config.js';


function Device(id, sent, confirmed, scanTime) {
    this.id = id;
    this.sent = sent;
    this.confirmed = confirmed;
    this.scanTime = scanTime
}

var deviceID = "";
var scannedDevices = [];
var allDevices = new Map;
var validateTimer = null;

class ProvisionDevicesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceRows: null,
            submitType: "batch",
            resetOnSuccess: false,
            ssid: null,
            password: null,
            interval: 8,
            loaded: false
        }
    }

    componentDidMount() {
        this.getAllDevices();
        validateTimer = setInterval(this.validateDevices.bind(this), 10000);
    }

    componentWillUnmount() {
        clearInterval(validateTimer);
    }
    
    getAllDevices() {
        //Get all Users
        let url = `${config.api}get-all-devices`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            const data = response["data"];
            data.forEach((item) => {
                allDevices.set(item.deviceID, item.id);
            });
            this.setState({
                loaded: true
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
    
    handleInputChange = (event) => {
        switch (event.target.id) {
            case 'provisionInput':
                console.log("Input Change");
                event.preventDefault();
                deviceID = event.target.value;
                break;

            case 'provisionSsid':
                this.setState({
                    ssid: event.target.value
                })
                console.log(this.state.ssid);
                break;

            case 'provisionPassword':
                this.setState({
                    password: event.target.value
                })
                console.log(this.state.password);
                break;

            case 'provisionInterval':
                this.setState({
                    interval: event.target.value
                })
                console.log(this.state.interval);
                break;

            default:
                break;
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        console.log("Handle Submit");

        if (this.state.ssid == null || this.state.ssid == "" || 
            this.state.password == null || this.state.password == "") {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'You have to enter an SSID and Password.',
            });
            document.getElementById('provisionInput').value = "";
            return; 
        }
        console.log("DEVICE ID");
        console.log(deviceID);
        const scanTime = Date.now();
        let scannedDevice = new Device(deviceID, false, false, scanTime);
        scannedDevices.push(scannedDevice)
        this.updateRows();
        document.getElementById('provisionInput').value = "";
        if (this.state.submitType == 'single') {
            console.log("PROVISION SINGLE DEVICE");
            this.provisionDevices();
        }
    }

    updateRows = () => {
        console.log("Update Rows");
        console.log(scannedDevices);
        const rows = scannedDevices.map((curDevice) => {
            let tbdSent = "❌";
            if (curDevice.sent) { tbdSent = "✅"}
            let tbdConfirmed = "❌";
            if (curDevice.confirmed) { tbdConfirmed = "✅"}
            return (
                <tr>
                    <td>{curDevice.id}</td>
                    <td>{tbdSent}</td>
                    <td>{tbdConfirmed}</td>
                </tr>
            )
        })
        this.setState({
            deviceRows: rows
        })
    }

    provisionDevices() {
        //Show loading pop up
        Swal.fire({
            title: 'Loading...',
            html: '<strong style="color: red;">Do not close this page.</strong>',
            'timer' : 60000,
            'allowOutsideClick': false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //Construct array of devices to be provisioned
        var inputArray = [];
        scannedDevices.forEach((device) => {
            if (device.sent == false) {
                inputArray.push(device.id);
            }
        });
        if (inputArray.length == 0) {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'You must have scanned at least one new device.',
            })
            return; 
        }

        //Provision Devices
        let url = `${config.old_api}update/set-multi`;
        let token = localStorage.getItem("mlToken");

        var postData = JSON.stringify({
            "hrDeviceIDs":inputArray,
            "token":token,
            "config":{"espConfig":{"config":{"wifi":{"sta":{"ssid":this.state.ssid,"pass":this.state.password}},"sntp":{"enable":true}}},
                "picConfig": {"normalStatus": this.state.interval},   
                "immVal":true}
        });

        console.log(postData);

        axios.post(
            url,
            postData
        )
        .then((response) => {
            let data = response["data"];
            if (data[0]['success']) {
                scannedDevices.forEach((device) => {
                    device.sent = true;    
                });
                this.updateRows();
                Swal.close();
                if (this.state.submitType == 'batch') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Done',
                        text: 'Provisioning requests were successfully sent.\nDon\'t forget to press the button on each device!'
                    })
                }
            }
            else {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: data,
                })
            }
        },
        (error) => {
            console.log(error);
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error,
            })
        }
        );

    }



    validateDevices() {
        console.log('In validate');
        //Construct array of devices to be validated
        var inputArray = [];
        console.log("SCANNED DEVICES");
        console.log(scannedDevices);
        scannedDevices.forEach((device) => {
            if (device.sent == true && device.confirmed == false) {
                inputArray.push(device.id);
            }
        });
        if (inputArray.length == 0) {
            return; 
        }

        // let mainThis = this;
        
        inputArray.forEach((device) => {
            const dbID = allDevices.get(device);
            console.log("GET ALL DEVICES")
            console.log(allDevices);
            //Validate Devices
            let url = `${config.api}update/check-verified`;
            let token = localStorage.getItem("mlToken");

            axios.post(
                url,
                {
                    "token": `${token}`,
                    "ids": [dbID],
                    "updateWindow": 3600000
                }
            )
            .then((response) => {
                let data = response["data"][0];
                console.log(data);
                const validated = data.validated;
                console.log(validated);
                scannedDevices.forEach((dev) => {
                    if (dev.id == device && validated == true) {
                        dev.confirmed = true;
                    }
                });
                this.updateRows();
            },
            (error) => {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                })
            }
            );
        });
    }

    handleSettingsChange = (event) => {
        switch (event.target.id) {
            case "single":
                console.log(event.target.checked);
                document.getElementById('batch').checked = false;
                document.getElementById('provisionSubmitButton').disabled = true;
                this.setState({
                    submitType: 'single'
                });
                break;

            case "batch":
                console.log(event.target.checked);
                document.getElementById('single').checked = false;
                document.getElementById('provisionSubmitButton').disabled = false;
                this.setState({
                    submitType: 'batch'
                });
                break;

            default:
                break;
        }
    }

    resetTable() {
        console.log("reset");
        scannedDevices = [];
        this.updateRows();
    }

    render() {
        if (this.state.loaded) {
            Swal.close();
            return (
                <Container>
                    <Row>
                        <Col xs={6} sm={4}>
                            <h2>Provision Devices</h2>
                            <br />
                            <br />
                            <Form>
                                <Form.Group>
                                    <Form.Label>SSID</Form.Label>
                                    <Form.Control type="text" id="provisionSsid" onChange={this.handleInputChange} />
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="text" id="provisionPassword" onChange={this.handleInputChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check style={{"alignItems": "left"}} inline label='Single' type='checkbox' id='single' onChange={this.handleSettingsChange} feedback="Test" />
                                    <Form.Check inline label='Batch' type='checkbox' id='batch' defaultChecked={true} onChange={this.handleSettingsChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control  style={{"display": "none"}} type="text" id="provisionInterval" defaultValue={this.state.interval} onChange={this.handleInputChange} />
                                </Form.Group>
                            </Form>
                            <br />
                            <Form onSubmit={this.handleSubmit} >
                                <Form.Group>
                                    <Form.Label>Device ID</Form.Label>
                                    <Form.Control type="text" id="provisionInput" placeholder="123456-123456" onChange={this.handleInputChange} />
                                </Form.Group>
                                <Button variant="primary" id="provisionSubmitButton" onClick={this.provisionDevices.bind(this)}>Submit</Button>
                            </Form>
                            <br />
                            <br />
                        </Col>
                        <Col xs={6} sm={8}>
                            <Row>
                                <Col sm={10}>
                                    <h3>{scannedDevices.length} devices scanned</h3>
                                </Col>
                                <Col sm={2}>
                                    <Button variant="link" onClick={this.resetTable.bind(this)} >Clear</Button>
                                </Col>
                            </Row>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Device ID</th>
                                        <th>Sent</th>
                                        <th>Confirmed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deviceRows}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            );
        }
        else {
            return(
                <LoadingView />
            );
        }
    }
}

export default withRouter(ProvisionDevicesView);