import React, { Component } from 'react';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2'
import axios from 'axios';
import config from '../../utilities/config';
import DatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

var weeklyReportButtons = [];

class PdfReportView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allBuildingOptions: null,
            buildingID: null,
            startDate: null,
            endDate: null
        }
    }

    componentDidMount() {
        //Get all building IDs
        let url = `${config.api}get-all-buildings`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
          )
          .then((response) => {
            console.log(response);
            let data = response["data"];
            console.log(data);
            this.createBuildingOptions(data);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    createBuildingOptions(allBuildings) {
        const options = allBuildings.map((item) => {

            return (
               <option value={item.id}>{item.label}</option>
            );
        })
        this.setState({
            allBuildingOptions: options
        });
    }

    handleBuildingChange = event => {
        this.setState({
          buildingID: event.target.value
        });
      };

    handleStartChange = date => {
        this.setState({
          startDate: date
        });
      };
    
    handleEndChange = date => {
        this.setState({
            endDate: date
        });
    };

    handleSubmit = (event) => {
        console.log(this.state);
        var startDate = "";
        var endDate = "";
        var buildingID = "";

        if(this.state.startDate != null){
            startDate = this.state.startDate.toISOString().split('T')[0]
          }
          else{
            Swal.fire({  
              title: 'Invalid Start Date',
            });
            return;
          }
      
          if(this.state.endDate != null){
            endDate = this.state.endDate.toISOString().split('T')[0]
      
          }
          else{
            Swal.fire({  
              title: 'Invalid End Date',
            });
            return;
          }

          if (this.state.startDate < this.state.endDate) {
            console.log("<" + true);
          }
          else {
            Swal.fire({  
                title: 'Start Date Must Be Earlier Than End Date',
              });
              return;
          }
      
          if(this.state.buildingID != null && this.state.buildingID > 0){
            buildingID = this.state.buildingID;
          }
          else if (event.target.value = "weekly") {/* just needs to pass the check, we ignore buildingID for weekly reports */}
          else{
            Swal.fire({  
              title: 'Invalid Building',
            });
            return;
          }

          switch(event.target.value) {
            case "submit":
              this.generateReport(startDate, endDate, buildingID);
              break;
            case "weekly":
              this.generateWeeklyReports(startDate, endDate, 20);
              break;
            default:
              break;
          }
    }

    generateReport(startDate, endDate, buildingID) {
        Swal.fire({
            'title' : 'Generating Report...',
            'timer' : 60000,
            'allowOutsideClick': false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        
        
          });

          let token = localStorage.getItem("mlToken");
          let url = `${config.api}pdf-generator?startDate=` + 
                        startDate + "&endDate=" + endDate + "&buildingID=" + buildingID
          console.log(url);
          axios.get(
              url,
              {
                  headers: { Authorization: `Bearer ${token}` }
              }
            )
      
            .then((response) => {
              Swal.close()
              let data = response["data"];
              let body = JSON.parse(data["body"]);
              let url = body["reportFileURL"];
              Swal.fire({  title: 'Report Created',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3578bd',
              confirmButtonText: 'Download'
              }).then(() => {
                window.open(url);
              })
            },
              (error) => {
                  console.log(error);
              }
            );
    }


    generateWeeklyReports(startDate, endDate, buildingID) {
      console.log("Weekly Reports");
      console.log("=================================================");
      Swal.fire({
          'title' : 'Generating Report...',
          'timer' : 60000,
          'allowOutsideClick': false,
          onBeforeOpen: () => {
              Swal.showLoading()
          }
      });

      const buildingIDs = [14,17,19,32,33,43,45,46,59,60,61];

      buildingIDs.forEach((building, index) => {
        let url = `${config.api}pdf-generator?startDate=` + startDate + "&endDate=" + endDate + "&buildingID=" + building
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
        .then((response) => {
          let data = response["data"];
          let body = JSON.parse(data["body"]);
          let url = body["reportFileURL"];
          console.log(url);
          if (index == buildingIDs.length-1) { 
            Swal.close()
            console.log("================================================="); 
          }
        },
          (error) => {
              console.log(error);
          }
        );

      });
  }

    render() {
        if (this.state.allBuildingOptions == null) {
            return <LoadingView />;
        }
        else {
            Swal.close();
            return (
                <Container>
                    <h1>Generate a PDF Report</h1>
                    <br />
                    <Form>
    
                        {/* Building */}
                        <Row>
                            <Form.Group controlId="buildingSelect">
                                <Form.Label>Building</Form.Label>
                                <Form.Control as="select" onChange={this.handleBuildingChange} selected="Choose....." >
                                    <option value="0">Select a Building</option>
                                    {this.state.allBuildingOptions}
                                </Form.Control>
                            </Form.Group>
                        </Row>
    
                        {/* Start Date */}
                        <Row>
                            <Form.Label>Start Date</Form.Label>
                            <Form.Group as={Col} controlId="startDateSlect">
                                <DatePicker onChange={this.handleStartChange} selected={this.state.startDate} />
                            </Form.Group>
                        </Row>
    
                        {/* End Date */}
                        
                        <Row>
                            <Form.Label>End Date</Form.Label>
                            <Form.Group as={Col} controlId="endDateSlect">
                                <DatePicker onChange={this.handleEndChange} selected={this.state.endDate} />
                            </Form.Group>
                        </Row>
                        <Button variant="primary" value="submit" onClick={this.handleSubmit}>Generate PDF</Button>
                        <br />
                        <Button variant="secondary" value="weekly" onClick={this.handleSubmit}>Run Weekly Reports</Button>
                    {this.state.weeklyReportButtons}
                    </Form>
                    {weeklyReportButtons}
                </Container>
            );
        }
    }
}

export default PdfReportView;