import React, { Component } from 'react'
import { withRouter } from 'react-router'
import config from '../../utilities/config';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import AlertDetails from './AlertDetails';

class AlertLookupView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buildingOptions: null,
            viewing: "buildingSelect",
            alertRows: null,
            alertDetails: null,
            selectedAlert: null
        }
    }

    componentDidMount() {
        this.getAllBuildings()
    }

    getAllBuildings = () => {
        axios({
           method: 'get',
           url: `${config.api}get-all-buildings`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           },
        })
        .then((response) => {
           const data = response['data'];
           const buildingOptions = data.map((building) => {
                return(
                    <option value={building.id}>{building.label}</option>   
                )
           });
           this.setState({
               buildingOptions: buildingOptions
           });
        })
        .catch((error) => {
           console.log(error);
        });
    }

    selectBuilding = () => {
        const buildingID = $("#buildingSelect").val();
        console.log(buildingID);
        this.getBuildingAlerts(buildingID);
    }

    getBuildingAlerts = (buildingID) => {
        axios({
           method: 'get',
           url: `${config.api}alerts/global/building/${buildingID}`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           const data = response['data'].alerts;
           console.log(data);
           const dataKeys = Object.keys(data);
           this.createAlertTable(data, dataKeys);
        })
        .catch((error) => {
           console.log(error);
        });
    }

    createAlertTable = (alertData, alertDataKeys) => {
        const alertRows = alertDataKeys.map((alertKey) => {
            return(
                <tr onClick={() => this.seeAlertDetails(alertData[alertKey].globalAlertID)}>
                    <td>{alertData[alertKey].globalAlertID}</td>
                    <td>{alertData[alertKey].locationID}</td>
                    <td>{alertData[alertKey].summary.gallons}</td>
                    <td>{alertData[alertKey].summary.flushes}</td>
                </tr>
            )
        });
        this.setState({
            alertRows: alertRows,
            viewing: "alertList"
        });
    }

    seeAlertDetails = (alertID) => {
        console.log(alertID);
        axios({
           method: 'get',
           url: `${config.api}alerts/global/globalAlert/${alertID}`,
           headers: {
              'Authorization': `Bearer ${config.token}`
           }
        })
        .then((response) => {
           const data = response['data'];
           console.log(data);
           this.setState({
                viewing: "alertDetails",
                alertDetails: data,
                selectedAlert: alertID
           });
        })
        .catch((error) => {
           console.log(error);
        });
    }

    render() {
        switch (this.state.viewing) {
            case "buildingSelect":
                return (
                    <Container>
                        <div className="masqueradeForm">
                            <h2 className="select-title">
                                Select a Building to View Alerts
                            </h2>
                            <select id="buildingSelect">
                                <option value="0">-- --</option>
                                {this.state.buildingOptions}
                            </select>
                            <br />
                            <br />
                            <Button variant="primary" onClick={this.selectBuilding}>
                                See Alerts
                            </Button>
                        </div>
                    </Container>
                )
            case "alertList":
                return(
                    <Container>
                        <div className="masqueradeForm">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Alert ID</th>
                                        <th>Location</th>
                                        <th>Gallons</th>
                                        <th>Flushes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.alertRows}
                                </tbody>
                            </Table>
                        </div>
                    </Container>
                )
            case "alertDetails":
                return(
                    <Container>
                        <Row style={{height: '20%', overflow: 'auto'}}>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Alert ID</th>
                                        <th>Location</th>
                                        <th>Total Gallons</th>
                                        <th>Total Flushes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].globalAlertID}</td>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].locationID}</td>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].summary.gallons}</td>
                                        <td>{this.state.alertDetails.alerts[this.state.selectedAlert].summary.flushes}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                        <Row style={{height: '80%', overflow: 'auto'}}>
                            <Container>
                                <AlertDetails alertData={this.state.alertDetails} alertID={this.state.selectedAlert} />
                            </Container>
                        </Row>
                    </Container>
                )
            default:
                return(
                    <div></div>
                )
        }
    }
}

export default withRouter(AlertLookupView);