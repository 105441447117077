import React from 'react';
import './GallonsUsed.scss';
import './GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {numberWithCommas} from '../../UniversalFunctions/UniversalFunctions';
import config from '../../../utilities/config';

const GallonsUsed = (props) => {

    let gallonsUsed = Number(props.gallonsUsed).toLocaleString();

    let cost = null;

    if (props.hasOwnProperty('cost')) {
        if (config.userID == 89) {
            const demoMultiplier = localStorage.getItem('mlDemoMultiplier');
            if (demoMultiplier != null) {
                cost = Number(props.cost * demoMultiplier).toFixed(2).toLocaleString();
            }
            else {
                cost = Number(props.cost).toLocaleString();
            }
        }
        else {
            cost = Number(props.cost).toLocaleString();
        }
    }

    const openWaterUse = () => {
        const history = props.history;
        history.push("/water-use");
    }

    if (props.history != null) {
        let fCost = numberWithCommas(cost);
        return (
            <Container className="gallonsUsedBackground">
                <Row style={{"height": "25%"}} >
                    <p className="blocksLabel">Gallons Used</p>
                </Row>
                <Row style={{"height": "75%"}} >
                    <p className="totalGallonsUsed">
                        <div>{gallonsUsed}</div>
                        {cost !== null && (<div className="totalGallonsUsedCost">${fCost}</div>)}
                    </p>
                    
                </Row>
            </Container>
        );
    }
    else {
        return (
            <Container className="gallonsUsedBackground" >
                <Row style={{"height": "25%"}} >
                    <p className="blocksLabel">Gallons Used</p>
                </Row>
                <Row style={{"height": "75%"}} >
                    <p className="totalGallonsUsed">{gallonsUsed}</p>
                </Row>
            </Container>
        );
    }
    
};

export default GallonsUsed;