import React, { Component } from 'react';
import axios from 'axios';
import config from '../../utilities/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

class AlertSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: null,
            buildings: null,
            showModal: false,
            buildingID: null, 
            gallons: null, 
            hours: null, 
            email: 0,
            text: 0
        };
    }

    componentDidMount() {
        this.getUserBuildings();
    }

    getUserBuildings() {
        var getConfig = {
            method: 'get',
            url: `${config.api}user/${config.userID}/buildings`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            console.log(data);
            var buildings = [];
            var buildingOptions = [];
            data.forEach((building) => {
                buildings[building.id] = building.label;
                buildingOptions.push(
                    <option key={building.id} value={building.id}>{building.label}</option>
                );
            });
            this.setState({
                buildings: buildings,
                buildingOptions: buildingOptions
            })
            this.getUserAlertSettings();
        })
        .catch((error) => {
            console.log(error);
        });
    }

    getUserAlertSettings = () => {
        var getConfig = {
            method: 'get',
            url: `${config.api}user/${config.userID}/settings/alerts`,
            headers: { 
              'Authorization': `Bearer ${config.token}`
            }
        };
        
        axios(getConfig)
        .then((response) => {
            let data = response.data;
            console.log(data);
            this.createTableRows(data);
        })
        .catch((error) => {
            console.log(error);
        });
    }

    createTableRows = (alertData) => {
        console.log(alertData);
        console.log(this.state.buildings);
        const tableRows = alertData.map((alert) => {
            const buildingLabel = this.state.buildings[alert.buildingID];
            let text = " ";
            if (alert.sendText == 1) {
                text = "\u2713"
            }
            let email = " ";
            if (alert.sendEmail == 1) {
                email = "\u2713"
            }
            return (
                <tr key={buildingLabel}>
                    <td>{buildingLabel}</td>
                    <td>{alert.gallons} gallons in {alert.hours} hours</td>
                    <td style={{'textAlign': 'center'}}>{text}</td>
                    <td style={{'textAlign': 'center'}}>{email}</td>
                </tr>
            );
        });
        this.setState({
            alerts: tableRows
        });
    }

    commitNewAlert = () => {
        Swal.showLoading()
        
        var data = `[{"userID": ${config.userID}, "buildingID": ${this.state.buildingID}, "hours": ${this.state.hours}, "gallons": ${this.state.gallons}, "sendEmail": ${this.state.email}, "sendText": ${this.state.text}}]`;
        console.log(data);
        
        var postConfig = {
        method: 'post',
        url: `${config.api}users/settings/alerts`,
        headers: { 
            'Authorization': `Bearer ${config.token}`, 
            'Content-Type': 'text/plain'
        },
        data : data
        };

        axios(postConfig)
        .then((response) => {
            this.getUserAlertSettings()
            Swal.close();
            Swal.fire({
                'title': "Alert Saved",
                'icon': 'success'
            });
        })
        .catch((error) => {
            Swal.close();
            Swal.fire({
                'title': "Something went wrong",
                'icon': 'error'
            });
            console.log(error);
        });

    }

    handleChange = (event) => {
        switch (event.target.id) {
            case "textCheckbox":
                console.log("text");
                console.log(event.target.checked);
                if (event.target.checked) {
                    this.setState({
                        text: 1
                    });
                }
                else if (!event.target.checked) {
                    this.setState({
                        text: 0
                    });
                }
                break;
            case "emailCheckbox":
                console.log("email");
                console.log(event.target.checked);
                if (event.target.checked) {
                    this.setState({
                        email: 1
                    });
                }
                else if (!event.target.checked) {
                    this.setState({
                        email: 0
                    });
                }
                break;
            case "buildingInput":
                console.log("building");
                console.log(event.target.value);
                this.setState({
                    buildingID: event.target.value
                });
                break;
            case "gallonInput":
                console.log("gallons");
                console.log(event.target.value);
                this.setState({
                    gallons: event.target.value
                });
                break;
            case "hourInput":
                console.log("hours");
                console.log(event.target.value);
                this.setState({
                    hours: event.target.value
                });
                break;
            default:
                console.log("default");
                break;
        }
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false,
            buildingID: null, 
            gallons: null, 
            hours: null, 
            email: 0,
            text: 0
        });
    }

    saveAlert = () => {
        this.setState({
            showModal: false
        }, () => this.commitNewAlert());
        
    }
    
    render() {
        return (
            <Container style={{'height': '100%'}}>
                <Row style={{'paddingTop': '50px'}}>
                    <Col xs={12} md={6}>
                        <h2 style={{'marginLeft': '0px'}}>Custom Alerts</h2>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button onClick={this.handleShow} style={{'backgroundColor': '#3578bd', 'borderColor': '#3578bd', 'position': 'absolute', 'right': '0'}} >&#65291; New Custom Alert </Button>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={12}>
                        <Table striped responsive bordered >
                            <thead>
                                <tr>
                                    <th>Building</th>
                                    <th>Alert when you have used</th>
                                    <th>Text</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.alerts}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Modal 
                    show={this.state.showModal} 
                    onHide={this.handleClose} 
                    style={{opacity:1}} 
                    animation={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>New Custom Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Form.Group style={{'paddingLeft': '30px'}}>
                                    <Form.Label style={{'fontWeight': 'bold'}} >Send me a</Form.Label> 
                                    <br />
                                        <Form.Check id="textCheckbox" onChange={this.handleChange} type="checkbox" label="Text Message" />
                                        <Form.Check id="emailCheckbox" onChange={this.handleChange} type="checkbox" label="Email" /> 
                                        <br />
                                    <Form.Label style={{'fontWeight': 'bold'}} >When a Location at</Form.Label> 
                                    <br />
                                    <Form.Control id="buildingInput" onChange={this.handleChange} as="select">
                                        <option>-</option>
                                        {this.state.buildingOptions}
                                    </Form.Control>
                                    <br />
                                    <Form.Label style={{'fontWeight': 'bold'}} >Has used</Form.Label> 
                                    <br />
                                    <Row >
                                            <Col xs="auto">
                                                <Form.Control id="gallonInput" onChange={this.handleChange} size='sm' as="select">
                                                    <option>-</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                    <option>150</option>
                                                    <option>200</option>
                                                    <option>250</option>
                                                    <option>300</option>
                                                    <option>350</option>
                                                </Form.Control>
                                            </Col>
                                            <Col xs="auto">
                                                gallons in 
                                            </Col>
                                            <Col xs="auto">
                                            <Form.Control id="hourInput" onChange={this.handleChange} size='sm' as="select">
                                                    <option>-</option>
                                                    <option>4</option>
                                                    <option>8</option>
                                                    <option>12</option>
                                                    <option>16</option>
                                                    <option>20</option>
                                                    <option>24</option>
                                                </Form.Control>
                                            </Col>
                                            <Col xs="auto">
                                                hours.
                                            </Col>
                                    </Row>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.saveAlert}>
                            Save Alert
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default AlertSettings;