import React from 'react';
import './GraphBlock.scss';
import './GeneralBlocks.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Line } from 'react-chartjs-2';
import { CSVLink, CSVDownload } from "react-csv";

const GraphBlock = (props) => {
    let labels = props.keys.map((key) => {
        let parts = key.split("-");
        for (var i=0; i<3; i++) {
            parts[i] = parts[i].replace(/^0+/, '');
        }
        return parts[1] + "/" + parts[2] + "/" + parts[0].substring(2,4);
    });

    console.log(props.wastedData);
    let waterLeaked  = false;
    let leakedColor = 'rgba(76, 192, 172, 1)';
    let leakedColorBackground = 'rgba(76, 192, 172, 0.5)';
    for(let i=0; i<props.wastedData.length; i++) {
        if (props.wastedData[i] > 0) {
            waterLeaked = true;
            leakedColor = 'rgba(240, 68, 81, 1)';
            leakedColorBackground = 'rgba(240, 68, 81, 0.5)';
            break;
        }
    }

    const data = {
        labels: labels,
        datasets: [{
            label: 'Gallons Used',
            data: props.gallonData,
            type: 'line',
            backgroundColor: [
                'rgba(53, 121, 189, 0.5)'
            ],
            borderColor: [
                'rgba(53, 121, 189, 1))'
            ],
            borderWidth: 2,
            pointBackgroundColor: 'rgba(53, 121, 189, 1)',
            fill: 1
        },
        {
            label: 'Gallons Leaked',
            data: props.wastedData,
            type: 'line',
            backgroundColor: [
                leakedColorBackground
            ],
            borderColor: [
                leakedColor
            ],
            borderWidth: 1,
            pointBackgroundColor: leakedColor
        }]
    };

    const options =  {
        legend: {
            display: false
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:true,
                    fontColor: "#212529",
                    userCallback: function(value, index, values) {
                        value = value.toString();
                        value = value.split(/(?=(?:...)*$)/);
                        value = value.join(',');
                        return value;
                    }
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#212529"
                }
            }]
        },
        maintainAspectRatio: false
    };

    const openWaterUse = () => {
        const history = props.history;
        history.push("/water-use");
    }

    // const exportData = () => {
    //     let csvData = [
    //         ["Used", "Wasted"],
    //       ];

    //     gallonData.forEach((gallon, index) => {
    //         csvData.push([gallon, wastedData[index]]);
    //     });
    // }

    return (
        <Container className="graphBackground" onClick={props.showModal}>
            <Row style={{'height': '10%'}} >
                <p className="blocksLabel">Water Use</p>
            </Row>
            <Row className="waterUseGraph" >
                <Line data={data} options={options} />
                {/* <CSVLink data={csvData}>Download me</CSVLink>; */}
            </Row>
        </Container>
    );
    
};

export default GraphBlock;